import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Actions from "../../actions";

const withJobPolling = (WrappedComponent, jobType) => {
  class TempClass extends Component {
    userJobsPoll = null;

    componentDidMount = () => this.updateUserJobs();

    componentDidUpdate(prevProps) {
      const { user: prevUser } = prevProps,
        { user: currUser } = this.props;
      if (!Object.keys(prevUser || {}).length && Object.keys(currUser || {}).length)
        this.maybeAlertSyncInProgress();
    }

    componentWillUnmount() {
      this.props.dispatch(Actions.clearMessage());
      if (this.userJobsPoll) {
        clearInterval(this.userJobsPoll);
        this.userJobsPoll = null;
      }
    }

    updateUserJobs = () => {
      const { user } = this.props,
        { id } = user || {};
      if (!id) return;
      this.props.dispatch(Actions.requestGetUserJobs(id, Cookies.get('token'))).then(() =>
        this.maybeAlertSyncInProgress()
      )
    };

    maybeAlertSyncInProgress = () => {
      const { user } = this.props;
      if (!user) return false;
      if (this.isSyncing()) {
        this.props.dispatch(Actions.newMessage('Import in progress', -1, ['close'], true));
        this.startUserJobsPoll();
      }
      else this.props.dispatch(Actions.clearMessage());
    };

    isSyncing = () => {
      const { user } = this.props,
        { jobs } = user || {};
      return !!(jobs || []).find(x =>
        Array.isArray(jobType) ? jobType.includes(x.job_type) : x.job_type === jobType
      )
    };

    startUserJobsPoll = () => this.userJobsPoll = setInterval(() => {
      this.props.dispatch(Actions.requestGetUserJobs(this.props.user.id, Cookies.get('token'))).then(() => {
        if (!this.isSyncing()) {
          this.props.dispatch(Actions.clearMessage());
          clearInterval(this.userJobsPoll);
        }
      });
    }, 7000);

    render = () => {
      return (
        <WrappedComponent { ...this.props } />
      );
    };
  }

  return connect(state => ({
    user: state.auth.user,
  }), undefined)(TempClass);
};

export default withJobPolling;
