import { patchFbCampaignRequest } from '../api/facebook';

import {
  PATCH_FB_CAMPAIGN,
  PATCH_FB_CAMPAIGN_SUCCESS,
  PATCH_FB_CAMPAIGN_FAILURE,
} from './types';


function patchFbCampaign() {
  return {
    type: PATCH_FB_CAMPAIGN,
  };
}

function patchFbCampaignSuccess(json) {
  return {
    type: PATCH_FB_CAMPAIGN_SUCCESS,
    payload: json,
  };
}

function patchFbCampaignFailure(error) {
  return {
    type: PATCH_FB_CAMPAIGN_FAILURE,
    payload: error,
  };
}


export default function requestPatchFbCampaign(fb_campaign_id, book_id, token) {
  return (dispatch) => {
    dispatch(patchFbCampaign());
    return patchFbCampaignRequest(fb_campaign_id, book_id, token)
      .then(json => dispatch(patchFbCampaignSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(patchFbCampaignFailure(errors))));
  };
}
