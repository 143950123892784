import { getInvoicePdfRequest } from '../api/braintree';

import {
  GET_INVOICE_PDF,
  GET_INVOICE_PDF_SUCCESS,
  GET_INVOICE_PDF_FAILURE,
} from './types';

function getInvoicePdf() {
  return {
    type: GET_INVOICE_PDF,
  };
}

function getInvoicePdfSuccess(json) {
  return {
    type: GET_INVOICE_PDF_SUCCESS,
    payload: json,
  };
}

function getInvoicePdfFailure(error) {
  return {
    type: GET_INVOICE_PDF_FAILURE,
    payload: error,
  };
}

export default function requestGetInvoicePdf(invoiceId, apiToken){
  return (dispatch) => {
    dispatch(getInvoicePdf());
    return getInvoicePdfRequest(apiToken)
      .then(json => dispatch(getInvoicePdfSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getInvoicePdfFailure(errors))));
  };
}


