import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Paper from '@material-ui/core/Paper';
import Actions from '../../actions';
import AreaChart from '../blocks/AreaChart';
import SortableTable from '../blocks/SortableTable';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styles from '../../styles';
import _ from 'lodash';
import { 
  GET_ALL_EXPENSES_FAILURE,
} from '../../actions/types';

class ViewExpensesByCategory extends Component {
  state = {
    selectedCategory: '',
    forceUpdate: 0,
  }

  componentDidMount() {
    this.props.dispatch(Actions.requestGetAllExpenses(Cookies.get('token'))).then(result => {
        if (result.type === GET_ALL_EXPENSES_FAILURE) {
          this.props.dispatch(Actions.newError(result.payload.message));
        } 
    });
  }

  handleChange = event => {
    let forceUpdate = this.state.forceUpdate;
    forceUpdate += 1;
    this.setState({ selectedCategory: event.target.value, forceUpdate });
  };

  render() {
    const tableColumns = [
      { key: 'description', title: 'Description' },
      { key: 'amount', title: 'Amount' },
      { key: 'book', title: 'Book' },
      { key: 'date', title: 'Date' },
    ];
    const tableData = [];
    const chartDataByDate = {};
    const chartOptions = { colors: ['red', '#004411'] };
    const categoryOptions = [];
    const pushedCategories = [];
    const { selectedCategory, forceUpdate } = this.state;
    const expenses = this.props.expenses.expenses;
    // go through our expenses
    for (const key in expenses) {
      const expense = expenses[key];
      let matchesCategory = false;
      // go through our expenses and build our expense dropdown
      for (const subKey in expense.categories) {
        const category = expense.categories[subKey];
        // check for duplicates
        if (pushedCategories.indexOf(category.id) === -1) {
          categoryOptions.push({
            id: category.id,
            name: category.title,
          })
          // push to an array to avoid duplicates
          pushedCategories.push(category.id);
        }
        // check to see if it matches our selected category
        if (selectedCategory === category.id) {
          matchesCategory = true;
        }
      }
      if (selectedCategory !== "") {
        // skip if the category is different
        if (!matchesCategory) {
          continue;
        }
      }
      tableData.push(
        {
          id: expenses[key].id,
          description: expenses[key].title,
          amount: expenses[key].amount,
          book: _.get(expenses[key], 'book.title'),
          date: expenses[key].date,
        },
      );
      if (!chartDataByDate[expenses[key].date]) {
        chartDataByDate[expenses[key].date] = 0;
      }
      chartDataByDate[expenses[key].date] += expenses[key].amount;
    }

    const chartData = [['Date', 'Expenses']];
    for (const key in chartDataByDate) {
      chartData.push(
        [
          key,
          chartDataByDate[key],
        ],
      );
    }

    const additionalInputs = (
      <Select
        value={selectedCategory}
        onChange={this.handleChange}
        displayEmpty
        name="Selected Category"
        style={{marginRight: 35, paddingTop: 25}}
      >
        <MenuItem value="">
          <em>All Categories</em>
        </MenuItem>
        {categoryOptions.map(category =>
          <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
        )}
      </Select>
    )

    return (
      <div style={styles.viewContainer}>
        <h2 style={styles.heading}>Expenses By Category</h2>
        <Paper>
          <AreaChart data={chartData} options={chartOptions} additionalInputs={additionalInputs}/>
          <SortableTable data={tableData} columns={tableColumns} forceUpdate={forceUpdate}/>
        </Paper>
      </div>
    );
  }
}

export default connect(
  state => ({
    expenses: state.expenses,
  }),
)(ViewExpensesByCategory);
