import { getSubscriptionRequest } from '../api/braintree';

import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
} from './types';


function getSubscription() {
  return {
    type: GET_SUBSCRIPTION,
  };
}

function getSubscriptionSuccess(json) {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: json,
  };
}

function getSubscriptionFailure(error) {
  return {
    type: GET_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export default function requestGetSubscription(apiToken) {
  return (dispatch) => {
    dispatch(getSubscription());
    return getSubscriptionRequest(apiToken)
      .then(json => dispatch(getSubscriptionSuccess(json)))
      .catch(error => dispatch(getSubscriptionFailure(error)));
  };
}
