import {
  POST_AMS_EXPORT,
  POST_AMS_EXPORT_FAILURE,
  POST_AMS_EXPORT_SUCCESS,
  GET_AMS_CAMPAIGNS,
  GET_AMS_CAMPAIGNS_FAILURE,
  GET_AMS_CAMPAIGNS_SUCCESS,
  PATCH_AMS_CAMPAIGN,
  PATCH_AMS_CAMPAIGN_FAILURE,
  PATCH_AMS_CAMPAIGN_SUCCESS,
  GET_AMS_SPEND_BY_DATE,
  GET_AMS_SPEND_BY_DATE_FAILURE,
  GET_AMS_SPEND_BY_DATE_SUCCESS,
  GET_HISTORICAL_AMS_IMPORT,
  GET_HISTORICAL_AMS_IMPORT_FAILURE,
  GET_HISTORICAL_AMS_IMPORT_SUCCESS,
} from "./types";

import {
  postAmsCampaignExportRequest,
  getAmsCampaignsRequest,
  patchAmsCampaignRequest,
  getAmsSpendByDateRequest,
  postAmazonTokenRequest, getAmsHistoricalImportRequest,
} from "../api/ams";

import {
  POST_AMAZON_TOKEN,
  POST_AMAZON_TOKEN_SUCCESS,
  POST_AMAZON_TOKEN_FAILURE,
}  from '../actions/types'

import { maybeUpdateExchangeRates } from "./get-all-exchange-rates";
import {currencies} from "../utilities/constants";

function postAmsExport() {
  return dispatch => dispatch({ type: POST_AMS_EXPORT });
}

function postAmsExportSuccess(payload) {
  return dispatch => dispatch({ type: POST_AMS_EXPORT_SUCCESS, payload });
}

function postAmsExportFailure(payload) {
  return dispatch => dispatch({ type: POST_AMS_EXPORT_FAILURE, payload });
}

export function requestPostAmsExport(token, formData) {
  return (dispatch) => {
    dispatch(postAmsExport());
    return postAmsCampaignExportRequest(formData, token)
      .then(json => dispatch(postAmsExportSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(postAmsExportFailure(eJson))));
  };
}

function getAmsCampaigns() {
  return dispatch => dispatch({ type: GET_AMS_CAMPAIGNS });
}

function getAmsCampaignsSuccess(payload) {
  return dispatch => dispatch({ type: GET_AMS_CAMPAIGNS_SUCCESS, payload });
}


function getAmsCapaignsFailure(payload) {
  return dispatch => dispatch({ type: GET_AMS_CAMPAIGNS_FAILURE, payload });
}

export function requestGetAmsCampaigns(token) {
  return (dispatch) => {
    dispatch(getAmsCampaigns());
    return getAmsCampaignsRequest(token)
      .then(json => dispatch(getAmsCampaignsSuccess(json)))
      .catch(e => dispatch(getAmsCapaignsFailure(e)));
  };
}

function patchAmsCampaign() {
  return dispatch => dispatch({ type: PATCH_AMS_CAMPAIGN });
}

function patchAmsCampaignSuccess(payload) {
  return dispatch => dispatch({ type: PATCH_AMS_CAMPAIGN_SUCCESS, payload });
}

function patchAmsCampaignFailure(payload) {
  return dispatch => dispatch({ type: PATCH_AMS_CAMPAIGN_FAILURE, payload });
}

export function requestPatchAmsCampaign(campaignId, formData, token) {
  return (dispatch) => {
    dispatch(patchAmsCampaign());
    return patchAmsCampaignRequest(campaignId, formData, token)
      .then(json => dispatch(patchAmsCampaignSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(patchAmsCampaignFailure(eJson))));
  };
}

function getAmsSpendByDate() {
  return dispatch => dispatch({ type: GET_AMS_SPEND_BY_DATE });
}

function getAmsSpendByDateSuccess(payload) {
  return dispatch => dispatch({ type: GET_AMS_SPEND_BY_DATE_SUCCESS, payload });
}

function getAmsSpendByDateFailure(payload) {
  return dispatch => dispatch({ type: GET_AMS_SPEND_BY_DATE_FAILURE, payload });
}

export function requestGetAmsSpendByDate(token, startDate, endDate) {
  return (dispatch, getState) => dispatch(maybeUpdateExchangeRates(token)).then(() => {
    dispatch(getAmsSpendByDate());
    const { auth, exchangeRates: exRatesState } = getState(),
      exchangeRates = exRatesState.exchangeRates || {},
      user = auth.user || {},
      preferredCurrency = user.preferred_currency || currencies.USD;
    return getAmsSpendByDateRequest(token, startDate, endDate)
      .then(json => dispatch(getAmsSpendByDateSuccess({ amsSpend: json, exchangeRates, preferredCurrency })))
      .catch(e => dispatch(getAmsSpendByDateFailure(e)));
  });
}

function postAmazonToken() {
  return {
    type: POST_AMAZON_TOKEN,
  };
}

function postAmazonTokenSuccess(json) {
  return {
    type: POST_AMAZON_TOKEN_SUCCESS,
    payload: json,
  };
}

function postAmazonTokenFailure(error) {
  return {
    type: POST_AMAZON_TOKEN_FAILURE,
    payload: error,
  };
}

export  function requestPostAmazonToken(apiToken, fbAccessToken){
  return (dispatch) => {
    dispatch(postAmazonToken());
    return postAmazonTokenRequest(apiToken, fbAccessToken)
        .then((json) => dispatch(postAmazonTokenSuccess(json)))
        .catch(error => dispatch(postAmazonTokenFailure(error)));
  };
}

export function requestGetHistoricalAmsImport(token) {
  return (dispatch) => {
    dispatch({ type: GET_HISTORICAL_AMS_IMPORT });
    return getAmsHistoricalImportRequest(token)
      .then(json => dispatch({ type: GET_HISTORICAL_AMS_IMPORT_SUCCESS, payload: json }))
      .catch(e => dispatch({ type: GET_HISTORICAL_AMS_IMPORT_FAILURE, payload: e }));
  };
}
