export const marketplaces = {
  AMAZON: 'amazon.com',
  AMAZON_CA: 'amazon.ca',
  AMAZON_AU: 'amazon.com.au',
  AMAZON_BR: 'amazon.com.br',
  AMAZON_UK: 'amazon.co.uk',
  AMAZON_FR: 'amazon.fr',
  AMAZON_DE: 'amazon.de',
  AMAZON_NL: 'amazon.nl',
  AMAZON_ES: 'amazon.es',
  AMAZON_IT: 'amazon.it',
  AMAZON_IN: 'amazon.in',
  AMAZON_JP: 'amazon.co.jp',
  AMAZON_TR: 'amazon.com.tr',
  AMAZON_SG: 'amazon.com.sg',
  AMAZON_MX: 'amazon.com.mx',
  AMAZON_CN: 'amazon.cn',
};

export const dateRangePresets = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_week',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  THIS_YEAR: 'this_year',
  LAST_YEAR: 'last_year',
  ALL_TIME: 'all_time',
  CUSTOM: 'custom',
};

export const currencies = {
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
  JPY: 'JPY',
  INR: 'INR',
  MXN: 'MXN',
};

export const SALES_AREA_CHART_HEADERS = [
  'Date',
  { role: 'tooltip', type: 'string', p: { html: true } },
  'Revenue',
  'Expenses',
  'Profit',
];
export const SALES_PIE_CHART_HEADERS = ['Book', 'Sales'];
export const SALES_CHART_TYPES = {
  PIE: 'pie',
  AREA: 'area',
};
export const EXP_AREA_CHART_HEADERS = [
  'Date',
  { role: 'tooltip', type: 'string', p: { html: true } },
  'Expenses',
];
export const EXP_PIE_CHART_HEADERS = [
  'Book',
  'Expenses',
];
export const PIE_CHART_METRICS = {
  EARNINGS: 'earnings',
  UNITS: 'units',
};
export const PIE_CHART_RESOURCES = {
  BOOK: 'book',
  AUTHOR: 'author',
  MARKET: 'market',
  TAG: 'tag',
};

export const USER_JOBS = {
  KDP_IMPORT: 'kdp_import',
  AMS_IMPORT: 'ams_import',
  FB_IMPORT: 'fb_campaign_import',
};

export const REPEAT_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};
