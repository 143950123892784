import {
  GET_FB_IMPORT_JOBS,
  GET_FB_IMPORT_JOBS_FAILURE,
  GET_FB_IMPORT_JOBS_SUCCESS,
} from "./types";

import { getFBImportJobsRequest } from "../api/facebook";

function getFBImportJobs() {
  return { type: GET_FB_IMPORT_JOBS };
}

function getFBImportJobsSuccess(payload) {
  return { type: GET_FB_IMPORT_JOBS_SUCCESS, payload };
}

function getFBImportJobsFailure(payload) {
  return { type: GET_FB_IMPORT_JOBS_FAILURE, payload };
}

export default function requestGetFBImportJobs(token) {
  return (dispatch) => {
    dispatch(getFBImportJobs());
    return getFBImportJobsRequest(token)
      .then(json => dispatch(getFBImportJobsSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(getFBImportJobsFailure(eJson))));
  };
}
