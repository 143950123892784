import {
  DELETE_CUSTOM_RATE,
  DELETE_CUSTOM_RATE_FAILURE,
  DELETE_CUSTOM_RATE_SUCCESS,
} from "./types";
import { deleteCustomRateRequest } from "../api/custom-rates";

function deleteCustomRate() {
  return { type: DELETE_CUSTOM_RATE };
}

function deleteCustomRateSuccess(payload) {
  return { type: DELETE_CUSTOM_RATE_SUCCESS, payload };
}

function deleteCustomRateFailure(payload) {
  return { type: DELETE_CUSTOM_RATE_FAILURE, payload };
}

export default function requestDeleteCustomRate(token, id) {
  return (dispatch) => {
    dispatch(deleteCustomRate());
    return deleteCustomRateRequest(id, token)
      .then(json => dispatch(deleteCustomRateSuccess(json)))
      .catch(e => dispatch(deleteCustomRateFailure(e)));
  };
}
