import {
  GET_ACCOUNT_SNAPSHOT,
  GET_ACCOUNT_SNAPSHOT_FAILURE,
  GET_ACCOUNT_SNAPSHOT_SUCCESS,
} from "../actions/types";

export default function reducer(
  state = {
    isFetching: false,
    error: null,
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_ACCOUNT_SNAPSHOT:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ACCOUNT_SNAPSHOT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_ACCOUNT_SNAPSHOT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
