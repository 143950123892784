import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import SortableTable from '../blocks/SortableTable';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../styles';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import { withStyles } from '@material-ui/core/styles';

class ViewInvoices extends Component {
  componentDidMount() {
    this.props.dispatch(Actions.requestGetAllInvoices(Cookies.get('token')));
  }

  render() {

    if (this.props.isFetching) {
      return (
        <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      )
    }

    const tableData = [];
    const tableColumns = [
      { key: 'date', title: 'Date' },
      { key: 'total', title: 'Total' },
      { key: 'link', title: 'Download' },
    ];
    const invoices = this.props.invoices;
    for (const key in invoices) {
      tableData.push(
        {
          link: (
            <a
              className="button-link"
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_API_ROOT + '/invoice/' + invoices[key].id + '?api_token=' + Cookies.get('token')}
            >
              <Button color="primary" variant="contained">Download</Button>
            </a>
          ),
          total: invoices[key].total,
          date: invoices[key].date,
        },
      );
    }

    const { classes } = this.props;

    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text={'invoices'} />
        </SectionWrapper>
        <SectionWrapper>
          <Paper>
            <SortableTable data={tableData} columns={tableColumns} />
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default connect(
  state => ({
    invoices: state.braintree.invoices,
    isFetching: state.braintree.isFetching,
  }),
)(withStyles(styles, { withTheme: true })(ViewInvoices));
