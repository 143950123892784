import { getAllExchangeRatesRequest } from '../api/exchange-rates';
import moment from 'moment';

import {
  GET_ALL_EXCHANGE_RATES,
  GET_ALL_EXCHANGE_RATES_SUCCESS,
  GET_ALL_EXCHANGE_RATES_FAILURE,
} from './types';

function getAllExchangeRates() {
  return { type: GET_ALL_EXCHANGE_RATES };
}

function getAllExchangeRatesSuccess(json) {
  return { type: GET_ALL_EXCHANGE_RATES_SUCCESS, payload: json };
}

function getAllExchangeRatesError(e) {
  return { type: GET_ALL_EXCHANGE_RATES_FAILURE, payload: e };
}

export default function requestGetAllExchangeRates(token) {
  return (dispatch) => {
    dispatch(getAllExchangeRates());
    return getAllExchangeRatesRequest(token)
      .then(json => dispatch(getAllExchangeRatesSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(getAllExchangeRatesError(eJson))));
  };
}

export function maybeUpdateExchangeRates(token) {
  return (dispatch, getState) => {
    const state = getState(),
      { exchangeRates: cachedExRates, updatedAt } = state.exchangeRates;
    const exchangeRatesCurrent = updatedAt
      && cachedExRates
      && Object.keys(cachedExRates).length
      && moment.unix(updatedAt).isSameOrAfter(moment().startOf('day'));
    if (exchangeRatesCurrent) return Promise.resolve();
    return dispatch(requestGetAllExchangeRates(token));
  };
}
