import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import SnapshotCard from '../blocks/SnapshotCard';

class AccountSnapshot extends PureComponent {
  render() {
    const { theme, profit, expenses, revenue } = this.props;

    return (
      <div className={this.props.classes.container} style={{
        justifyContent: 'space-between',
        paddingTop: 30,
        paddingBottom: 30,
      }}>
        <SnapshotCard bottomText="Revenue" topText={revenue || ''} bottomBackgroundColor={theme.palette.common.revenue} />
        <SnapshotCard bottomText="Expenses" topText={expenses || ''} bottomBackgroundColor={theme.palette.common.expenses} />
        <SnapshotCard bottomText="Profit" topText={profit || ''} bottomBackgroundColor={theme.palette.common.profit} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AccountSnapshot);
