import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import styles from '../../styles';
import { marketplaces } from "../../utilities/constants";
import { capitalizeFirstChar } from "../../utilities/string-helpers";
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment-timezone';
import {currencyForMarketplace, currencySymbol} from "../../utilities/locale-helpers";

const customStyles = {
  horizontalForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formControl: {
    margin: 15,
    minWidth: 200
  }
};

class RateForm extends Component {
  state = {
    rate: '',
    startDate: null,
    endDate: null,
    marketplace: ''
  };

  componentDidMount() {
    this.extractRateInfo();
  }

  extractRateInfo = () => {
    const { rate } = this.props;
    if (!rate) return;
    const { rate: rateAmount, marketplace, start_date: startDate, end_date: endDate } = rate;
    const zone = moment.tz.guess();
    if (rateAmount && marketplace) this.setState({
      rate: rateAmount,
      marketplace,
      // Ensure that UTC timestamps are displayed correctly in user's timezone
      startDate: moment.tz(startDate, zone),
      endDate: moment.tz(endDate, zone)
    });
  };

  handleChange = name => e => {
    const val = e instanceof Date ? e : e.target.value;
    this.setState({ [name]: val });
  };

  submitRate = () => {
    const form = document.getElementById('custom-rate-form');
    const formData = new FormData(form);
    const rateId = this.props.rate ? this.props.rate.id : null;
    this.props.onSubmit(formData, rateId);
  };

  render() {
    const { showDates, error } = this.props;
    const { marketplace } = this.state;
    const currency = currencyForMarketplace(marketplace);
    const currSymbol = currencySymbol(currency);
    return (
      <div style={styles.viewContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button variant="outlined" size="small" color="secondary" onClick={this.props.onBack}>
            <ArrowBack />
            Back
          </Button>
          <form
            id="custom-rate-form"
            autoComplete="off"
            onSubmit={this.submitRate}
            className={this.props.classes.horizontalForm}
          >
            <FormControl className={this.props.classes.formControl}>
              <InputLabel htmlFor="rate">Rate</InputLabel>
              <Input
                id="rate"
                name="rate"
                label="Rate"
                type="float"
                onChange={this.handleChange('rate')}
                value={this.state.rate}
                error={error && 'rate' in error}
                startAdornment={currSymbol ? <span style={{ marginRight: 5 }}>{currSymbol}</span> : undefined}
              />
            </FormControl>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel htmlFor="marketplace-helper">Marketplace</InputLabel>
              <Select
                value={this.state.marketplace}
                onChange={this.handleChange('marketplace')}
                input={<Input name="marketplace" id="marketplace-helper" />}
                label="Marketplace"
                error={error && 'marketplace' in error}
              >
                {
                  Object.values(marketplaces).map((x, i) => <MenuItem key={i} value={x}>{capitalizeFirstChar(x)}</MenuItem>)
                }
              </Select>
            </FormControl>
            {
              !!showDates && (
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={this.props.classes.horizontalForm}>
                  <DatePicker
                    autoOk
                    clearable
                    id="start_date"
                    name="start_date"
                    label="Start date"
                    format="MMM dd, yyyy"
                    value={this.state.startDate}
                    onChange={this.handleChange('startDate')}
                    className={this.props.classes.formControl}
                    error={error && 'start_date' in error}
                  />
                  <DatePicker
                    autoOk
                    clearable
                    id="end_date"
                    name="end_date"
                    label="End date"
                    format="MMM dd, yyyy"
                    value={this.state.endDate}
                    onChange={this.handleChange('endDate')}
                    className={this.props.classes.formControl}
                    error={error && 'end_date' in error}
                  />
                </MuiPickersUtilsProvider>
              )
            }
            <Button variant="contained" color="primary" onClick={() => this.submitRate()}>
              Submit
            </Button>
          </form>
        </div>
      </div>
    )
  }
}

export default withStyles({ ...styles, ...customStyles })(RateForm);
