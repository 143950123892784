import { combineReducers } from 'redux';

import auth from './auth';
import books from './books';
import braintree from './braintree';
import expenses from './expenses';
import facebook from './facebook';
import message from './message';
import sales from './sales';
import rates from './rates';
import exchangeRates from './exchange-rates';
import profitAndLoss from './profit-and-loss';
import categories from './categories';
import tags from './tags';
import accountSnapshot from './account-snapshot';
import ams from './ams';

const appReducer = combineReducers({
  auth,
  books,
  braintree,
  expenses,
  facebook,
  message,
  sales,
  rates,
  exchangeRates,
  profitAndLoss,
  categories,
  tags,
  accountSnapshot,
  ams,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
