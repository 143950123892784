import { forgotPasswordEmailRequest } from '../api/auth';

import {
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
} from './types';

function resetPasswordEmail() {
  return {
    type: RESET_PASSWORD_EMAIL,
  };
}

function resetPasswordEmailSuccess(json) {
  return {
    type: RESET_PASSWORD_EMAIL_SUCCESS,
    payload: json,
  };
}

function resetPasswordEmailFailure(error) {
  return {
    type: RESET_PASSWORD_EMAIL_FAILURE,
    payload: error,
  };
}

export default function requestResetPasswordEmail(formData) {
  return (dispatch) => {
    dispatch(resetPasswordEmail());
    return forgotPasswordEmailRequest(formData)
      .then(json => dispatch(resetPasswordEmailSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(resetPasswordEmailFailure(errors))));
  };
}
