import {
  GET_RATE_DROPS,
  GET_RATE_DROPS_FAILURE,
  GET_RATE_DROPS_SUCCESS,
} from "./types";
import { getRateDropsRequest } from "../api/rates";

function getRateDrops() {
  return { type: GET_RATE_DROPS };
}

function getRateDropsSuccess(payload) {
  return { type: GET_RATE_DROPS_SUCCESS, payload };
}

function getRateDropsFailure(payload) {
  return { type: GET_RATE_DROPS_FAILURE, payload };
}

export default function requestGetRateDrops(token) {
  return (dispatch) => {
    dispatch(getRateDrops());
    return getRateDropsRequest(token)
      .then(json => dispatch(getRateDropsSuccess(json)))
      .catch(e => dispatch(getRateDropsFailure(e)));
  };
}
