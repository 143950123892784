import { postCreateSubscriptionRequest } from '../api/braintree';

import {
  POST_CREATE_SUBSCRIPTION,
  POST_CREATE_SUBSCRIPTION_SUCCESS,
  POST_CREATE_SUBSCRIPTION_FAILURE,
}
 from '../actions/types'

function postCreateSubscription() {
  return {
    type: POST_CREATE_SUBSCRIPTION,
  };
}

function postCreateSubscriptionSuccess(json) {
  return {
    type: POST_CREATE_SUBSCRIPTION_SUCCESS,
    payload: json,
  };
}

function postCreateSubscriptionFailure(error) {
  return {
    type: POST_CREATE_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export default function requestPostCreateSubscription(formData, apiToken){
  return (dispatch) => {
    dispatch(postCreateSubscription());
    return postCreateSubscriptionRequest(formData, apiToken)
    .then((json) => dispatch(postCreateSubscriptionSuccess(json)))
    .catch(error => error.json().then(errors => dispatch(postCreateSubscriptionFailure(errors))));
  };
}


