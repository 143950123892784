import {
  GET_PROFIT_AND_LOSS,
  GET_PROFIT_AND_LOSS_FAILURE,
  GET_PROFIT_AND_LOSS_SUCCESS,
  SET_PROFIT_AND_LOSS,
} from './types';
import requestGetAllSales from "./get-all-sales";
import requestGetAllExpenses from "./get-all-expenses";

function getProfitAndLoss() {
  return {
    type: GET_PROFIT_AND_LOSS,
  };
}

function setProfitAndLoss(salesByBook, expenses) {
  return {
    type: SET_PROFIT_AND_LOSS,
    salesByBook,
    expenses,
  };
}

function getProfitAndLossSuccess() {
  return {
    type: GET_PROFIT_AND_LOSS_SUCCESS,
  };
}

function getProfitAndLossFailure(error) {
  return {
    type: GET_PROFIT_AND_LOSS_FAILURE,
    payload: error,
  };
}

function getDataForProfitAndLoss(state, token) {
  return (dispatch) => {
    const promises = [];
    const { salesByBook } = state.sales;
    const { expenses } = state.expenses;
    if (!salesByBook || !Object.keys(salesByBook).length) promises.push(dispatch(requestGetAllSales(token)));
    if (!expenses || !Object.keys(expenses).length) promises.push(dispatch(requestGetAllExpenses(token)));
    return Promise.all(promises);
  };
}

export default function requestGetProfitAndLoss(apiToken) {
  return (dispatch, getState) => {
    dispatch(getProfitAndLoss());
    return dispatch(getDataForProfitAndLoss(getState(), apiToken))
      .then(() => {
        const state = getState();
        const { sales, expenses: expState } = state;
        const { salesByBook } = sales;
        const { expenses } = expState;
        if (!salesByBook) return dispatch(getProfitAndLossFailure());
        dispatch(setProfitAndLoss(salesByBook, expenses));
        return dispatch(getProfitAndLossSuccess());
      });
  };
}
