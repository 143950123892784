import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Paper from '@material-ui/core/Paper';
import ExpenseForm from '../blocks/ExpenseForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  GET_ALL_BOOKS_FAILURE,
  GET_ALL_EXPENSES_FAILURE, PATCH_EXPENSE_FAILURE, PATCH_EXPENSE_SUCCESS, POST_CATEGORY_SUCCESS,
  POST_EXPENSE_FAILURE, POST_EXPENSE_SUCCESS,
} from '../../actions/types';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SectionWrapper from '../blocks/SectionWrapper';
import PageHeader from '../blocks/PageHeader';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

class ExpenseManager extends Component {
  state = {
    amount: '',
    title: '',
    date: '',
    vendor: '',
    account: '',
    category_id: '',
    book_id: '',
    repeat_type: '',
  };

  componentDidMount() {
    const { expenses, books } = this.props;
    const expenseId = this.maybeGetExpenseId();

    if (expenseId) {
      if (!expenses || !Object.keys(expenses).length) {
        this.fetchExpenses().then(() => this.selectExpense(expenseId));
      }
      else this.selectExpense(expenseId)
    }
    if (!books || !Object.keys(books).length) this.fetchBooks();
  }

  maybeGetExpenseId = () => {
    const { match } = this.props;
    const { params } = match;
    const { id: expenseId } = params;
    return expenseId;
  };

  selectExpense = expenseId => {
    const { expenses, recurringExpenses } = this.props;
    const expense = (expenses || {})[expenseId] || (recurringExpenses || {})[expenseId];
    if (!expense) {
      console.error(`Can't find expense with ID ${expenseId} in expenses`);
      return;
    }
    const { account, amount, book_id, date, title, vendor, book, repeat_type } = expense,
      categories = expense.categories || [],
      category = categories && categories.length ? { value: categories[0].id, label: categories[0].title } : null,
      bookData = book ? { value: book.id, label: book.title } : null;
    this.setState({ account, amount, book_id, title, vendor, date, category, book: bookData, repeat_type });
  };

  fetchExpenses = () => {
    const { dispatch } = this.props,
      token = Cookies.get('token');

    return Promise.all([
      dispatch(Actions.requestGetAllExpenses(token)),
      dispatch(Actions.requestGetAllRecurringExpenses(token)),
    ]);
  };

  fetchBooks = () => {
    const { dispatch } = this.props,
      token = Cookies.get('token');

    dispatch(Actions.requestGetAllBooks(token)).then(res => {
      if (res.type === GET_ALL_BOOKS_FAILURE) {
        dispatch(Actions.newError(res.payload.message));
      }
    });
  };

  handleChange = name => event => {
    const val = event && 'target' in event && 'value' in event.target
      ? event.target.value
      : event;
    this.setState({ [name]: val });
  };

  submitExpense = formData => {
    const token = Cookies.get('token'),
      { dispatch } = this.props,
      expenseId = this.maybeGetExpenseId();

    if (expenseId) {
      dispatch(Actions.requestPatchExpense(expenseId, formData, token))
        .then(res => {
          if (res.type === PATCH_EXPENSE_SUCCESS) {
            dispatch(Actions.newMessage('Expense updated!'));
            return;
          }

          if (res.type === PATCH_EXPENSE_FAILURE) {
            this.handleError(res);
          }

        });
    }
    else {
      dispatch(Actions.requestPostExpense(formData, token))
        .then(res => {
          if (res.type === POST_EXPENSE_SUCCESS) {
            dispatch(Actions.newMessage('Expense submitted!'));
            return;
          }

          if (res.type === POST_EXPENSE_FAILURE) {
            this.handleError(res);
          }
        })
    }
  };

  handleError = (res) => {
    const payload = res.payload || {},
      errorMsg = Object.values(payload).join(' '),
      { dispatch } = this.props;
    if (errorMsg && errorMsg.length) dispatch(Actions.newError(errorMsg));
  };

  postCategory = category => {
    const token = Cookies.get('token');
    this.props.dispatch(Actions.requestPostCategory({ title: category }, token))
      .then(res => {
        if (res.type === POST_CATEGORY_SUCCESS) {
          this.props.dispatch(Actions.newMessage('Category created!'));
        }
        const { payload } = res;
        if (!payload) return;
        const { id, title } = payload;
        this.handleChange('category')({ value: id, label: title });
      });
  };

  render() {
    const { isFetching, classes } = this.props;

    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text={`${this.maybeGetExpenseId() ? 'Edit' : 'Create'} Expense`} />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{
            ...styles.paper,
            position: 'relative'
          }}>
            <Button component={Link} variant="outlined" size="small" color="secondary" to="/expenses">
              <ArrowBack />
              Back
            </Button>
            <ExpenseForm
              onSubmit={this.submitExpense}
              handleChange={this.handleChange}
              books={this.props.books}
              error={this.props.error}
              categories={this.props.categories}
              postCategory={this.postCategory}
              { ...this.state }
            />
            {
              isFetching &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(52, 52, 52, 0.6)'
              }}
              >
                <CircularProgress />
              </div>
            }
          </Paper>
        </SectionWrapper>
      </div>
    )
  }
}

export default connect(
  state => ({
    expenses: state.expenses.expenses,
    recurringExpenses: state.expenses.recurringExpenses,
    error: state.expenses.error,
    isFetching: state.expenses.isFetching,
    books: state.books.books,
    categories: Object.values(state.categories.categories || {})
  }),
)(withStyles(styles, { withTheme: true })(ExpenseManager));
