import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import _ from 'lodash';
import {
  RESET_PASSWORD_EMAIL_SUCCESS
} from '../../actions/types';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {WithLoading} from "../blocks/WithLoading";

class ForgotPassword extends Component {
  resetPasswordEmail(event) {
    event.preventDefault();
    const form = document.getElementById('reset-password-email-form');
    const formData = new FormData(form);
    this.props.dispatch(Actions.requestResetPasswordEmail(formData))
      .then(result => {
        if (result.type === RESET_PASSWORD_EMAIL_SUCCESS) {
          this.props.dispatch(Actions.newMessage('Email Sent!'));
        } else {
          this.props.dispatch(Actions.newError('Something went wrong, please try again'));
        }
      });
  }

  fieldError = key => {
    const { auth } = this.props,
      { error } = auth || {};
    if (!error || Array.isArray(error) || typeof error !== 'object') return;
    const err = error[key];
    if (Array.isArray(err)) return err.join(' ');
    return err;
  };

  render() {
    const { classes, auth } = this.props,
      { isFetching } = auth || {};
    return (
      <WithLoading loading={isFetching}>
        <div className={classes.contentContainer}>
          <Typography component="h2" variant="h5" className={classes.uppercase}>
            Forgot Password?
          </Typography>
          <Paper style={styles.paper}>
            <form onSubmit={(event) => this.resetPasswordEmail(event)} id='reset-password-email-form'>
              <FormControl error={!!this.fieldError('email')} style={{ marginBottom: 20, width: 320 }}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                />
                {
                  !!this.fieldError('email')
                  && <FormHelperText>{ this.fieldError('email') }</FormHelperText>
                }
              </FormControl>
              <br/>
              <Button variant="contained" color="primary" type="submit">Submit</Button>
            </form>
          </Paper>
        </div>
      </WithLoading>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
)(withStyles(styles, { withTheme: true })(ForgotPassword));
