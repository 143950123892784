import React, {PureComponent} from 'react';
import {Grid, Button} from '@material-ui/core';
import {Link} from 'react-router-dom';

class Plan extends PureComponent {
  render() {
    const {
      id, name, price, billingFrequency, description,
    } = this.props.plans || {};

    const affiliate = this.props.affiliate;

    const bgColor = {
      backgroundColor: this.props.bgColor,
    };

    const btnColor = {
      backgroundColor: this.props.btnColor,
    };

    const affiliateParam = affiliate || '';

    return (
      <Grid
        item
        xs={12}
        style={{
          ...bgColor,
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'column',
          width: '100%',
          textAlign: 'center',
          padding: '20px',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <strong style={{textTransform: 'uppercase'}}>{name}</strong>
        <p style={{fontWeight: 300}}>{description}</p>
        <h3 style={{
          fontWeight: 300,
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
        >
          {' '}
          $
          {price}
          {' '}
          /
          {' '}
          {billingFrequency === 12 ? 'year' : 'month'}
        </h3>
        <Link
          key={`link-${id}`}
          to={`/register/${id}/${affiliateParam}`}
          className="button-link"
          style={{margin: 'auto', marginBottom: 0}}
        >
          <Button style={{...btnColor, color: 'white'}} variant="contained">
            Get Started
          </Button>
        </Link>
      </Grid>
    );
  }
}

export default Plan;
