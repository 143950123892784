import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const loginRequest = (email, password) => fetch(
  `${process.env.REACT_APP_API_ROOT}/login`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  },
).then(handleResponse);

export const loginRequestWithToken = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/token`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: token,
    }),
  },
).then(handleResponse);

export const resetPasswordRequest = formData => fetch(
  `${process.env.REACT_APP_API_ROOT}/password/reset`,
  {
    method: 'POST',
    body: formData,
  },
).then(handleResponse);

export const forgotPasswordEmailRequest = formData => fetch(
  `${process.env.REACT_APP_API_ROOT}/password/email`,
  {
    method: 'POST',
    body: formData,
  },
).then(handleResponse);

export const updatePasswordRequest = (id, formData, token) => {
  formData.append('_method', 'PATCH');
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/user/${id}/update-password`,
    {
      method: 'PATCH',
      headers: {
        'api-token': token,
      },
      body: formData,
    },
  ).then(handleResponse);
};
