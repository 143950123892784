import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';
import {getUrlParamString} from "../utilities/request-helpers";

export const getAllSalesRequest = (token, startDate, endDate, page = 1) => {
  let url = `${process.env.REACT_APP_API_ROOT}/sales`;
  const p = { page };
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};

export const getSalesByDateRequest = (token, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/sales_by_date`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};
