import { getFbCampaignRequest } from '../api/facebook';

import {
  GET_FB_CAMPAIGN,
  GET_FB_CAMPAIGN_SUCCESS,
  GET_FB_CAMPAIGN_FAILURE,
} from './types';

function getFbCampaign() {
  return {
    type: GET_FB_CAMPAIGN,
  };
}

function getFbCampaignSuccess(json) {
  return {
    type: GET_FB_CAMPAIGN_SUCCESS,
    payload: json,
  };
}

function getFbCampaignFailure(error) {
  return {
    type: GET_FB_CAMPAIGN_FAILURE,
    payload: error,
  };
}

export default function requestGetFbCampaign(token, fb_campaign_id) {
  return (dispatch) => {
    dispatch(getFbCampaign());
    return getFbCampaignRequest(token, fb_campaign_id)
      .then(json => dispatch(getFbCampaignSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getFbCampaignFailure(errors))));
  };
}
