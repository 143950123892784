import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ChangePlanIcon from '@material-ui/icons/Autorenew';
import ViewInvoicesIcon from '@material-ui/icons/Description';
import UpdateCardIcon from '@material-ui/icons/CreditCard';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from '../../styles';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import { withStyles } from '@material-ui/core/styles';

const iconStyles = {
  verticalAlign: 'middle',
  marginRight: 15
}

const links = [
  {
    to: '/billing/change-plan',
    name: 'Change Plan',
    icon: <ChangePlanIcon fontSize="large" style={iconStyles}/>
  },
  {
    to: '/billing/view-invoices',
    name: 'View Invoices',
    icon: <ViewInvoicesIcon fontSize="large" style={iconStyles}/>
  },
  {
    to: '/billing/update-card',
    name: 'Update Card',
    icon: <UpdateCardIcon fontSize="large" style={iconStyles}/>
  },
];

class BillingDashboard extends PureComponent {
  render = () => {
    const { classes } = this.props;
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Billing" />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{...styles.paper, float: 'left', width: '100%'}}>
            {links.map((link, index) => {
              let marginTop = 25;
              let marginBottom = 25;
              if(index === 0) {
                marginTop = 0;
              }
              if(index === links.length - 1) {
                marginBottom = 0;
              }
              return (
                <Link key={'link-' + index} to={link.to} className="button-link" style={{color: 'black', marginTop, marginBottom, float: 'left', width: '100%'}}>
                  <Button color="primary" variant="contained" style={{float: 'right'}}>
                    <ArrowForward fontSize="large"/>
                  </Button>
                  <h2 style={styles.paperHeading}>{link.icon} {link.name}</h2>
                </Link>
              )
            })}
          </Paper>
        </SectionWrapper>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(BillingDashboard)

