import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const postTagRequest = (data, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/tags`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
    body: JSON.stringify(data),
  },
).then(handleResponse);

export const getTagsRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/tags`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);
