import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Actions from '../../actions';
import styles from '../../styles';
import Cookies from 'cookies-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  POST_CHANGE_PLAN_SUCCESS,
  POST_CHANGE_PLAN_FAILURE,
} from '../../actions/types';

class ChangePlan extends Component {

  state = {
    planChanging: false
  }

  componentDidMount() {
    this.props.dispatch(Actions.requestGetAllPlans());
    this.props.dispatch(Actions.requestGetSubscription(Cookies.get('token')));
  }

  changePlan = (plan) => {
    this.setState({planChanging: plan});
    this.props.dispatch(Actions.requestPostChangePlan(plan, Cookies.get('token')))
      .then((result) => {
        if (result.type === POST_CHANGE_PLAN_SUCCESS) {
          this.props.dispatch(Actions.newMessage('You have successfully changed plans.'));
          this.props.history.push('/billing');
        } else if (result.type === POST_CHANGE_PLAN_FAILURE) {
          this.props.dispatch(Actions.newError(result.payload.message));
        }
      })
  }
  render() {
    let plans = [];
    for (const key in this.props.plans) {
      const plan = this.props.plans[key];
        plans.push(
          <Grid key={key} item md={4}>
            <Paper style={styles.paper}>
              <h3 style={styles.paperHeading}>{plan.name}</h3>
              <p>Price: ${plan.price} / {plan.billingFrequency === 12 ? 'year' : 'month'}</p> 
              <p>Description: {plan.description}</p>  
              {key !== this.props.auth.user.braintree_plan ?
                this.props.isFetching && this.state.planChanging === key ? 
                  <CircularProgress />
                : <Button color="primary" variant="contained" onClick={() => this.changePlan(key)}>
                  Change
                </Button>
              : null}
            </Paper>
          </Grid>
        )
    }
    const currentPlan = _.get(this.props.plans, _.get(this.props.braintree, 'subscription.braintree_plan'));
    const currentPlanSection = this.props.isFetching && this.state.planChanging === false ? <CircularProgress /> : (
      <p>Current plan: {currentPlan ? currentPlan.name : null}</p>
    );
    return( 
      <div style={styles.viewContainer}>
        <h2 style={styles.heading}>Plans</h2>
        {currentPlanSection}
        <Grid container spacing={24} style={{...styles.container, flexGrow: 1}} justify="center">
          {plans}
        </Grid>
      </div>
    )
  }
}
export default connect(
  state => ({
    auth: state.auth,
    plans: state.braintree.plans,
    isFetching: state.braintree.isFetching,
    braintree: state.braintree,
  }),
)(ChangePlan);
