import moment from 'moment';
import { reduceObjAryByKey } from '../utilities/array-helpers';

import {
  GET_ALL_EXCHANGE_RATES,
  GET_ALL_EXCHANGE_RATES_FAILURE,
  GET_ALL_EXCHANGE_RATES_SUCCESS,
} from '../actions/types';

export default function reducer(
  state = {
    isFetching: false,
    exchangeRates: {},
    updatedAt: null,
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_ALL_EXCHANGE_RATES:
      return { ...state, isFetching: true };
    case GET_ALL_EXCHANGE_RATES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case GET_ALL_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        exchangeRates: reduceObjAryByKey(action.payload, 'date'),
        updatedAt: moment().unix(),
      };
    default:
      return state;
  }
}
