import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './fonts/FranklinGothicDemiRegular.ttf';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import Routes from './routes';

const store = configureStore();

render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
