import { currencies } from './constants';

export function capitalizeFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

export function toTitleCase(str) {
  const words = str.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
  return words.map(capitalize).join(' ');
}

export function currencyFormatter(preferredCurrency) {
  return new Intl.NumberFormat(undefined, { style: 'currency', currency: preferredCurrency || currencies.USD });
}

export function percentFormatter() {
  return new Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2 });
}
