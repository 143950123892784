export const doesItemExistInState = (items, item_id) => {
  if (typeof items[item_id] !== 'undefined') {
    return items[item_id];
  }
  return false;
};

export const selectedItem = (items, item_id) => {
  if (typeof items[item_id] !== 'undefined') {
    return items[item_id];
  }
  return false;
};
