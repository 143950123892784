import Cookies from 'cookies-js';
import { clearCache } from '../utilities/cache-helpers';
import {
  LOGOUT_USER,
} from './types';

function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export default function logout() {
  return (dispatch) => {
    clearCache();
    Cookies.expire('token');
    dispatch(logoutUser());
  };
}
