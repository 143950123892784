// auth
import login from './login';
import loginWithToken from './login-with-token';
import logout from './logout';
import register from './register';
import requestPasswordReset from './reset-password';
import requestResetPasswordEmail from './reset-password-email';
import requestUpdatePassword from './update-password';
import requestUpdateUser from './update-user';

// books
import requestGetAllBooks from './get-all-books';

// braintree
import requestGetBraintreeToken from './get-braintree-token';
import requestPostCreateSubscription from './post-create-subscription';
import requestPostCancelSubscription from './post-cancel-subscription';
import requestPostChangePlan from './post-change-plan';
import requestPostUpdateCard from './post-update-card';
import requestGetAllPlans from './get-all-plans';
import requestGetSubscription from './get-subscription';
import requestGetAllInvoices from './get-all-invoices';
import requestGetInvoicePdf from './get-invoice-pdf';

// expenses
import requestGetAllExpenses from './get-all-expenses';
import requestGetExpense from './get-expense';
import requestPostExpense from './post-expense';
import requestPatchExpense from './patch-expense';
import requestGetAllExpenseCategories from './get-all-expense-categories';
import requestDeleteExpense from './delete-expense';

//facebook
import requestPostFacebookToken from './post-facebook-token';
import requestGetImportFacebookAdCampaigns from './get-import-fb-ad-campaigns';
import requestGetAllFacebookCampaigns from './get-all-fb-campaigns';
import requestGetFacebookCampaign from './get-fb-campaign';
import requestPatchFbCampaign from './patch-fb-campaign';
import requestDeleteFbCampaign from './delete-fb-campaign';
import requestPostFbCampaignExport from './post-fb-campaign-export';
import requestGetFBImportJobs from './get-fb-import-jobs';
import { requestDeleteFbToken } from "./facebook";

// sales
import requestGetAllSales from './get-all-sales';

// messages
import newError from './new-error';
import newMessage from './new-message';
import clearMessage from './clear-message';

// rates
import requestGetRate from './get-rate';
import requestPostRate from './post-rate';
import requestGetAllRates from './get-all-rates';
import requestGetRateDrops from './get-rate-drops';

// exchange rates
import requestGetAllExchangeRates from './get-all-exchange-rates';

// custom rates
import requestGetAllCustomRates from './get-all-custom-rates';
import requestPostCustomRate from './post-custom-rate';
import requestPatchCustomRate from './patch-custom-rate';
import requestDeleteCustomRate from './delete-custom-rate';

import requestGetProfitAndLoss from './get-profit-and-loss';

import { requestPostCategory } from './categories';

import { requestPostTag, requestGetTags } from './tags';

import { requestPostBookTags, requestGetBookTags } from './books';
import { requestGetSalesByDate } from './sales';
import { requestGetAccountSnapshot } from './account-snapshot';
import { requestPatchRate, requestDeleteRate } from "./rates";
import { requestGetUserJobs } from "./users";
import { requestGetAllRecurringExpenses } from "./expenses";

import {
  requestPostAmsExport,
  requestGetAmsCampaigns,
  requestPatchAmsCampaign,
  requestGetAmsSpendByDate,
  requestPostAmazonToken,
  requestGetHistoricalAmsImport,
} from "./ams";

const actions = {
  logout,

  // GET
  requestGetAllSales,
  requestGetAllExpenses,
  requestGetExpense,
  requestGetAllExpenseCategories,
  requestGetAllBooks,
  requestGetImportFacebookAdCampaigns,
  requestGetAllFacebookCampaigns,
  requestGetFacebookCampaign,
  requestGetFBImportJobs,
  requestGetBraintreeToken,
  requestGetAllPlans,
  requestGetSubscription,
  requestGetAllInvoices,
  requestGetInvoicePdf,
  requestGetAllRates,
  requestGetAllRecurringExpenses,

  // POST
  login,
  loginWithToken,
  register,
  requestPasswordReset,
  requestResetPasswordEmail,
  requestPostExpense,
  requestPostFacebookToken,
  requestPostCreateSubscription,
  requestPostCancelSubscription,
  requestPostChangePlan,
  requestPostUpdateCard,
  requestPostFbCampaignExport,

  // PATCH
  requestUpdatePassword,
  requestPatchExpense,
  requestUpdateUser,
  requestPatchFbCampaign,

  // DELETE
  requestDeleteExpense,
  requestDeleteFbCampaign,
  requestDeleteFbToken,


  // MESSAGES
  clearMessage,
  newError,
  newMessage,

  // RATES
  requestGetRate,
  requestPostRate,
  requestGetRateDrops,

  // EXCHANGE RATES
  requestGetAllExchangeRates,

  // CUSTOM RATES
  requestGetAllCustomRates,
  requestPostCustomRate,
  requestPatchCustomRate,
  requestDeleteCustomRate,

  requestGetProfitAndLoss,

  requestPostCategory,
  requestPostTag,
  requestGetTags,
  requestPostBookTags,
  requestGetBookTags,
  requestGetSalesByDate,
  requestGetAccountSnapshot,
  requestPostAmsExport,
  requestPostAmazonToken,
  requestGetAmsCampaigns,
  requestGetHistoricalAmsImport,
  requestPatchAmsCampaign,
  requestGetAmsSpendByDate,
  requestPatchRate,
  requestDeleteRate,
  requestGetUserJobs,
};

export default actions;
