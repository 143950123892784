import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  top: {
    width: '50%',
    backgroundColor: 'white',
    marginBottom: -20,
    zIndex: 999,
    fontSize: 30,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 12,
    color: '#1b1b1b',
    fontFamily: 'FranklinGothicDemi',
    letterSpacing: 2,
  },
  bottom: {
    paddingTop: 30,
    paddingBottom: 20,
    width: '70%',
  },
});

class SnapshotCard extends PureComponent {
  render() {
    const { classes, bottomText, topText, bottomBackgroundColor } = this.props;
    return (
      <div className={classes.root}>
        <Paper elevation={3} className={classes.top}>{topText}</Paper>
        <Paper elevation={1} className={classes.bottom} style={{
          background: bottomBackgroundColor || 'white',
        }}>
          <Typography variant="h5" align="center" style={{
            color: bottomBackgroundColor ? 'white' : 'inherit',
            fontSize: 26,
            fontWeight: 700,
          }}>
            {bottomText.toUpperCase()}
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SnapshotCard);
