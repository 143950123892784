import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { WithLoading } from '../blocks/WithLoading';
import { AuthStatePropTypes } from '../../propTypes';
import PropTypes from 'prop-types';
import { RESET_PASSWORD_SUCCESS } from '../../actions/types';

class ResetPassword extends Component {
  resetPassword(event) {
    event.preventDefault();
    const form = document.getElementById('reset-password-form');
    const formData = new FormData(form);
    const { dispatch } = this.props;
    dispatch(Actions.requestPasswordReset(formData))
      .then((result) => {
        const { type, payload } = result;
        if (type === RESET_PASSWORD_SUCCESS) dispatch(Actions.newMessage('Password Reset!'));
        else {
          let err = 'Something went wrong, please try again';
          const { invalidToken } = payload || {};
          if (invalidToken) err = 'Password reset request expired. Please re-request a reset.';
          dispatch(Actions.newError(err));
        }
      });
  }

  fieldError = key => {
    const { auth } = this.props,
      { error } = auth || {};
    if (!error || Array.isArray(error) || typeof error !== 'object') return;
    const err = error[key];
    if (Array.isArray(err)) return err.join(' ');
    return err;
  };

  render() {
    const token = window.location.href.split("/")[4];
    const { classes, auth } = this.props,
      { isFetching } = auth || {};
    return (
      <WithLoading loading={isFetching}>
        <div className={classes.contentContainer}>
          <Typography component="h2" variant="h5" className={classes.uppercase}>
            Reset Password
          </Typography>
          <Paper style={styles.paper}>
            <form onSubmit={event => this.resetPassword(event)} id="reset-password-form">
              <FormControl error={!!this.fieldError('email')} style={{ marginBottom: 20, width: 320 }}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                />
                {
                  !!this.fieldError('email')
                  && <FormHelperText>{ this.fieldError('email') }</FormHelperText>
                }
              </FormControl>
              <br/>
              <FormControl error={!!this.fieldError('password')} style={{ marginBottom: 20, width: 320 }}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="New Password"
                />
                {
                  !!this.fieldError('password')
                  && <FormHelperText>{ this.fieldError('password') }</FormHelperText>
                }
              </FormControl>
              <br/>
              <FormControl error={!!this.fieldError('password_confirmation')} style={{ marginBottom: 20, width: 320 }}>
                <InputLabel htmlFor="password">Confirm Password</InputLabel>
                <Input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  placeholder="New Password"
                />
                {
                  !!this.fieldError('password_confirmation')
                  && <FormHelperText>{ this.fieldError('password_confirmation') }</FormHelperText>
                }
              </FormControl>
              <input type="hidden" id="token" name="token" value={token} />
              <br/>
              <FormControl>
                <Button variant="contained" color="primary" type="submit">Submit</Button>
              </FormControl>
            </form>
          </Paper>
        </div>
      </WithLoading>
    );
  }
}

ResetPassword.defaultProps = {
  auth: {},
};

ResetPassword.propTypes = {
  auth: AuthStatePropTypes,
  classes: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    auth: state.auth,
  }),
)(withStyles(styles)(ResetPassword));
