import { postChangePlanRequest } from '../api/braintree';

import {
  POST_CHANGE_PLAN,
  POST_CHANGE_PLAN_SUCCESS,
  POST_CHANGE_PLAN_FAILURE,
}
 from '../actions/types'

function postChangePlan() {
  return {
    type: POST_CHANGE_PLAN,
  };
}

function postChangePlanSuccess(json) {
  return {
    type: POST_CHANGE_PLAN_SUCCESS,
    payload: json,
  };
}

function postChangePlanFailure(error) {
  return {
    type: POST_CHANGE_PLAN_FAILURE,
    payload: error,
  };
}

export default function requestPostChangePlan(plan, apiToken){
  return (dispatch) => {
    dispatch(postChangePlan());
    return postChangePlanRequest(plan, apiToken)
    .then((json) => dispatch(postChangePlanSuccess(json)))
    .catch(error => error.json().then(errors => dispatch(postChangePlanFailure(errors))));
  };
}

