import React, {Component} from 'react';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

class ButtonWithConfirm extends Component {
  state = {
    modalOpen: false,
  };

  render() {
    const { buttonComponent, buttonContent, onConfirm, buttonProps } = this.props;
    const ButtonComponent = buttonComponent;
    return (
      <span>
        <Dialog
          open={this.state.modalOpen}
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogActions>
            <Button onClick={() => this.setState({ modalOpen: false })} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => {
              onConfirm();
              this.setState({ modalOpen: false });
            }} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <ButtonComponent onClick={() => this.setState({ modalOpen: true })} { ...(buttonProps || {}) }>
          { buttonContent }
        </ButtonComponent>
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ButtonWithConfirm);
