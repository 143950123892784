import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {Typography} from "@material-ui/core";

export default class ConfirmationDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose();
  };

  handleOk = () => {
    this.props.onClose(this.props.value);
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        {...this.props}
      >
        <DialogTitle id="confirmation-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to perform this action? Once confirmed, it cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleOk} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
