import { getAllSalesRequest } from '../api/sales';
import {maybeUpdateExchangeRates} from './get-all-exchange-rates';
import requestGetRateDrops from './get-rate-drops';
import requestGetAllCustomRates from './get-all-custom-rates';
import requestGetAllRates from './get-all-rates';

import {
  GET_ALL_SALES,
  GET_ALL_SALES_SUCCESS,
  GET_ALL_SALES_FAILURE,
} from './types';
import requestGetAllExpenses from "./get-all-expenses";
import requestGetAllFacebookCampaigns from "./get-all-fb-campaigns";
import {requestGetBookTags} from "./books";
import {requestGetTags} from "./tags";
import {requestGetAmsSpendByDate} from "./ams";
import {requestGetAllRecurringExpenses} from "./expenses";

function getAllSales() {
  return {
    type: GET_ALL_SALES,
  };
}

function getAllSalesSuccess(json, exchangeRates, rateData) {
  return {
    type: GET_ALL_SALES_SUCCESS,
    payload: json,
    exchangeRates,
    rateData,
  };
}

function getAllSalesFailure(error) {
  return {
    type: GET_ALL_SALES_FAILURE,
    payload: error,
  };
}

/**
 * Conditionally retrieves all rate data needed to properly calculate
 * sales/page read revenue
 * @param state
 * @param token
 * @returns {function(*): Promise<any[]>}
 */
function getRateDataForSales(state, token, startDate, endDate) {
  return (dispatch) => {
    const promises = [];

    const { rateDrops } = state.rates;
    // retrieve rate drops if not present
    if (!rateDrops) promises.push(dispatch(requestGetRateDrops(token)));
    const { customRates = {} } = state.rates;
    const { id: userId } = state.auth.user;
    const userCustomRates = customRates[userId] || null;
    // retrieve custom rates if not present
    if (!userCustomRates) promises.push(dispatch(requestGetAllCustomRates(token)));
    // retrieve historical rates if not present
    const { rates } = state.rates;
    if (!Object.keys(rates).length) promises.push(dispatch(requestGetAllRates(token)));

    promises.push(dispatch(maybeUpdateExchangeRates(token)));
    promises.push(dispatch(requestGetAmsSpendByDate(token, startDate, endDate)));
    promises.push(dispatch(requestGetAllExpenses(token, startDate, endDate)));
    promises.push(dispatch(requestGetAllRecurringExpenses(token, startDate, endDate)));
    promises.push(dispatch(requestGetAllFacebookCampaigns(token, startDate, endDate)));
    promises.push(dispatch(requestGetBookTags(token)));
    promises.push(dispatch(requestGetTags(token)));

    return Promise.all(promises);
  };
}

function fetchPages(token, startDate, endDate) {
  let data = [];
  return getAllSalesRequest(token, startDate, endDate, 1)
    .then((res) => {
      const d = res.data || [],
        { last_page: lastPage } = res,
        pageAry = typeof lastPage === 'number' && lastPage >= 1
          ? Array.from(Array(lastPage)).map((_, i) => i + 1)
          : [];
      if (pageAry && pageAry.length) pageAry.splice(0, 1);
      data = [...d];

      if (!pageAry || !pageAry.length) return data;

      const promises = pageAry.map(page => getAllSalesRequest(token, startDate, endDate, page));
      return Promise.all(promises)
        .then((pageRes) => {
          pageRes.forEach((x) => {
            data = [...data, ...(x.data || [])];
          });
          return data;
        });
    });
}


export default function requestGetAllSales(token, startDate, endDate) {
  return (dispatch, getState) => {
    dispatch(getAllSales());
    return dispatch(getRateDataForSales(getState(), token, startDate, endDate))
      .then(() => {
        // retrieve rate data from store
        const state = getState();
        const { exchangeRates } = state.exchangeRates;
        const { customRates = {}, rateDrops, rates, ratesByMarketplace } = state.rates;
        const {
          id: userId,
          rate_estimation_method: rateEstimationMethod,
          preferred_currency: preferredCurrency,
        } = state.auth.user;
        const userCustomRates = customRates[userId] || {};
        const rateData = {
          customRates: userCustomRates,
          rateEstimationMethod,
          preferredCurrency,
          rateDrops,
          rates,
          ratesByMarketplace,
        };

        return fetchPages(token, startDate, endDate)
          .then(json => dispatch(getAllSalesSuccess(json, exchangeRates, rateData)))
          .catch(error => error.json().then(errors => dispatch(getAllSalesFailure(errors))));
      })
      .catch(e => e.json().then(json => dispatch(getAllSalesFailure(json))));
  };
}
