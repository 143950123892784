import React, {Component} from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Actions from '../../actions';
import SortableTable from '../blocks/SortableTable';
import {
    GET_HISTORICAL_AMS_IMPORT_SUCCESS,
    PATCH_AMS_CAMPAIGN_SUCCESS,
} from '../../actions/types';
import styles from '../../styles';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {currencies, USER_JOBS} from "../../utilities/constants";
import {convertCurrency, findExchangeRateForDate} from "../../utilities/exchange-rate-helpers";
import {currencyFormatter} from "../../utilities/string-helpers";
import {sortObjAryByKey} from "../../utilities/array-helpers";
import Select from 'react-select';
import {bookTitle} from "../../utilities/book-helpers";
import withJobPolling from "../blocks/WithJobPolling";
import Link from "@material-ui/core/Link";


class ViewAmsCampaigns extends Component {
    componentDidMount() {
        const {amsCampaigns, exchangeRates} = this.props;
        const token = Cookies.get('token');
        if (!amsCampaigns || !Object.keys(amsCampaigns).length) {
            this.props.dispatch(Actions.requestGetAmsCampaigns(token));
        }

        if (!exchangeRates || !Object.keys(exchangeRates).length) {
            this.props.dispatch(Actions.requestGetAllExchangeRates(token));
        }
    }

    onHistoricalImport = () => {
        const token = Cookies.get('token');
        this.props.dispatch(Actions.requestGetHistoricalAmsImport(token))
            .then(res => {
                const {type, payload} = res;
                if (type === GET_HISTORICAL_AMS_IMPORT_SUCCESS)
                    this.props.dispatch(Actions.newMessage('Import in progress!'));
                else
                    this.props.dispatch(Actions.newError(payload.message || 'An error occurred'));
            })
    };

    bookOptions = () => {
        const user = this.props.user || {},
            authors = user.author || [];
        const books = authors.reduce((acc, x) => {
            const books = x.books || [];
            return [...acc, ...books];
        }, []).map(x => ({label: bookTitle(x), value: x.id}));
        return sortObjAryByKey(books, 'label');
    };

    onBookSelectChange = (data, campaignId) => {
        const formData = new FormData();
        const {value: bookId} = data || {};
        formData.append('book_id', bookId || '');
        this.props.dispatch(Actions.requestPatchAmsCampaign(campaignId, formData, Cookies.get('token')))
            .then(res => {
                const {type} = res || {};
                if (type === PATCH_AMS_CAMPAIGN_SUCCESS)
                    this.props.dispatch(Actions.newMessage('Campaign updated successfully!'));
                else
                    this.props.dispatch(Actions.newError('An error occurred. Unable to update campaign.'));
            })
    };

    renderBookSelect = (campaignId, bookId) => {
        const bookOpts = this.bookOptions(),
            selected = bookId ? bookOpts.find(x => x.value === bookId) : null;
        return (
            <Select
                isSearchable
                isClearable
                options={bookOpts}
                value={selected}
                placeholder="Select a book..."
                onChange={data => this.onBookSelectChange(data, campaignId)}
                styles={{
                    container: base => ({
                        ...base,
                        minWidth: 300
                    })
                }}
            />
        )
    };

    importInProgress = () => {
        const { user } = this.props,
          { jobs } = user || {};
        return !!(jobs || []).find(x => x.job_type === USER_JOBS.AMS_IMPORT);
    }

    tableData = () => {
        const {user, exchangeRates} = this.props,
            preferredCurrency = user.preferred_currency || currencies.USD,
            currFormatter = currencyFormatter(preferredCurrency);
        const amsCampaigns = this.props.amsCampaigns || {};
        moment.locale(window.navigator.userLanguage || window.navigator.language);
        const bookOpts = this.bookOptions();
        return Object.values(amsCampaigns).map(x => {
            const {name, book_id, start_date, end_date, id: campaignId, state, synced_at} = x,
                campaignSpend = x.ams_campaign_spend || [],
                [firstSpend] = campaignSpend,
                dailyBudget = firstSpend ? firstSpend.daily_budget : '',
                totalSpend = campaignSpend.reduce((sum, x) => {
                    const {currency, date, cost: spend} = x;
                    if (currency === preferredCurrency) return sum += spend;
                    const exRateData = findExchangeRateForDate(date, exchangeRates);
                    if (!exRateData) return sum;
                    try {
                        const parsedRates = JSON.parse(exRateData.rates);
                        const convertedSpend = convertCurrency(spend, currency, parsedRates, preferredCurrency);
                        return sum += convertedSpend;
                    } catch (e) {
                        return sum;
                    }
                }, 0);
            const book = book_id ? bookOpts.find(x => x.value === book_id) : null;
            return {
                name,
                spend: currFormatter.format(totalSpend),
                spendRaw: totalSpend,
                book: this.renderBookSelect(campaignId, book_id),
                bookRaw: book ? book.title : '',
                daily_budget: currFormatter.format(dailyBudget),
                daily_budgetRaw: dailyBudget,
                status: state.toLowerCase(),
                start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : '',
                end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : '',
                synced_at: synced_at ? moment(synced_at).format('YYYY-MM-DD') : '',
            }
        });
    };

    render() {
        const {isFetching} = this.props;
        const tableColumns = [
            {key: 'name', title: 'Name'},
            {key: 'spend', title: 'Spend'},
            {key: 'daily_budget', title: 'Daily Budget'},
            {key: 'book', title: 'Book'},
            {key: 'status', title: 'Status'},
            {key: 'start_date', title: 'Start Date'},
            {key: 'end_date', title: 'End Date'},
            {key: 'synced_at', title: 'Last Synced'}
        ];

        return (
            <div style={{
                padding: 15,
                minHeight: 300,
                width: '100%',
                display: 'flex',
            }}>
                {
                    isFetching &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(1,1,1,.3)',
                        zIndex: 999
                    }}>
                        <CircularProgress/>
                    </div>
                }
                <div style={{flex: 1}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <Button disabled={this.importInProgress()} variant="contained" color="primary" onClick={this.onHistoricalImport}>
                                Import Historical
                            </Button>
                        </div>
                        <div>
                            <Link className="button-link" href={process.env.REACT_APP_AMAZON_LOGIN_URL}>
                                <Button variant="contained" color="primary">
                                    Login to AMS
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <SortableTable
                        key={'amsCampaignTable'}
                        rowsPerPage={10}
                        search={true}
                        data={this.tableData()}
                        columns={tableColumns}
                        padding="dense"
                        orderBy="status"
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        user: state.auth.user,
        amsCampaigns: state.ams.amsCampaigns,
        isFetching: state.ams.isFetching,
        exchangeRates: state.exchangeRates.exchangeRates,
    }),
)(withStyles(styles, {withTheme: true})(withJobPolling(ViewAmsCampaigns, USER_JOBS.AMS_IMPORT)));
