import React, {Component} from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE} from '../../actions/types';
import Actions from '../../actions';
import styles from '../../styles';
import {withStyles} from '@material-ui/core/styles';
import {currencies} from "../../utilities/constants";
import {defaultSalesDates} from "../../utilities/date-helpers";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/icons/Link';
import Assessment from '@material-ui/icons/Assessment';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Profile extends Component {
  state = {
    email: '',
    rateEstimationMethod: '',
    preferredCurrency: '',
    showTopEarners: true,
  };

  componentDidMount() {
    this.extractUserInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevUser = prevProps.auth.user || {},
      currUser = this.props.auth.user || {};
    if (currUser && currUser.id !== prevUser.id) this.extractUserInfo();
  }

  extractUserInfo = () => {
    const {user} = this.props.auth;
    if (!user) return;
    const {email, preferred_currency, rate_estimation_method, show_top_earners} = user;
    if (email && preferred_currency && rate_estimation_method) {
      this.setState({
        rateEstimationMethod: rate_estimation_method,
        preferredCurrency: preferred_currency,
        email,
        showTopEarners: !!show_top_earners,
      });
    }
  };

  updateUser = (event) => {
    event.preventDefault();
    const form = document.getElementById('update-account-form');
    let formData = new FormData(form);
    formData.append('email', this.state.email);
    formData.append('show_top_earners', this.state.showTopEarners ? '1' : '0');
    this.props.dispatch(Actions.requestUpdateUser(this.props.auth.user.id, formData, Cookies.get('token')))
      .then((result) => {
        if (result.type === UPDATE_USER_SUCCESS) {
          this.props.dispatch(Actions.newMessage('Account Updated'));
          const {startDate, endDate} = defaultSalesDates;
          this.props.dispatch(Actions.requestGetAllSales(Cookies.get('token'), startDate, endDate));
        } else if (result.type === UPDATE_USER_FAILURE) {
          this.props.dispatch(Actions.newError('Oops, something went wrong'));
        }
      });
  }

  handleChange = (prop) => {
    return function (event) {
      const val = prop === 'showTopEarners' ? event.target.checked : event.target.value;
      this.setState({[prop]: val});
    }.bind(this);
  }

  copyText = () => {
    /* Get the text field */
    const copyText = document.getElementById("affiliate-link");

    /* Select the text field */
    copyText.select();

    /* Copy the text inside the text field */
    document.execCommand("copy");

    this.props.dispatch(Actions.newMessage('Link Copied'));
  }

  onTabChange = (e, val) => {
    this.setState({tab: val});
  };

  render() {
    const affiliateLink = window.location.href.split('/settings')[0] + "/pricing/" + btoa("affiliate-link-" + this.props.auth.user.id);
    return (
      <div style={styles.viewContainer}>
        <List style={{ width: '50%' }}>
          <ListItem>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText primary="Affiliate Link" secondary={affiliateLink} />
            <ListItemSecondaryAction>
              <IconButton color="primary" onClick={this.copyText}>
                <FileCopy />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Assessment />
            </ListItemIcon>
            <ListItemText primary="Affiliate Report" />
            <ListItemSecondaryAction>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_API_ROOT + '/affiliate-report?api_token=' + Cookies.get('token')}
                className="button-link"
              >
                <IconButton color="primary">
                  <CloudDownload />
                </IconButton>
              </a>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <input id="affiliate-link" value={affiliateLink} onChange={() => {
        }} style={{opacity: 0}}/>
        <form id="update-account-form" onSubmit={event => this.updateUser(event)}>
          <FormControl>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              name="email"
              errors={this.props.auth}
              type="text"
              value={this.state.email}
              placeholder="Email"
              onChange={this.handleChange('email')}
              className={this.props.classes.textField}
              disabled
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="rate_estimation_method">Rate Estimation Method</InputLabel>
            <Select
              value={this.state.rateEstimationMethod}
              onChange={this.handleChange('rateEstimationMethod')}
              input={<Input name="rate_estimation_method" id="rate_estimation_method-helper"/>}
              className={this.props.classes.textField}
            >
              <MenuItem value="custom">Custom</MenuItem>
              <MenuItem value="drop">Expect a Drop</MenuItem>
              <MenuItem value="previous">Previous Month's Rate</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="preferred_currency">Preferred Currency</InputLabel>
            <Select
              value={this.state.preferredCurrency}
              input={<Input name="preferred_currency" id="preferred_currency"/>}
              className={this.props.classes.textField}
              onChange={this.handleChange('preferredCurrency')}
            >
              {
                Object.values(currencies).map(c => (
                  <MenuItem key={c} value={c}>{c}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value="showTopEarners"
                  onChange={this.handleChange('showTopEarners')}
                  checked={this.state.showTopEarners}
                  id="show_top_earners"
                  inputProps={{
                    name: 'show_top_earners',
                  }}
                />
              }
              label="Show Top Earners"
            />
          </FormControl>
          <br/>
          <br/>
          <Button variant="contained" color="primary" type="submit">Save</Button>
        </form>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
)(withStyles(styles)(Profile));
