import moment from 'moment';
import { dateRangePresets } from "./constants";

export function datesForDateRangePreset(dateRangePreset) {
  let startDate = moment().startOf('day');
  const endDate = moment().startOf('day');
  switch (dateRangePreset) {
    case dateRangePresets.YESTERDAY:
      startDate.subtract(1, 'day').startOf('day');
      endDate.subtract(1, 'day').endOf('day');
      break;
    case dateRangePresets.LAST_WEEK:
      startDate.subtract(1, 'week');
      break;
    case dateRangePresets.THIS_MONTH:
      startDate.startOf('month').startOf('day');
      break;
    case dateRangePresets.LAST_MONTH:
      startDate.subtract(1, 'month').startOf('month').startOf('day');
      endDate.subtract(1, 'month').endOf('month').endOf('day');
      break;
    case dateRangePresets.LAST_30_DAYS:
      startDate.subtract(30, 'days').startOf('day');
      break;
    case dateRangePresets.LAST_90_DAYS:
      startDate.subtract(90, 'days').startOf('day');
      break;
    case dateRangePresets.THIS_YEAR:
      startDate.startOf('year').startOf('day');
      break;
    case dateRangePresets.LAST_YEAR:
      startDate.subtract(1, 'year').startOf('year').startOf('day');
      endDate.subtract(1, 'year').endOf('year').endOf('day');
      break;
    case dateRangePresets.ALL_TIME:
      startDate = null;
      break;
    default:
      break;
  }
  return {
    startDate,
    endDate,
  };
}

export const defaultSalesDates = {
  startDate: moment().startOf('month').startOf('day').format(),
  endDate: moment().endOf('day').format(),
};

export function sortDateStrArray(ary) {
  return ary.sort((a, b) => (new Date(a) - new Date(b)));
}
