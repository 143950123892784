import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, GET_SUBSCRIPTION_SUCCESS} from '../../actions/types';
import Paper from '@material-ui/core/Paper';
import Actions from '../../actions';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {AuthStatePropTypes} from "../../propTypes";
import PropTypes from 'prop-types';

class Login extends Component {
  state = {
    email: '',
    password: '',
    showPassword: false,
  };

  componentDidMount() {
    const { location } = this.props,
      { search } = location;
    if (search && search.length) {
      const searchParams = new URLSearchParams(search),
        apiToken = searchParams.get('apiToken');
      if (apiToken && apiToken.length) {
        this.props.dispatch(Actions.loginWithToken(apiToken))
          .then(() => {
            Cookies.set('token', apiToken);
            this.props.history.push('/');
          });
      }
    }
  }

  login = (event) => {
    event.preventDefault();
    const email = document.getElementById('email').value.trim();
    const password = document.getElementById('password').value.trim();
    this.props.dispatch(Actions.login(email, password))
      .then((result) => {
        if (result.type === LOGIN_USER_SUCCESS) {
          Cookies.set('token', this.props.auth.user.api_token);
          if (this.props.auth.user.role === 'admin') {
            this.props.history.push('/');
          } else {
            this.props.history.push('/');
            this.props.dispatch(Actions.requestGetSubscription(this.props.auth.user.api_token)).then((result) => {
              if (result.type === GET_SUBSCRIPTION_SUCCESS) {
                if(result.payload.braintree_plan === null){
                  this.props.dispatch(Actions.newError("Please upgrade to a paid plan to continue to use BookKeeper."));
                  this.props.history.push('/billing/change-plan');
                }else if(result.payload.braintree_plan === 'free' && this.props.auth.user.monthly_earnings >= 1000){
                  this.props.dispatch(Actions.newError("Please upgrade to a paid plan to continue to use BookKeeper."));
                  this.props.history.push('/billing/change-plan');
                }else{
                  this.props.history.push('/');
                }
              }else{
                this.props.history.push('/billing/change-plan');
              }
            });
          }
        } else if (result.type === LOGIN_USER_FAILURE) {
          Cookies.expire('token');
          this.props.dispatch(Actions.newError("Username and password do not match"));
        }
      });
  }

  logout = () => {
    this.props.dispatch(Actions.logout());
  }

  handleChange = (prop) => {
    return function (event) {
      this.setState({ [prop]: event.target.value });
    }.bind(this);
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.contentContainer}>
        <Typography component="h2" variant="h5" className={classes.uppercase}>
          { this.props.auth.user.id ? 'Logout' : 'Login' }
        </Typography>
        <Paper style={styles.paper} elevation={0}>
          {Cookies.get('token')
            ? (
              <div className="form-container">
                <p className="logged-in-as">{`Logged in as ${this.props.auth.user.email}`}</p>
                <Button variant="contained" color="primary" onClick={() => this.logout()}>Log out</Button>
              </div>
            )
            : (
              <div className="form-container">
                <form onSubmit={event => this.login(event)}>
                  <div className="login-form-inputs">
                    <FormControl>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        id="email"
                        errors={this.props.auth}
                        type="text"
                        value={this.state.email}
                        placeholder="Email"
                        onChange={this.handleChange('email')}
                        className={this.props.classes.textField}
                      />
                    </FormControl>
                    <br/>
                    <FormControl>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        id="password"
                        errors={this.props.auth}
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        placeholder="Password"
                        onChange={this.handleChange('password')}
                        style={{width: 320}}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                  </div>
                  <br />
                  <div>
                    <Button variant="contained" color="primary" type="submit">Log In</Button>
                  </div>
                </form>
                <br />
                <Link className="button-link" to="/forgot-password">
                  <Button color="secondary">
                    Forgot Password?
                  </Button>
                </Link>
                <br />
                <Link className="button-link" to="/pricing">
                  <Button color="secondary">
                    Need to sign up?
                  </Button>
                </Link>

              </div>
            )
          }
        </Paper>
      </div>
    );
  }
}

Login.defaultProps = {
  auth: {},
};

Login.propTypes = {
  auth: AuthStatePropTypes,
  classes: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    auth: state.auth,
  }),
)(withStyles(styles, { withTheme: true })(Login));
