import React, {PureComponent} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import AutocompleteSelect from '../blocks/AutocompleteSelect';
import { sortObjAryByKey } from "../../utilities/array-helpers";
import CreatableSelect from 'react-select/creatable';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

class ExpenseForm extends PureComponent {
  state = {
    expenseType: 'single',
  };

  categoryOptions = () => {
    const categories = this.props.categories || [];
    return sortObjAryByKey(categories.map(x => ({ label: x.title, value: x.id })), 'label');
  };

  onCategoryChange = data => {
    if (!data) this.changeCategory(null);
    else if (typeof data === 'object' && 'value' in data) this.changeCategory(data);
    else if (typeof data === 'string') {
      this.props.postCategory(data)
    }
  };

  onBookChange = book => {
    if (book && typeof book === 'object' && 'value' in book) this.changeBook(book);
    else this.changeBook(null);
  };

  changeBook = book => this.props.handleChange('book')(book);

  changeCategory = category => this.props.handleChange('category')(category);

  onSubmit = () => {
    const form = document.getElementById('expense-form');
    const formData = new FormData(form);
    this.props.onSubmit(formData);
  };

  bookOptions = () => {
    return sortObjAryByKey(Object.values(this.props.books), 'title').map(option => ({
      value: option.id,
      label: option.title
    }))
  };

  maybeHasError = field => {
    const error = this.props.error || {};
    return error && field in error;
  };

  onExpenseTypeChange = (e, val) => {
    this.setState({ expenseType: val }, () => {
      if (val !== 'recurring') this.props.handleChange('repeat_type')('');
    });
  };

  render() {
    const { expenseType } = this.state,
      isRecurring = expenseType === 'recurring';
    const { amount, title, vendor, account, date, category, book, theme, repeat_type } = this.props,
      parsedAmount = amount && typeof amount === 'string'
        ? parseFloat(amount)
        : amount;
    return (
      <div>
        <FormControl component="fieldset" className={this.props.classes.formGridItem}>
          <RadioGroup
            aria-label="Expense Type"
            name="expense-type"
            row
            value={repeat_type && repeat_type.length ? 'recurring' : this.state.expenseType}
            onChange={this.onExpenseTypeChange}
          >
            <FormControlLabel value="single" control={<Radio />} label="One-Time" />
            <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
          </RadioGroup>
        </FormControl>
        <form
          id="expense-form"
          autoComplete="off"
        >
          <Grid container>
            <Grid xs={12} item className={this.props.classes.formGridItem} style={{
              display: repeat_type && repeat_type.length || isRecurring ? 'flex' : 'none',
            }}>
              <FormControl style={{ flex: 1 }}>
                <InputLabel htmlFor="repeat_type">Repeat Type</InputLabel>
                <Select
                  value={repeat_type || ''}
                  onChange={this.props.handleChange('repeat_type')}
                  input={<Input name="repeat_type" id="repeat_typ"/>}
                  className={this.props.classes.formGridField}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} className={this.props.classes.formGridItem}>
              <TextField
                id="title"
                name="title"
                label="Title"
                onChange={this.props.handleChange('title')}
                margin="normal"
                className={this.props.classes.formGridField}
                value={title}
                InputLabelProps={{
                  shrink: !!(title && title.length)
                }}
                error={this.maybeHasError('title')}
                required={true}
              />
            </Grid>
            <Grid item xs={4} className={this.props.classes.formGridItem}>
              <TextField
                id="amount"
                name="amount"
                label="Amount"
                onChange={this.props.handleChange('amount')}
                margin="normal"
                className={this.props.classes.formGridField}
                style={{
                  flex: 1
                }}
                value={parsedAmount}
                type="number"
                InputLabelProps={{
                  shrink: typeof parsedAmount === 'number'
                }}
                error={this.maybeHasError('amount')}
                required={true}
              />
            </Grid>
            <Grid item xs={4} className={this.props.classes.formGridItem}>
              <TextField
                id="date"
                name="date"
                label="Date"
                onChange={this.props.handleChange('date')}
                margin="normal"
                className={this.props.classes.formGridField}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={date}
                error={this.maybeHasError('date')}
                required={true}
              />
            </Grid>
            <Grid item xs={6} className={this.props.classes.formGridItem}>
              <TextField
                id="vendor"
                name="vendor"
                label="Vendor"
                onChange={this.props.handleChange('vendor')}
                margin="normal"
                className={this.props.classes.formGridField}
                value={vendor}
                InputLabelProps={{
                  shrink: !!(vendor && vendor.length)
                }}
              />
            </Grid>
            <Grid item xs={6} className={this.props.classes.formGridItem}>
              <TextField
                id="account"
                name="account"
                label="Account"
                onChange={this.props.handleChange('account')}
                margin="normal"
                className={this.props.classes.formGridField}
                value={account}
                InputLabelProps={{
                  shrink: !!(account && account.length)
                }}
              />
            </Grid>
            <Grid item xs={6} className={this.props.classes.formGridItem}>
              <CreatableSelect
                isClearable
                onChange={this.onCategoryChange}
                onCreateOption={this.props.postCategory}
                options={this.categoryOptions()}
                value={category}
                placeholder="Category"
                id="category_ids"
                name="category_ids"
                className={this.props.classes.formGridField}
                styles={{
                  control: base => ({
                    ...base,
                    ...(this.maybeHasError('category_ids') ? { borderColor: theme.palette.error.main } : {}),
                  })
                }}
              />
            </Grid>
            <Grid item xs={6} className={this.props.classes.formGridItem}>
              <AutocompleteSelect
                options={this.bookOptions()}
                onChange={this.onBookChange}
                className={this.props.classes.formGridField}
                value={book}
                placeholder="Book"
                id="book_id"
                name="book_id"
                error={this.maybeHasError('book_id')}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={() => this.onSubmit()}>
            Submit
          </Button>
        </form>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ExpenseForm);
