// AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGIN_USER_WITH_TOKEN_SUCCESS = 'LOGIN_USER_WITH_TOKEN_SUCCESS';
export const LOGIN_USER_WITH_TOKEN_FAILURE = 'LOGIN_USER_WITH_TOKEN_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAILURE = 'RESET_PASSWORD_EMAIL_FAILURE';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_USER_JOBS = 'UPDATE_USER_JOBS';

// BOOKS
export const GET_ALL_BOOKS = 'GET_ALL_BOOKS';
export const GET_ALL_BOOKS_SUCCESS = 'GET_ALL_BOOKS_SUCCESS';
export const GET_ALL_BOOKS_FAILURE = 'GET_ALL_BOOKS_FAILURE';
export const GET_SALES_BY_DATE = 'GET_SALES_BY_DATE';
export const GET_SALES_BY_DATE_SUCCESS = 'GET_SALES_BY_SUCCESS';
export const GET_SALES_BY_DATE_FAILURE = 'GET_SALES_BY_FAILURE';

export const POST_BOOK_TAGS = 'POST_BOOK_TAGS';
export const POST_BOOK_TAGS_SUCCESS = 'POST_BOOK_TAGS_SUCCESS';
export const POST_BOOK_TAGS_FAILURE = 'POST_BOOK_TAGS_FAILURE';
export const UPDATE_BOOK_TAGS = 'UPDATE_BOOK_TAGS';
export const SET_BOOK_TAGS = 'SET_BOOK_TAGS';

// BRAINTREE
export const GET_BRAINTREE_TOKEN = 'GET_BRAINTREE_TOKEN';
export const GET_BRAINTREE_TOKEN_SUCCESS = 'GET_BRAINTREE_TOKEN_SUCCESS';
export const GET_BRAINTREE_TOKEN_FAILURE = 'GET_BRAINTREE_TOKEN_FAILURE';

export const POST_CREATE_SUBSCRIPTION = 'POST_CREATE_SUBSCRIPTION';
export const POST_CREATE_SUBSCRIPTION_SUCCESS = 'POST_CREATE_SUBSCRIPTION_SUCCESS';
export const POST_CREATE_SUBSCRIPTION_FAILURE = 'POST_CREATE_SUBSCRIPTION_FAILURE';

export const POST_CANCEL_SUBSCRIPTION = 'POST_CANCEL_SUBSCRIPTION';
export const POST_CANCEL_SUBSCRIPTION_SUCCESS = 'POST_CANCEL_SUBSCRIPTION_SUCCESS';
export const POST_CANCEL_SUBSCRIPTION_FAILURE = 'POST_CANCEL_SUBSCRIPTION_FAILURE';

export const POST_CHANGE_PLAN = 'POST_CHANGE_PLAN';
export const POST_CHANGE_PLAN_SUCCESS = 'POST_CHANGE_PLAN_SUCCESS';
export const POST_CHANGE_PLAN_FAILURE = 'POST_CHANGE_PLAN_FAILURE';

export const POST_UPDATE_CARD = 'POST_UPDATE_CARD';
export const POST_UPDATE_CARD_SUCCESS = 'POST_UPDATE_CARD_SUCCESS';
export const POST_UPDATE_CARD_FAILURE = 'POST_UPDATE_CARD_FAILURE';

export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS';
export const GET_ALL_PLANS_FAILURE = 'GET_ALL_PLANS_FAILURE';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE';

export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_FAILURE = 'GET_ALL_INVOICES_FAILURE';

export const GET_INVOICE_PDF = 'GET_INVOICE_PDF';
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_PDF_FAILURE = 'GET_INVOICE_PDF_FAILURE';

// EXPENSES
export const GET_ALL_EXPENSES = 'GET_ALL_EXPENSES';
export const GET_ALL_EXPENSES_SUCCESS = 'GET_ALL_EXPENSES_SUCCESS';
export const GET_ALL_EXPENSES_FAILURE = 'GET_ALL_EXPENSES_FAILURE';

export const GET_ALL_RECURRING_EXPENSES = 'GET_ALL_RECURRING_EXPENSES';
export const GET_ALL_RECURRING_EXPENSES_SUCCESS = 'GET_ALL_RECURRING_EXPENSES_SUCCESS';
export const GET_ALL_RECURRING_EXPENSES_FAILURE = 'GET_ALL_RECURRING_EXPENSES_FAILURE';

export const GET_EXPENSE = 'GET_EXPENSE';
export const GET_EXPENSE_SUCCESS = 'GET_EXPENSE_SUCCESS';
export const GET_EXPENSE_FAILURE = 'GET_EXPENSE_FAILURE';

export const POST_EXPENSE = 'POST_EXPENSE';
export const POST_EXPENSE_SUCCESS = 'POST_EXPENSE_SUCCESS';
export const POST_EXPENSE_FAILURE = 'POST_EXPENSE_FAILURE';

export const PATCH_EXPENSE = 'PATCH_EXPENSE';
export const PATCH_EXPENSE_SUCCESS = 'PATCH_EXPENSE_SUCCESS';
export const PATCH_EXPENSE_FAILURE = 'PATCH_EXPENSE_FAILURE';

export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS';
export const DELETE_EXPENSE_FAILURE = 'DELETE_EXPENSE_FAILURE';

export const GET_ALL_EXPENSE_CATEGORIES = 'GET_ALL_EXPENSE_CATEGORIES';
export const GET_ALL_EXPENSE_CATEGORIES_SUCCESS = 'GET_ALL_EXPENSE_CATEGORIES_SUCCESS';
export const GET_ALL_EXPENSE_CATEGORIES_FAILURE = 'GET_ALL_EXPENSE_CATEGORIES_FAILURE';

export const POST_CATEGORY = 'POST_CATEGORY';
export const POST_CATEGORY_SUCCESS = 'POST_CATEGORY_SUCCESS';
export const POST_CATEGORY_FAILURE = 'POST_CATEGORY_FAILURE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

// FACEBOOK
export const POST_FACEBOOK_TOKEN = 'POST_FACEBOOK_TOKEN';
export const POST_FACEBOOK_TOKEN_SUCCESS = 'POST_FACEBOOK_TOKEN_SUCCESS';
export const POST_FACEBOOK_TOKEN_FAILURE = 'POST_FACEBOOK_TOKEN_FAILURE';

export const DELETE_FACEBOOK_TOKEN = 'DELETE_FACEBOOK_TOKEN';
export const DELETE_FACEBOOK_TOKEN_SUCCESS = 'DELETE_FACEBOOK_TOKEN_SUCCESS';
export const DELETE_FACEBOOK_TOKEN_FAILURE = 'DELETE_FACEBOOK_TOKEN_FAILURE';

export const GET_IMPORT_FACEBOOK_AD_CAMPAIGNS = 'GET_IMPORT_FACEBOOK_AD_CAMPAIGNS';
export const GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS = 'GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS';
export const GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE = 'GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE';

export const GET_ALL_FACEBOOK_CAMPAIGNS = 'GET_ALL_FACEBOOK_CAMPAIGNS';
export const GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS = 'GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS';
export const GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE = 'GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE';

export const GET_FB_CAMPAIGN = 'GET_FACEBOOK_CAMPAIGN';
export const GET_FB_CAMPAIGN_SUCCESS = 'GET_FACEBOOK_CAMPAIGN_SUCCESS';
export const GET_FB_CAMPAIGN_FAILURE = 'GET_FACEBOOK_CAMPAIGN_FAILURE';

export const PATCH_FB_CAMPAIGN = 'PATCH_FB_CAMPAIGN';
export const PATCH_FB_CAMPAIGN_SUCCESS = 'PATCH_FB_CAMPAIGN_SUCCESS';
export const PATCH_FB_CAMPAIGN_FAILURE = 'PATCH_FB_CAMPAIGN_FAILURE';

export const DELETE_FB_CAMPAIGN = 'DELETE_FB_CAMPAIGN';
export const DELETE_FB_CAMPAIGN_SUCCESS = 'DELETE_FB_CAMPAIGN_SUCCESS';
export const DELETE_FB_CAMPAIGN_FAILURE = 'DELETE_FB_CAMPAIGN_FAILURE';

export const POST_FB_CAMPAIGN_EXPORT = 'POST_FB_CAMPAIGN_IMPORT';
export const POST_FB_CAMPAIGN_EXPORT_SUCCESS = 'POST_FB_CAMPAIGN_IMPORT_SUCCESS';
export const POST_FB_CAMPAIGN_EXPORT_FAILURE = 'POST_FB_CAMPAIGN_IMPORT_FAILURE';

export const GET_FB_IMPORT_JOBS = 'GET_FB_IMPORT_JOBS';
export const GET_FB_IMPORT_JOBS_SUCCESS = 'GET_FB_IMPORT_JOBS_SUCCESS';
export const GET_FB_IMPORT_JOBS_FAILURE = 'GET_FB_IMPORT_JOBS_FAILURE';

export const GET_FB_CAMPAIGN_SPEND = 'GET_FB_CAMPAIGN_SPEND';
export const GET_FB_CAMPAIGN_SPEND_SUCCESS = 'GET_FB_CAMPAIGN_SPEND_SUCCESS';
export const GET_FB_CAMPAIGN_SPEND_FAILURE = 'GET_FB_CAMPAIGN_SPEND_FAILURE';

// SALES
export const GET_ALL_SALES = 'GET_ALL_SALES';
export const GET_ALL_SALES_SUCCESS = 'GET_ALL_SALES_SUCCESS';
export const GET_ALL_SALES_FAILURE = 'GET_ALL_SALES_FAILURE';

// MESSAGES
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const NEW_ERROR = 'NEW_ERROR';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// RATES
export const GET_RATE = 'GET_RATE';
export const GET_RATE_SUCCESS = 'GET_RATE_SUCCESS';
export const GET_RATE_FAILURE = 'GET_RATE_FAILURE';
export const GET_RATE_DROPS = 'GET_RATE_DROPS';
export const GET_RATE_DROPS_SUCCESS = 'GET_RATE_DROPS_SUCCESS';
export const GET_RATE_DROPS_FAILURE = 'GET_RATE_DROPS_FAILURE';

export const POST_RATE = 'POST_RATE';
export const POST_RATE_SUCCESS = 'POST_RATE_SUCCESS';
export const POST_RATE_FAILURE = 'POST_RATE_FAILURE';

export const GET_ALL_RATES = 'GET_ALL_RATES';
export const GET_ALL_RATES_SUCCESS = 'GET_ALL_RATES_SUCCESS';
export const GET_ALL_RATES_FAILURE = 'GET_ALL_RATES_FAILURE';

export const PATCH_RATE = 'PATCH_RATE';
export const PATCH_RATE_SUCCESS = 'PATCH_RATE_SUCCESS';
export const PATCH_RATE_FAILURE = 'PATCH_RATE_FAILURE';

export const DELETE_RATE = 'DELETE_RATE';
export const DELETE_RATE_SUCCESS = 'DELETE_RATE_SUCCESS';
export const DELETE_RATE_FAILURE = 'DELETE_RATE_FAILURE';

// EXCHANGE RATES
export const GET_ALL_EXCHANGE_RATES = 'GET_ALL_EXCHANGE_RATES';
export const GET_ALL_EXCHANGE_RATES_SUCCESS = 'GET_ALL_EXCHANGE_RATES_SUCCESS';
export const GET_ALL_EXCHANGE_RATES_FAILURE = 'GET_ALL_EXCHANGE_RATES_FAILURE';

// CUSTOM RATES
export const GET_ALL_CUSTOM_RATES = 'GET_ALL_CUSTOM_RATES';
export const GET_ALL_CUSTOM_RATES_SUCCESS = 'GET_ALL_CUSTOM_RATES_SUCCESS';
export const GET_ALL_CUSTOM_RATES_ERROR = 'GET_ALL_CUSTOM_RATES_ERROR';
export const POST_CUSTOM_RATE = 'POST_CUSTOM_RATE';
export const POST_CUSTOM_RATE_SUCCESS = 'POST_CUSTOM_RATE_SUCCESS';
export const POST_CUSTOM_RATE_FAILURE = 'POST_CUSTOM_RATE_FAILURE';
export const PATCH_CUSTOM_RATE = 'PATCH_CUSTOM_RATE';
export const PATCH_CUSTOM_RATE_SUCCESS = 'PATCH_CUSTOM_RATE_SUCCESS';
export const PATCH_CUSTOM_RATE_FAILURE = 'PATCH_CUSTOM_RATE_FAILURE';
export const DELETE_CUSTOM_RATE = 'DELETE_CUSTOM_RATE';
export const DELETE_CUSTOM_RATE_SUCCESS = 'DELETE_CUSTOM_RATE_SUCCESS';
export const DELETE_CUSTOM_RATE_FAILURE = 'DELETE_CUSTOM_RATE_FAILURE';

export const GET_PROFIT_AND_LOSS = 'GET_PROFIT_AND_LOSS';
export const SET_PROFIT_AND_LOSS = 'SET_PROFIT_AND_LOSS';
export const GET_PROFIT_AND_LOSS_SUCCESS = 'GET_PROFIT_AND_LOSS_SUCCESS';
export const GET_PROFIT_AND_LOSS_FAILURE = 'GET_PROFIT_AND_LOSS_FAILURE';

export const POST_TAG = 'POST_TAG';
export const POST_TAG_SUCCESS = 'POST_TAG_SUCCESS';
export const POST_TAG_FAILURE = 'POST_TAG_FAILURE';
export const SET_TAGS = 'SET_TAGS';
export const UPDATE_TAGS = 'UPDATE_TAGS';

export const GET_ACCOUNT_SNAPSHOT = 'GET_ACCOUNT_SNAPSHOT';
export const GET_ACCOUNT_SNAPSHOT_SUCCESS = 'GET_ACCOUNT_SNAPSHOT_SUCCESS';
export const GET_ACCOUNT_SNAPSHOT_FAILURE = 'GET_ACCOUNT_SNAPSHOT_FAILURE';


// AMS
export const POST_AMS_EXPORT = 'POST_AMS_EXPORT';
export const POST_AMS_EXPORT_SUCCESS = 'POST_AMS_EXPORT_SUCCESS';
export const POST_AMS_EXPORT_FAILURE = 'POST_AMS_EXPORT_FAILURE';
export const GET_AMS_CAMPAIGNS = 'GET_AMS_CAMPAIGNS';
export const GET_AMS_CAMPAIGNS_SUCCESS = 'GET_AMS_CAMPAIGNS_SUCCESS';
export const GET_AMS_CAMPAIGNS_FAILURE = 'GET_AMS_CAMPAIGNS_FAILURE';
export const PATCH_AMS_CAMPAIGN = 'PATCH_AMS_CAMPAIGN';
export const PATCH_AMS_CAMPAIGN_SUCCESS = 'PATCH_AMS_CAMPAIGN_SUCCESS';
export const PATCH_AMS_CAMPAIGN_FAILURE = 'PATCH_AMS_CAMPAIGN_FAILURE';
export const GET_AMS_SPEND_BY_DATE = 'GET_AMS_SPEND_BY_DATE';
export const GET_AMS_SPEND_BY_DATE_SUCCESS = 'GET_AMS_SPEND_BY_DATE_SUCCESS';
export const GET_AMS_SPEND_BY_DATE_FAILURE = 'GET_AMS_SPEND_BY_DATE_FAILURE';
export const POST_AMAZON_TOKEN = 'POST_AMAZON_TOKEN';
export const POST_AMAZON_TOKEN_SUCCESS = 'POST_AMAZON_TOKEN_SUCCESS';
export const POST_AMAZON_TOKEN_FAILURE = 'POST_AMAZON_TOKEN_FAILURE';
export const GET_HISTORICAL_AMS_IMPORT = 'GET_HISTORICAL_AMS_IMPORT';
export const GET_HISTORICAL_AMS_IMPORT_SUCCESS = 'GET_HISTORICAL_AMS_IMPORT_SUCCESS';
export const GET_HISTORICAL_AMS_IMPORT_FAILURE = 'GET_HISTORICAL_AMS_IMPORT_FAILURE';
