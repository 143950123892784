import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import _ from 'lodash';
import DropIn from 'braintree-web-drop-in-react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Actions from '../../actions';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import {doesItemExistInState} from '../../utilities/exist-in-state'
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from '../../actions/types';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      showPassword: false,
    };
  }

  instance;

  componentDidMount() {
    if(_.isEmpty(this.props.clientToken)) {
      this.props.dispatch(Actions.requestGetBraintreeToken());
    }
    if(_.isEmpty(this.props.plans)) {
      this.props.dispatch(Actions.requestGetAllPlans());
    }
  }

  register = (event) => {
    event.preventDefault();
    this.instance.requestPaymentMethod().then(({ nonce }) => {
      const form = document.getElementById('register-form');
      let formData = new FormData(form);
      formData.append('token', nonce);
      if(this.props.match.params.affiliate) {
        const affiliate_id = atob(this.props.match.params.affiliate).split("affiliate-link-")[1];
        formData.append('affiliate_id', affiliate_id);
      }
      this.props.dispatch(Actions.register(formData))
        .then((result) => {
          if (result.type === REGISTER_SUCCESS) {
            Cookies.set('token', this.props.auth.user.api_token);
            this.props.dispatch(Actions.newMessage('Welcome!'));
            this.props.history.push('/');
          } else if (result.type === REGISTER_FAILURE) {
            Cookies.expire('token');
            // if we have errors, build our error message
            if (this.props.auth.error) {
              const errors = this.props.auth.error;
              let errorMessage = "";
              for(let key in errors) {
                const newErrors = errors[key].map(message => message + ' ');
                errorMessage += newErrors;
              }
              this.props.dispatch(Actions.newError(errorMessage));
            } else {
              this.props.dispatch(Actions.newError("Something went wrong, please try again."));
            }
          }
        });
    });
  }

  handleChange = (prop) => {
    return function (event) {
      this.setState({ [prop]: event.target.value });
    }.bind(this);
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    if (this.props.isFetching || _.isEmpty(this.props.clientToken || _.isEmpty(this.props.plans))) {
      return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
    }
    const plan = doesItemExistInState(this.props.plans, this.props.match.params.plan);
    return (
      <div style={styles.viewContainer}>
        <h2 style={styles.heading}>Register</h2>
        <Paper style={styles.paper}>
          <h3 style={styles.paperHeading}>{plan.name}</h3>
          <p>Price: ${plan.price} / {plan.billingFrequency === 12 ? 'year' : 'month'}</p>
          <p>Description: {plan.description}</p>
          <form id="register-form" onSubmit={event => this.register(event)}>
            <FormControl>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                errors={this.props.auth}
                type="text"
                value={this.state.email}
                placeholder="Email"
                onChange={this.handleChange('email')}
                className={this.props.classes.textField}
              />
            </FormControl>
            <br/>
            <FormControl>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                name="password"
                errors={this.props.auth}
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                placeholder="Password"
                onChange={this.handleChange('password')}
                style={{width: 320}}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <input type="hidden" name="plan" value={plan.id} />
            <br />
            <br />
            <br />
            <DropIn
              options={{ authorization: this.props.clientToken, paypal: { flow: 'vault'} }}
              onInstance={instance => (this.instance = instance)}
            />
            <div className="login-button">
              {this.props.auth.isFetching ? <CircularProgress /> : <Button variant="contained" className="login-submit" color="primary" type="submit">Register</Button>}
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    plans: state.braintree.plans,
    clientToken: state.braintree.clientToken,
    isFetching: state.braintree.isFetching,
  }),
)(withStyles(styles)(Register));
