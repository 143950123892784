import {
  GET_FB_CAMPAIGN_SPEND,
  GET_FB_CAMPAIGN_SPEND_FAILURE,
  GET_FB_CAMPAIGN_SPEND_SUCCESS,
  DELETE_FACEBOOK_TOKEN,
  DELETE_FACEBOOK_TOKEN_SUCCESS,
  DELETE_FACEBOOK_TOKEN_FAILURE,
} from "./types";

import { getFbCampaignSpendRequest, deleteFbTokenRequest } from '../api/facebook';

function getFbCampaignSpend() {
  return dispatch => dispatch({ type: GET_FB_CAMPAIGN_SPEND });
}

function getFbCampaignSpendSuccess(payload) {
  return dispatch => dispatch({ type: GET_FB_CAMPAIGN_SPEND_SUCCESS, payload });
}

function getFbCampaignSpendFailure(payload) {
  return dispatch => dispatch({ type: GET_FB_CAMPAIGN_SPEND_FAILURE, payload });
}

export function requestGetFbCampaignSpend(token, startDate, endDate) {
  return (dispatch) => {
    dispatch(getFbCampaignSpend());
    return getFbCampaignSpendRequest(token, startDate, endDate)
      .then(json => dispatch(getFbCampaignSpendSuccess(json)))
      .catch(e => dispatch(getFbCampaignSpendFailure(e)));
  };
}

export function requestDeleteFbToken(token) {
  return (dispatch) => {
    dispatch({ type: DELETE_FACEBOOK_TOKEN });
    return deleteFbTokenRequest(token)
      .then(json => dispatch({ type: DELETE_FACEBOOK_TOKEN_SUCCESS, payload: json }))
      .catch(e => dispatch({ type: DELETE_FACEBOOK_TOKEN_FAILURE, payload: e }));
  };
}
