import { postUpdateCardRequest } from '../api/braintree';

import {
  POST_UPDATE_CARD,
  POST_UPDATE_CARD_SUCCESS,
  POST_UPDATE_CARD_FAILURE,
}
 from '../actions/types'

function postUpdateCard() {
  return {
    type: POST_UPDATE_CARD,
  };
}

function postUpdateCardSuccess(json) {
  return {
    type: POST_UPDATE_CARD_SUCCESS,
    payload: json,
  };
}

function postUpdateCardFailure(error) {
  return {
    type: POST_UPDATE_CARD_FAILURE,
    payload: error,
  };
}

export default function requestPostUpdateCard(token, apiToken){
  return (dispatch) => {
    dispatch(postUpdateCard());
    return postUpdateCardRequest(token, apiToken)
    .then((json) => dispatch(postUpdateCardSuccess(json)))
    .catch(error => error.json().then(errors => dispatch(postUpdateCardFailure(errors))));
  };
}
