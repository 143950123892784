import {
  PATCH_CUSTOM_RATE,
  PATCH_CUSTOM_RATE_FAILURE,
  PATCH_CUSTOM_RATE_SUCCESS,
} from "./types";
import { patchCustomRateRequest } from "../api/custom-rates";

function patchCustomRate() {
  return { type: PATCH_CUSTOM_RATE };
}

function patchCustomRateSuccess(payload) {
  return { type: PATCH_CUSTOM_RATE_SUCCESS, payload };
}

function patchCustomRateFailure(payload) {
  return { type: PATCH_CUSTOM_RATE_FAILURE, payload };
}

export default function requestPatchCustomRate(formData, id, token) {
  return (dispatch) => {
    dispatch(patchCustomRate());
    return patchCustomRateRequest(formData, id, token)
      .then(json => dispatch(patchCustomRateSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(patchCustomRateFailure(eJson))));
  };
}
