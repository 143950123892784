import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const WithLoading = ({ loading, children }) => {
  const loadingOverlay = (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(1,1,1,.2)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    }}
    >
      <CircularProgress size={80} />
    </div>
  );
  return (
    <div style={{ position: 'relative' }}>
      { !!loading && loadingOverlay }
      { children }
    </div>
  );
};
