import { patchUserRequest } from '../api/users';

import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from './types';

function updateUser() {
  return {
    type: UPDATE_USER,
  };
}

function updateUserSuccess(json) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: json,
  };
}

function updateUserFailure(error) {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
}

export default function requestUpdateUser(id, formData, token) {
  return (dispatch) => {
    dispatch(updateUser());
    return patchUserRequest(id, formData, token)
      .then(json => dispatch(updateUserSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(updateUserFailure(errors))));
  };
}
