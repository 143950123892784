import { getAllBooksRequest } from '../api/books';

import {
  GET_ALL_BOOKS,
  GET_ALL_BOOKS_SUCCESS,
  GET_ALL_BOOKS_FAILURE,
} from './types';

function getAllBooks() {
  return {
    type: GET_ALL_BOOKS,
  };
}

function getAllBooksSuccess(json) {
  return {
    type: GET_ALL_BOOKS_SUCCESS,
    payload: json,
  };
}

function getAllBooksFailure(error) {
  return {
    type: GET_ALL_BOOKS_FAILURE,
    payload: error,
  };
}

export default function requestGetAllBooks(token) {
  return (dispatch) => {
    dispatch(getAllBooks());
    return getAllBooksRequest(token)
      .then(json => dispatch(getAllBooksSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllBooksFailure(errors))));
  };
}
