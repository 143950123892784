import {
  PATCH_RATE,
  PATCH_RATE_FAILURE,
  PATCH_RATE_SUCCESS,
  DELETE_RATE,
  DELETE_RATE_FAILURE,
  DELETE_RATE_SUCCESS,
} from "./types";

import { patchRateRequest, deleteRateRequest } from "../api/rates";

function patchRate() {
  return dispatch => dispatch({ type: PATCH_RATE });
}

function patchRateSuccess(payload) {
  return dispatch => dispatch({ type: PATCH_RATE_SUCCESS, payload });
}

function patchRateFailure(payload) {
  return dispatch => dispatch({ type: PATCH_RATE_FAILURE, payload });
}

export function requestPatchRate(rateId, formData, token) {
  return (dispatch) => {
    dispatch(patchRate());
    return patchRateRequest(rateId, formData, token)
      .then(json => dispatch(patchRateSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(patchRateFailure(eJson))));
  };
}

function deleteRate() {
  return dispatch => dispatch({ type: DELETE_RATE });
}

function deleteRateSuccess(payload) {
  return dispatch => dispatch({ type: DELETE_RATE_SUCCESS, payload });
}

function deleteRateFailure(payload) {
  return dispatch => dispatch({ type: DELETE_RATE_FAILURE, payload });
}

export function requestDeleteRate(rateId, token) {
  return (dispatch) => {
    dispatch(deleteRate());
    return deleteRateRequest(rateId, token)
      .then(json => dispatch(deleteRateSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(deleteRateFailure(eJson))));
  };
}
