import {
  POST_CUSTOM_RATE,
  POST_CUSTOM_RATE_SUCCESS,
  POST_CUSTOM_RATE_FAILURE,
} from "./types";
import { postCustomRateRequest } from "../api/custom-rates";

function postCustomRate() {
  return { type: POST_CUSTOM_RATE };
}

function postCustomRateSuccess(payload) {
  return { type: POST_CUSTOM_RATE_SUCCESS, payload };
}

function postCustomRateFailure(payload) {
  return { type: POST_CUSTOM_RATE_FAILURE, payload };
}

export default function requestPostCustomRate(formData, token) {
  return (dispatch) => {
    dispatch(postCustomRate());
    return postCustomRateRequest(formData, token)
      .then(json => dispatch(postCustomRateSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(postCustomRateFailure(eJson))));
  };
}
