import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    text: {
      primary: '#3c3c3c',
    },
    common: {
      darkBlue: '#28435c',
      lightGrey: '#f0f0f0',
      lightBlue: '#42d6e2',
      revenue: '#009fd9',
      expenses: '#d81921',
      profit: '#13bf1f',
      darkGrey: '#2e2e2e',
    },
    primary: {
      light: '#81ffff',
      main: '#42d6e2',
      dark: '#00a4b0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#546e89',
      main: '#28435c',
      dark: '#001c32',
      contrastText: '#ffffff',
    },
  },
});

export default theme;
