import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import _ from 'lodash';
import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Actions from './actions';
import { LOGIN_USER_WITH_TOKEN_FAILURE } from './actions/types';
import PrivateRoute from './utilities/PrivateRoute';
import NavigationDrawer from './components/blocks/NavigationDrawer';
import Settings from './components/pages/Settings';
import Admin from './components/pages/Admin';
import ViewSalesByBook from './components/pages/ViewSalesByBook';
import ViewExpenses from './components/pages/ViewExpenses';
import ViewExpensesByCategory from './components/pages/ViewExpensesByCategory';
import ConnectFacebook from './components/pages/ConnectFacebook';
import ViewAmsCampaigns from './components/pages/ViewAmsCampaigns';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import ViewCustomRates from './components/pages/ViewCustomRates';
import BillingDashboard from './components/pages/BillingDashboard';
import ViewPlans from './components/pages/ViewPlans';
import ChangePlan from './components/pages/ChangePlan';
import ChangeCard from './components/pages/ChangeCard';
import ViewInvoices from './components/pages/ViewInvoices';
import ExpenseManager from './components/pages/ExpenseManager';
import RecurringExpenses from "./components/pages/RecurringExpenses";
import Campaigns from './components/pages/Campaigns';
import theme from './theme';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutTimeoutPointer: null,
    };
  }

  componentDidMount() {
    if (Cookies.get('token') && !_.get(this.props, 'auth.user.api_token')) {
      this.props.dispatch(Actions.loginWithToken(Cookies.get('token')))
        .then((result) => {
          if (result.type === LOGIN_USER_WITH_TOKEN_FAILURE) {
            Cookies.expire('token');
            this.props.history.push('/login');
          }
        });
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.dispatch(Actions.clearMessage());
  };

  handleExited = () => {
    this.props.dispatch(Actions.clearMessage());
  };

  renderAlert = () => {
    const { message } = this.props,
      { message: msg, autoHideDuration, backgroundColor, actions, showLoading } = message || {};
    return (
      <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={!!msg}
        autoHideDuration={autoHideDuration === -1 ? null : (autoHideDuration || 6000)}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <SnackbarContent
          onClose={this.handleClose}
          message={
            <div style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
              { !!showLoading && <LinearProgress /> }
              <Typography variant="body1" style={{ color: 'white', marginTop: 5 }}>{ msg }</Typography>
            </div>
          }
          style={{ backgroundColor }}
          action={(actions || []).map(action => {
            switch (action) {
              case 'close':
                return <IconButton
                  key="close"
                  color="primary"
                  onClick={this.handleClose}
                ><CloseIcon/></IconButton>;
              case 'refresh':
                return <IconButton
                  key="refresh"
                  color="primary"
                  onClick={() => window.location.reload()}
                ><RefreshIcon/></IconButton>;
              default:
                return undefined;
            }
          })}
        />
      </Snackbar>
    )
  };

  render() {
    return (
      <div id="App">
        <MuiThemeProvider theme={theme}>
          <div className="main-container">
            <NavigationDrawer />
            <div className="views">
              <div className="inner">
                {this.props.children}
              </div>
            </div>
          </div>
          {
            this.renderAlert()
          }
        </MuiThemeProvider>
        <MuiThemeProvider theme={theme}>
          <PrivateRoute exact path="/" component={ViewSalesByBook} />
          <PrivateRoute exact path="/expenses" component={ViewExpenses} />
          <PrivateRoute exact path="/expense" component={ExpenseManager} />
          <PrivateRoute exact path="/expense/:id" component={ExpenseManager} />
          <PrivateRoute exact path="/recurring_expenses" component={RecurringExpenses} />
          <PrivateRoute exact path="/expenses-by-category" component={ViewExpensesByCategory} />
          <PrivateRoute exact path="/rates" component={ViewCustomRates} />
          <PrivateRoute exact path="/ad-campaigns" component={Campaigns} />
          <PrivateRoute exact path="/ams-campaigns" component={ViewAmsCampaigns} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/billing" component={BillingDashboard} />
          <PrivateRoute exact path="/billing/change-plan" component={ChangePlan} />
          <PrivateRoute exact path="/billing/update-card" component={ChangeCard} />
          <PrivateRoute exact path="/billing/view-invoices" component={ViewInvoices} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register/:plan/:affiliate?" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword}/>
          <Route exact path="/pricing/:affiliate?" component={ViewPlans} />
        </MuiThemeProvider>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      if (!_.isEmpty(this.props.auth.user)) {
        // clear previous logout timeout
        if (this.state.logoutTimeoutPointer) {
          clearTimeout(this.state.logoutTimeoutPointer);
        }
        // set up our new logout timeout
        const that = this;
        const logoutTimeoutPointer = setTimeout(() => {
          that.props.dispatch(Actions.logout());
          window.location = '/';
        }, 4 * 60 * 60 * 1000);
        // save the new pointer to the state
        this.setState({ logoutTimeoutPointer });
      }
    }
  }
}

export default connect(
  state => ({
    auth: state.auth,
    message: state.message,
  }),
)(App);
