import {
  POST_BOOK_TAGS,
  POST_BOOK_TAGS_FAILURE,
  POST_BOOK_TAGS_SUCCESS,
  SET_BOOK_TAGS,
  UPDATE_BOOK_TAGS,
} from './types';
import { postBookTagsRequest, getBookTagsRequest } from '../api/books';
import { updateTags } from './tags';

function postBookTags() {
  return dispatch => dispatch({ type: POST_BOOK_TAGS });
}

function postBookTagsSuccess(payload) {
  return dispatch => dispatch({ type: POST_BOOK_TAGS_SUCCESS, payload });
}

function postBookTagsFailure(payload) {
  return dispatch => dispatch({ type: POST_BOOK_TAGS_FAILURE, payload });
}

function updateBookTags(payload) {
  return dispatch => dispatch({ type: UPDATE_BOOK_TAGS, payload });
}

function setBookTags(payload) {
  return dispatch => dispatch({ type: SET_BOOK_TAGS, payload });
}

export function requestPostBookTags(token, bookId, formData) {
  return (dispatch) => {
    dispatch(postBookTags());
    return postBookTagsRequest(token, bookId, formData)
      .then((json) => {
        dispatch(updateBookTags(json));
        const tags = json.tags || [];
        if (tags && tags.length) dispatch(updateTags(tags));
        return dispatch(postBookTagsSuccess(json));
      })
      .catch(e => e.json().then(eJson => dispatch(postBookTagsFailure(eJson))));
  };
}

export function requestGetBookTags(token) {
  return (dispatch) => {
    return getBookTagsRequest(token)
      .then(json => dispatch(setBookTags(json)));
  };
}
