import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ViewCustomRates from './ViewCustomRates';
import Profile from './Profile';
import BookManager from './BookManager';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';

class Settings extends Component {
  state = {
    tab: 0,
  };

  onTabChange = (e, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Settings" />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{ minHeight: 500 }}>
            <AppBar position="static" color="secondary">
              <Tabs value={this.state.tab} onChange={this.onTabChange} variant="fullWidth" indicatorColor="primary" classes={{
                root: classes.darkGreyBackground,
              }} >
                <Tab label="Profile" />
                <Tab label="Books" />
                <Tab label="Custom Rates" />
              </Tabs>
            </AppBar>
            { this.state.tab === 0 && <Profile /> }
            { this.state.tab === 1 && <BookManager /> }
            { this.state.tab === 2 && <ViewCustomRates /> }
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Settings);
