import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import TableFooter from "@material-ui/core/TableFooter";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import classNames from 'classnames';

const styles = {
  tableCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  tableCellHeader: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableCellTotal: {
    backgroundColor: '#00a4b0',
  },
};

const CustomTableCell = withStyles(theme => ({
  root: {
    color: theme.palette.common.darkGrey,
  },
  head: {
    backgroundColor: theme.palette.common.darkGrey,
    color: 'white',
  },
  body: {
    color: theme.palette.common.darkGrey,
    borderBottom: 'none',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'visible'
  },
  footer: {
    backgroundColor: theme.palette.common.darkGrey,
    color: 'white',
  }
}))(TableCell);

const CustomTableSortLabel = withStyles(theme => ({
  root: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
    '&:focus': {
      color: 'white',
    }
  },
  active: {
    color: 'white',
  }
}))(TableSortLabel);

class SortableTable extends Component {
  state = {
    order: 'desc',
    orderBy: '',
    page: 0,
    rowsPerPage: null,
    search: '',
  };

  componentDidMount() {
    const { orderBy, rowsPerPage } = this.props;
    const newState = {};
    if (orderBy && orderBy.length) newState['orderBy'] = orderBy;
    if (rowsPerPage) newState['rowsPerPage'] = rowsPerPage;
    if (Object.keys(newState).length) this.setState(newState);
  }

  desc = (a, b, orderBy) => {
    const orderByRaw = `${orderBy}Raw`,
      aVal = orderByRaw in a ? a[orderByRaw] : a[orderBy],
      bVal = orderByRaw in b ? b[orderByRaw] : b[orderBy];

    if (bVal < aVal) return -1;
    if (bVal > aVal) return 1;
    return 0;
  }

	stableSort = (array, cmp) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = cmp(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	}

	getData = () => {
    const { data } = this.props;
    const sortedData = this.stableSort(data, this.getSorting(this.state.order, this.state.orderBy));
    const { startDate, endDate } = this.props;
    const startMoment = startDate ? moment(startDate).startOf('day') : null;
    const endMoment = endDate ? moment(endDate).startOf('day') : null;
    if (!startDate && !endDate) return sortedData;

    return sortedData.filter(x => {
      const { date } = x;
      const dateMoment = moment(date).startOf('day');
      if (!startDate && !endDate) return true;
      else if (startDate && !endDate) return dateMoment.isSameOrAfter(startMoment);
      else if (!startDate && endDate) return dateMoment.isSameOrBefore(endMoment);
      else return dateMoment.isSameOrAfter(startMoment) && dateMoment.isSameOrBefore(endMoment);
    });
  };

	getSorting = (order, orderBy) => {
		return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
	}

	handleRequestSort = (property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  filteredTableData = () => {
    const tableData = this.getData();
    if (!tableData || !tableData.length) return tableData;
    const { search } = this.state;
    if (!search || !search.length) return tableData;
    return tableData.filter(x => {
      const searchableStr = Object.values(x).filter(y => (typeof y === 'string' || typeof y === 'number')).join(' ');
      return searchableStr.match(new RegExp(search, 'i'));
    });
  };

  renderSearch = () => {
    return (
      <TextField
        label="Search"
        type="text"
        value={this.state.search}
        onChange={this.handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear search"
                onClick={() => this.setState({ search: '' })}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    )
  };

  render() {
    let tableData = this.filteredTableData();
    const totalCount = tableData.length,
      rowsPerPageOptions = [5, 10, 25, ...(totalCount < 25 ? [] : (100 > totalCount ? [totalCount] : [100, totalCount]))];
    const { totals, search, theme, padding } = this.props;
    const { rowsPerPage, page } = this.state;

    if (rowsPerPage) {
      tableData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return (
      <>
        <Toolbar>
         <Typography variant="h6" id="tableTitle">
           {this.props.title}
          </Typography>
          { search && this.renderSearch() }
        </Toolbar>
        <Table padding={padding || 'dense'}>
          <TableHead>
            <TableRow>
              {this.props.columns.map((column, index) => (
                <CustomTableCell
									key={`column-${index}`}
									sortDirection={this.state.orderBy === column.key ? this.state.order : false}
                  className={column.className || ''}
                  classes={{
                    root: this.props.classes.tableCellHeader
                  }}
								>
										<Tooltip
											title="Sort"
											placement='bottom-start'
											enterDelay={300}
										>
											<CustomTableSortLabel
												active={this.state.orderBy === column.key}
												direction={this.state.order}
                        onClick={() => 'sortable' in column && !column.sortable ? undefined : this.handleRequestSort(column.key)}
											>
												{column.title}
											</CustomTableSortLabel>
										</Tooltip>
                  </CustomTableCell>
              ))}
            </TableRow>
            {
              totals &&
              <TableRow>
                {
                  this.props.columns.map((col, idx) => (
                    <CustomTableCell key={`footer-cell-${idx}`} classes={{
                      root: classNames(this.props.classes.tableCellHeader, this.props.classes.tableCellTotal)
                    }} className={col.className || ''}>
                      {
                        (totals && totals.hasOwnProperty(col.key)) ? totals[col.key] : ''
                      }
                    </CustomTableCell>
                  ))
                }
              </TableRow>
            }
          </TableHead>
          <TableBody>
						{tableData
							.map((r, index) => {
								return (
									<TableRow key={`row-${index}`} style={{ backgroundColor: (index % 2 ? theme.palette.common.lightGrey : 'white') }}>
										{this.props.columns.map((column, index) => (
											<CustomTableCell
												key={`row-cell-${r.id}-${index}`}
												component={(index === 0) ? 'th' : 'td'}
												scope="row"
                        className={column.className || ''}
											>
												{
												  column.key === 'edit' && r[column.key] ?
                            <Link className="button-link" to={r[column.key]}>
                              <Button variant="contained">Edit</Button>
                            </Link> :
                            (column.key === 'destroy' && r[column.key]
                              ? <Button variant="contained" onClick={() => r[column.key]()}>Delete</Button>
                              : r[column.key]
                            )
                        }
											</CustomTableCell>
										))}
									</TableRow>
								);
            })}
          </TableBody>
          {
            totals &&
            <TableFooter>
              <TableRow>
                {
                  this.props.columns.map((col, idx) => (
                    <CustomTableCell key={`footer-cell-${idx}`} classes={{
                      root: classNames(this.props.classes.tableCellHeader, this.props.classes.tableCellTotal)
                    }} className={col.className || ''}>
                      {
                        (totals && totals.hasOwnProperty(col.key)) ? totals[col.key] : ''
                      }
                    </CustomTableCell>
                  ))
                }
              </TableRow>
            </TableFooter>
          }
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={this.state.rowsPerPage || totalCount}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SortableTable);
