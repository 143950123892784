import {
  POST_AMS_EXPORT_FAILURE,
  POST_AMS_EXPORT_SUCCESS,
  POST_AMS_EXPORT,
  GET_AMS_CAMPAIGNS_FAILURE,
  GET_AMS_CAMPAIGNS_SUCCESS,
  GET_AMS_CAMPAIGNS,
  PATCH_AMS_CAMPAIGN,
  PATCH_AMS_CAMPAIGN_FAILURE,
  PATCH_AMS_CAMPAIGN_SUCCESS,
  GET_AMS_SPEND_BY_DATE,
  GET_AMS_SPEND_BY_DATE_FAILURE,
  GET_AMS_SPEND_BY_DATE_SUCCESS,
  GET_HISTORICAL_AMS_IMPORT,
  GET_HISTORICAL_AMS_IMPORT_FAILURE,
  GET_HISTORICAL_AMS_IMPORT_SUCCESS,
} from "../actions/types";
import {convertCurrency, findExchangeRateForDate} from "../utilities/exchange-rate-helpers";

function updateAmsSpendByBook(state, campaigns) {
  const currentSpend = state.amsSpendByBook || {},
    currCampaigns = {
      ...state.amsCampaigns || {},
      ...campaigns.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
    },
    bookIds = Object.values(currCampaigns).map(x => x.book_id).filter(x => !!x);

  const oldSpend = Object.keys(currentSpend).reduce((acc, bookId) => {
    if (!bookIds.includes(parseInt(bookId, 10))) return acc;
    return { ...acc, [bookId]: currentSpend[bookId] };
  }, {});
  const newSpend = campaigns.reduce((acc, x) => {
    const { book_id } = x,
      spendAry = x.ams_campaign_spend || [];
    if (!book_id) return acc;
    if (book_id in oldSpend) {
      oldSpend[book_id] = [...oldSpend[book_id], ...spendAry];
      return acc;
    }
    return {
      ...acc,
      [book_id]: [
        ...acc[book_id] || [],
        ...spendAry,
      ],
    };
  }, {});
  return {
    ...oldSpend,
    ...newSpend,
  };
}

function updateAmsSpendByDate({ amsSpend, exchangeRates, preferredCurrency }) {
  return Object.keys(amsSpend).reduce((acc, bookId) => {
    const spendAry = amsSpend[bookId];
    const convertedBookSpend = spendAry.map((x) => {
      const { currency, date } = x;
      let { cost: spend } = x;
      if (typeof spend === 'string') spend = parseFloat(spend);
      if (currency === preferredCurrency) return { ...x };
      const exRateData = findExchangeRateForDate(date, exchangeRates);
      if (!exRateData) return { ...x };
      try {
        const parsedRates = JSON.parse(exRateData.rates);
        const convertedSpend = convertCurrency(spend, currency, parsedRates, preferredCurrency);
        return { ...x, cost: convertedSpend, currency: preferredCurrency };
      } catch (e) {
        console.error(e);
        return { ...x };
      }
    });
    return { ...acc, [bookId]: convertedBookSpend };
  }, {});
}

export default function reducer(
  state = {
    isFetching: false,
    error: null,
    amsCampaigns: {},
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case POST_AMS_EXPORT:
      return {
        ...state,
        isFetching: true,
      };
    case POST_AMS_EXPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        amsCampaigns: {
          ...state.amsCampaigns || {},
          ...action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
        },
      };
    case POST_AMS_EXPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_AMS_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_AMS_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        amsCampaigns: {
          ...state.amsCampaigns || {},
          ...action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
        },
        amsSpendByBook: updateAmsSpendByBook(state, action.payload),
      };
    case GET_AMS_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case PATCH_AMS_CAMPAIGN:
      return {
        ...state,
        isFetching: true,
      };
    case PATCH_AMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        amsCampaigns: {
          ...state.amsCampaigns || {},
          [action.payload.id]: action.payload,
        },
        amsSpendByBook: updateAmsSpendByBook(state, [action.payload]),
      };
    case PATCH_AMS_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_AMS_SPEND_BY_DATE:
      return {
        ...state,
        isFetching: true,
      };
    case GET_AMS_SPEND_BY_DATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        amsSpendByDate: updateAmsSpendByDate(action.payload),
      };
    case GET_AMS_SPEND_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_HISTORICAL_AMS_IMPORT:
      return {
        ...state,
        isFetching: true,
      };
    case GET_HISTORICAL_AMS_IMPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_HISTORICAL_AMS_IMPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
