import{
    NEW_MESSAGE,
    NEW_ERROR,
    CLEAR_MESSAGE,
} from '../actions/types';

export default function reducer(state = {
    message: '',
    open: false,
    bodyStyle: null,
    autoHideDuration: null,
    actions: [],
    showLoading: false,
}, action = {type: ''}) {
    switch (action.type) {
        case NEW_MESSAGE:
            return {
                ...state,
                message: action.message,
                autoHideDuration: action.autoHideDuration,
                actions: action.actions,
                backgroundColor: '',
                showLoading: action.showLoading,
            };
        case NEW_ERROR:
            return {
                ...state,
                message: action.message,
                backgroundColor: 'rgb(244, 67, 54)',
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: '',
                open: false,
                autoHideDuration: null,
                actions: [],
            };
        default:
            return state;
    }
}
