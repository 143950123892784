import {
  POST_TAG,
  POST_TAG_SUCCESS,
  POST_TAG_FAILURE,
  SET_TAGS,
  UPDATE_TAGS,
} from './types';
import {
  postTagRequest,
  getTagsRequest,
} from '../api/tags';

function postTag() {
  return dispatch => dispatch({ type: POST_TAG });
}

function postTagSuccess(payload) {
  return dispatch => dispatch({ type: POST_TAG_SUCCESS, payload });
}

function postTagFailure(payload) {
  return dispatch => dispatch({ type: POST_TAG_FAILURE, payload });
}

function setTags(payload) {
  return dispatch => dispatch({ type: SET_TAGS, payload });
}

export function updateTags(payload) {
  return dispatch => dispatch({ type: UPDATE_TAGS, payload });
}

export function requestGetTags(token) {
  return (dispatch) => {
    return getTagsRequest(token)
      .then(json => dispatch(setTags(json)));
  };
}

export function requestPostTag(tag, token) {
  return (dispatch) => {
    dispatch(postTag());
    return postTagRequest({ text: tag }, token)
      .then((json) => {
        dispatch(updateTags(json));
        return dispatch(postTagSuccess(json));
      })
      .catch(e => e.json().then(eJson => dispatch(postTagFailure(eJson))));
  };
}
