import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const getAllExchangeRatesRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/exchange-rates`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);
