import { getAllFacebookCampaignsRequest } from '../api/facebook';
import {selectExchangeRates, selectPreferredCurrency} from '../utilities/selectors';
import {
  GET_ALL_FACEBOOK_CAMPAIGNS,
  GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS,
  GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE,
} from './types';
import { maybeUpdateExchangeRates } from './get-all-exchange-rates';

function getAllFacebookCampaigns() {
  return {
    type: GET_ALL_FACEBOOK_CAMPAIGNS,
  };
}

function getAllFacebookCampaignsSuccess(json) {
  return {
    type: GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS,
    payload: json,
  };
}

function getAllFacebookCampaignsFailure(error) {
  return {
    type: GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE,
    payload: error,
  };
}

export default function requestGetAllFacebookCampaigns(token, startDate, endDate) {
  return (dispatch, getState) => dispatch(maybeUpdateExchangeRates(token))
    .then(() => {
      const state = getState();
      const preferredCurrency = selectPreferredCurrency(state);
      const exchangeRates = selectExchangeRates(state);
      dispatch(getAllFacebookCampaigns());
      return getAllFacebookCampaignsRequest(token, startDate, endDate)
        .then((fbCampaigns) => {
          const data = { fbCampaigns, preferredCurrency, exchangeRates };
          return dispatch(getAllFacebookCampaignsSuccess(data));
        })
        .catch(error => dispatch(getAllFacebookCampaignsFailure(error)));
    });
}
