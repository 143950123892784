import { postCancelSubscriptionRequest } from '../api/braintree';

import {
  POST_CANCEL_SUBSCRIPTION,
  POST_CANCEL_SUBSCRIPTION_SUCCESS,
  POST_CANCEL_SUBSCRIPTION_FAILURE,
}
 from '../actions/types'

function postCancelSubscription() {
  return {
    type: POST_CANCEL_SUBSCRIPTION,
  };
}

function postCancelSubscriptionSuccess(json) {
  return {
    type: POST_CANCEL_SUBSCRIPTION_SUCCESS,
    payload: json,
  };
}

function postCancelSubscriptionFailure(error) {
  return {
    type: POST_CANCEL_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export default function requestPostCancelSubscription(formData, apiToken){
  return (dispatch) => {
    dispatch(postCancelSubscription());
    return postCancelSubscriptionRequest(formData, apiToken)
    .then((json) => dispatch(postCancelSubscriptionSuccess(json)))
    .catch(error => error.json().then(errors => dispatch(postCancelSubscriptionFailure(errors))));
  };
}



