import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default function (ComposedComponent) {
  class Authentication extends Component {
    assertAdmin() {
      const { user } = this.props;
      return user && user.role === 'admin';
    }

    render() {
      const { location } = this.props;
      return this.assertAdmin()
        ? <ComposedComponent {...this.props} />
        : <Redirect to={{ pathname: '/', state: { from: location }}} />;
    }
  }

  const mapStateToProps = ({ auth }) => {
    return {
      user: auth.user,
    };
  };

  return connect(mapStateToProps)(Authentication);
}
