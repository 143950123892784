import { deleteExpenseRequest } from '../api/expenses';

import {
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAILURE
} from '../actions/types';
import requestGetProfitAndLoss from "./get-profit-and-loss";

function deleteExpense() {
  return {
    type: DELETE_EXPENSE
  };
}

function deleteExpenseSuccess(json) {
  return {
    type: DELETE_EXPENSE_SUCCESS,
    payload: json
  };
}

function deleteExpenseFailure(error) {
  return {
    type: DELETE_EXPENSE_FAILURE,
    payload: error
  };
}

export default function requestDeleteExpense(expense_id, token) {
  return dispatch => {
    dispatch(deleteExpense());
    return deleteExpenseRequest(expense_id, token)
      .then(json => dispatch(deleteExpenseSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(deleteExpenseFailure(errors))));
  };
}
