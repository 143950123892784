import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import DropIn from 'braintree-web-drop-in-react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'cookies-js';
import Paper from '@material-ui/core/Paper';
import Actions from '../../actions';
import styles from '../../styles';
import {
  POST_UPDATE_CARD_SUCCESS,
  POST_UPDATE_CARD_FAILURE,
} from '../../actions/types';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import { withStyles } from '@material-ui/core/styles';

class ChangeCard extends Component {

  instance;

  componentDidMount() {
    if(_.isEmpty(this.props.clientToken)) {
      this.props.dispatch(Actions.requestGetBraintreeToken());
    }
  }

  changeCard = (event) => {
    event.preventDefault();
    this.instance.requestPaymentMethod().then(({ nonce }) => {
      this.props.dispatch(Actions.requestPostUpdateCard(nonce, Cookies.get('token')))
        .then((result) => {
          if (result.type === POST_UPDATE_CARD_SUCCESS) {
            this.props.dispatch(Actions.newMessage('Card Updated!'));
            this.props.history.push('/billing');
          } else if (result.type === POST_UPDATE_CARD_FAILURE) {
            this.props.dispatch(Actions.newError("Something went wrong, please try again."));
          }
        });
    });
  }



  render() {
    const { classes } = this.props;

    if (this.props.isFetching || _.isEmpty(this.props.clientToken)) {
      return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
    }
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text={'change card'} />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={styles.paper}>
            <DropIn
              options={{ authorization: this.props.clientToken, paypal: { flow: 'vault'} }}
              onInstance={instance => (this.instance = instance)}
            />
            <div className="login-button">
              {this.props.isFetching ? <CircularProgress /> : <Button variant="contained" className="login-submit" color="primary" onClick={this.changeCard} type="submit">Register</Button>}
            </div>
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default connect(
  state => ({
    clientToken: state.braintree.clientToken,
    isFetching: state.braintree.isFetching,
  }),
)(withStyles(styles, { withTheme: true })(ChangeCard));
