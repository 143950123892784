import {
  POST_FB_CAMPAIGN_EXPORT,
  POST_FB_CAMPAIGN_EXPORT_FAILURE,
  POST_FB_CAMPAIGN_EXPORT_SUCCESS,
} from "./types";
import { postFbCampaignExportRequest } from "../api/facebook";

function postExport() {
  return { type: POST_FB_CAMPAIGN_EXPORT };
}

function postExportSuccess() {
  return { type: POST_FB_CAMPAIGN_EXPORT_SUCCESS };
}

function postExportFailure(payload) {
  return { type: POST_FB_CAMPAIGN_EXPORT_FAILURE, payload };
}

export default function requestPostFbCampaignExport(formData, token) {
  return (dispatch) => {
    dispatch(postExport());
    return postFbCampaignExportRequest(formData, token)
      .then(() => dispatch(postExportSuccess()))
      .catch(e => dispatch(postExportFailure(e)));
  };
}
