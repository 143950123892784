import PropTypes from 'prop-types';

export const UserPropTypes = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  role: PropTypes.string,
  api_token: PropTypes.string,
  rate_estimation_method: PropTypes.string,
  preferred_currency: PropTypes.string,
});

export const AuthStatePropTypes = PropTypes.shape({
  isFetching: PropTypes.bool,
  user: UserPropTypes,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      password: PropTypes.string,
    }),
  ]),
});
