import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

class SectionWrapper extends PureComponent {
  render() {
    const { classes, className, style, header } = this.props;
    return (
      <div className={classNames(classes.viewContainer, className)} style={style || null}>
        <div className={classes.contentContainer}>
          {
            !!header && <Toolbar><Typography variant="h6">{header}</Typography></Toolbar>
          }
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionWrapper);
