import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import Actions from '../../actions';
import styles from '../../styles';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import { withStyles } from '@material-ui/core/styles';

class ConnectFacebook extends Component {
  componentDidMount() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '409881879453192',
        cookie     : true,  // enable cookies to allow the server to access
                          // the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v3.1' // use version 3.2
      });

      // Now that we've initialized the JavaScript SDK, we call
      // FB.getLoginStatus().  This function gets the state of the
      // person visiting this page and can return one of three states to
      // the callback you provide.  They can be:
      //
      // 1. Logged into your app ('connected')
      // 2. Logged into Facebook, but not your app ('not_authorized')
      // 3. Not logged into Facebook and can't tell if they are logged into
      //    your app or not.
      //
      // These three cases are handled in the callback function.
      window.FB.getLoginStatus(function(response) {
        this.statusChangeCallback(response);
      }.bind(this));
    }.bind(this);

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  testAPI() {
    window.FB.api('/me', function(response) {
    document.getElementById('status').innerHTML =
      'Thanks for logging in !';
    });
  }

  // This is called with the results from from FB.getLoginStatus().
  statusChangeCallback(response) {
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      this.testAPI();
      // TODO: this is firing on every page load of this page do we need that??
      this.props.dispatch(Actions.requestPostFacebookToken(Cookies.get('token'), response.authResponse.accessToken));
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      document.getElementById('status').innerHTML = 'Please log ' +
        'into this app.';
      window.FB.login(function(response) {
        if (response.status === 'connected') {
          // Logged into your app and Facebook.
          this.testAPI();
          // TODO: this is firing on every page load of this page do we need that??
          this.props.dispatch(Actions.requestPostFacebookToken(Cookies.get('token'), response.authResponse.accessToken));
        } else {
            console.log('User canceled login or did not fully authorize');
        }
      }, { scope: 'business_management, ads_management, ads_read'});
    }  else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      document.getElementById('status').innerHTML = 'Please log ' +
      'into Facebook.';
    }
  }
  // This function is called when someone finishes with the Login
  // Button.  See the onlogin handler attached to it in the sample
  // code below.
  checkLoginState() {
    window.FB.getLoginStatus(function(response) {
      this.statusChangeCallback(response);
    }.bind(this));
  }

  logUserOutOfFB() {
    window.FB.logout();
  }

  handleClick() {
     window.FB.login(this.checkLoginState());
  }

render() {
    const { classes } = this.props;
    const user = this.props.user || {};
    const { fb_token: fbToken } = user;

    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Connect to Facebook" />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={styles.paper}>
            <div id="status"></div>
            <br/>
            {
              !!fbToken &&
              <Link className="button-link" to="/fb-campaigns">
                <Button variant="contained" color="primary">Import Facebook Campaigns</Button>
              </Link>
            }
            <Button variant="contained" onClick={this.handleClick.bind(this)}>
              Check Login status
            </Button>
            <br/>
            <br/>
            <Button variant="contained" onClick={this.logUserOutOfFB}>
              Log out
            </Button>
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.facebook.user,
}))(withStyles(styles, { withTheme: true })(ConnectFacebook));
