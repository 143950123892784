import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const postCustomRateRequest = (formData, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/custom-rates`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const patchCustomRateRequest = (formData, id, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/custom-rates/${id}`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const getAllCustomRatesRequest = (token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/custom-rates`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const deleteCustomRateRequest = (id, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/custom-rate/${id}`,
  {
    method: 'DELETE',
    headers: {
      'api-token': token,
    },
  },
).then(handleResponse);
