import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const getBraintreeTokenRequest = apiToken => fetch(
  `${process.env.REACT_APP_API_ROOT}/braintree/token`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
  },
).then(handleResponse);

export const postCreateSubscriptionRequest = (formData, apiToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription`,
  {
    method: 'POST',
    headers: {
      'api-token': apiToken,
    },
    body: formData,
  },
).then(handleResponse);

export const postCancelSubscriptionRequest = (formData, apiToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription/cancel`,
  {
    method: 'POST',
    headers: {
      'api-token': apiToken,
    },
    body: formData,
  },
).then(handleResponse);

export const postChangePlanRequest = (plan, apiToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription/change-plan`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
    body: JSON.stringify({plan: plan}),
  },
).then(handleResponse);

export const postUpdateCardRequest = (token, apiToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription/update-card`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
    body: JSON.stringify({token: token}),
  },
).then(handleResponse);

export const getAllPlansRequest = () => fetch(
  `${process.env.REACT_APP_API_ROOT}/plans`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then(handleResponse);

export const getSubscriptionRequest = (apiToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
  },
).then(handleResponse);

export const getAllInvoicesRequest = apiToken => fetch(
  `${process.env.REACT_APP_API_ROOT}/invoices`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
  },
).then(handleResponse);

export const getInvoicePdfRequest = (invoiceId, apiToken) => fetch(
  // does this need to be returned??
  `${process.env.REACT_APP_API_ROOT}/invoice/${invoiceId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': apiToken,
    },
  },
).then(handleResponse);
