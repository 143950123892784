import { registerRequest } from '../api/users';

import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './types';

function registerUser() {
  return {
    type: REGISTER,
  };
}

function registerUserSuccess(json) {
  return {
    type: REGISTER_SUCCESS,
    payload: {
      user: json,
    },
  };
}


function registerUserFailure(error) {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
}
export default function register(formData) {
  return (dispatch) => {
    dispatch(registerUser());
    return registerRequest(formData)
      .then(json => dispatch(registerUserSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(registerUserFailure(errors))));
  };
}
