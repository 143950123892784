export function selectUser(state) {
  const { auth } = state || {},
    { user } = auth || {};
  return user;
}

export function selectPreferredCurrency(state) {
  const user = selectUser(state),
    { preferred_currency: prefCurrency } = user || {};
  return prefCurrency;
}

export function selectExchangeRates(state) {
  const { exchangeRates: exRatesState } = state || {},
    { exchangeRates } = exRatesState || {};
  return exchangeRates;
}
