import { mergeState } from '11online-redux-helpers';
import {
  GET_RATE,
  GET_RATE_SUCCESS,
  GET_RATE_FAILURE,
  POST_RATE,
  POST_RATE_SUCCESS,
  POST_RATE_FAILURE,
  GET_ALL_RATES,
  GET_ALL_RATES_SUCCESS,
  GET_ALL_RATES_FAILURE,
  GET_ALL_CUSTOM_RATES,
  GET_ALL_CUSTOM_RATES_SUCCESS,
  GET_ALL_CUSTOM_RATES_ERROR,
  POST_CUSTOM_RATE,
  POST_CUSTOM_RATE_FAILURE,
  POST_CUSTOM_RATE_SUCCESS,
  PATCH_CUSTOM_RATE,
  PATCH_CUSTOM_RATE_SUCCESS,
  PATCH_CUSTOM_RATE_FAILURE,
  DELETE_CUSTOM_RATE,
  DELETE_CUSTOM_RATE_FAILURE,
  DELETE_CUSTOM_RATE_SUCCESS,
  GET_RATE_DROPS,
  GET_RATE_DROPS_FAILURE,
  GET_RATE_DROPS_SUCCESS,
  PATCH_RATE,
  PATCH_RATE_FAILURE,
  PATCH_RATE_SUCCESS,
  DELETE_RATE,
  DELETE_RATE_FAILURE,
  DELETE_RATE_SUCCESS,
} from '../actions/types';
import {sortObjAryByDate} from "../utilities/array-helpers";

function mapCustomRates(customRates) {
  return customRates.reduce((acc, x) => {
    const { user_id: userId, id } = x;
    return {
      ...acc,
      [userId]: {
        ...(acc[userId] || {}),
        [id]: x,
      },
    };
  }, {});
}

function mapRatesByMarketplace(rates) {
  const grouped = rates.reduce((acc, x) => {
    const { marketplace } = x;
    return { ...acc, [marketplace]: [...(acc[marketplace] || []), x] };
  }, {});
  Object.keys(grouped).forEach(marketplace => {
    const mRates = grouped[marketplace];
    grouped[marketplace] = sortObjAryByDate(mRates, 'start_date');
  });
  return grouped;
}

export default function reducer(
  state = {
    isFetching: false,
    user: {},
    rates: {},
    defaultRate: {},
    customRates: {},
    rateDrops: null,
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case GET_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        rates: mergeState(state.rates, action.payload),
      };
    case GET_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case POST_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        rates: mergeState(state.rates, action.payload),
      };
    case POST_RATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_ALL_RATES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_RATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        rates: mergeState(state.rates, action.payload),
        ratesByMarketplace: mapRatesByMarketplace(action.payload),
      };
    case GET_ALL_RATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ALL_CUSTOM_RATES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_CUSTOM_RATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        customRates: mapCustomRates(action.payload),
      };
    case GET_ALL_CUSTOM_RATES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_CUSTOM_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case POST_CUSTOM_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customRates: {
          ...state.customRates,
          [action.payload.user_id]: {
            ...(state.customRates[action.payload.user_id] || {}),
            [action.payload.id]: action.payload,
          },
        },
      };
    case POST_CUSTOM_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case PATCH_CUSTOM_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case PATCH_CUSTOM_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customRates: {
          ...state.customRates,
          [action.payload.user_id]: {
            ...(state.customRates[action.payload.user_id] || {}),
            [action.payload.id]: action.payload,
          },
        },
      };
    case PATCH_CUSTOM_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_CUSTOM_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_CUSTOM_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customRates: Object.keys(state.customRates).reduce((acc, userId) => {
          const tmp = state.customRates[userId];
          if (!(action.payload.id in tmp)) return { ...acc, [userId]: tmp };
          const { [action.payload.id]: remove, ...rest } = tmp;
          return { ...acc, [userId]: rest };
        }, {}),
      };
    case DELETE_CUSTOM_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_RATE_DROPS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_RATE_DROPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rateDrops: action.payload
          .reduce((acc, x) => ({ ...acc, [x.marketplace]: x.rate_drop }), {}),
      };
    case GET_RATE_DROPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case PATCH_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case PATCH_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rates: {
          ...(state.rates || {}),
          [action.payload.id]: action.payload,
        },
      };
    case PATCH_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_RATE:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rates: Object.keys(state.rates || {}).reduce((acc, rateId) => {
          if (rateId === action.payload.id) return acc;
          return { ...acc, [rateId]: state.rates[rateId] };
        }, {}),
      };
    case DELETE_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
