import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Paper from '@material-ui/core/Paper';
import {
  DELETE_EXPENSE_FAILURE,
  GET_ALL_RECURRING_EXPENSES_FAILURE,
} from '../../actions/types';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SectionWrapper from '../blocks/SectionWrapper';
import PageHeader from '../blocks/PageHeader';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import {WithLoading} from "../blocks/WithLoading";
import SortableTable from '../blocks/SortableTable';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import ButtonWithConfirm from "../blocks/ButtonWithConfirm";

class RecurringExpenses extends Component {
  componentDidMount() {
    this.fetchRecurringExpenses();
  }

  fetchRecurringExpenses = () => {
    const { dispatch } = this.props,
      token = Cookies.get('token');

    return dispatch(Actions.requestGetAllRecurringExpenses(token)).then(res => {
      if (res.type === GET_ALL_RECURRING_EXPENSES_FAILURE) {
        dispatch(Actions.newError(res.payload.message));
      }
    })
  };

  tableData = () => Object.values(this.props.recurringExpenses || {}).map(x => {
    const { title, date, book, categories, repeat_type, id } = x;
    return {
      title,
      date,
      book: book.title,
      category: (categories || []).map(x => x.title).join(', '),
      repeat_type,
      actions: (
        <div>
          <IconButton color="primary" component={Link}  to={`/expense/${id}`}>
            <Edit />
          </IconButton>
          <ButtonWithConfirm
            buttonComponent={IconButton}
            buttonProps={{
              style: { color: this.props.theme.palette.error.main },
              component: Button,
            }}
            buttonContent={<Delete/>}
            onConfirm={() => this.deleteExpense(id)}
          />
        </div>
      )
    };
  });

  deleteExpense = (id) => {
    this.props.dispatch(Actions.requestDeleteExpense(id, Cookies.get('token')))
      .then(result => {
        if (result.type !== DELETE_EXPENSE_FAILURE){
          this.props.dispatch(Actions.newMessage('You have successfully deleted this expense.'));
        } else {
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  };

  render() {
    const { classes, isFetching } = this.props;
    return (
      <WithLoading loading={isFetching}>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Recurring Expenses" />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{
            ...styles.paper,
            position: 'relative',
          }}>
            <Button component={Link} variant="outlined" size="small" color="secondary" to="/expenses">
              <ArrowBack />
              Back
            </Button>
            <Button style={{float: 'right'}} variant="contained" component={Link} color="primary" to="/expense">
              Add Expense
            </Button>
            <SortableTable
              columns={[
                { key: 'title', title: 'Title' },
                { key: 'date', title: 'Date' },
                { key: 'book', title: 'Book' },
                { key: 'category', title: 'Category' },
                { key: 'repeat_type', title: 'Repeat Type' },
                { key: 'actions', title: '' },
              ]}
              data={this.tableData()}
              key="recurringExpensesTable"
              rowsPerPage={10}
              search={true}
              padding="dense"
              orderBy="title"
            />
          </Paper>
        </SectionWrapper>
      </WithLoading>
    )
  }
}

export default connect(
  state => ({
    recurringExpenses: state.expenses.recurringExpenses,
    isFetching: state.expenses.isFetching,
  }),
)(withStyles(styles, { withTheme: true })(RecurringExpenses));
