import React, { PureComponent } from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';

export default class AreaChart extends PureComponent {
  getData = () => {
    const { startDate, endDate, data } = this.props;
    const startMoment = startDate ? moment(startDate).startOf('day') : null;
    const endMoment = endDate ? moment(endDate).startOf('day') : null;
    return data.filter(row => {
      if(row[0] === 'Date') {
        return true;
      }
      const dateMoment = moment(row[0], ['M/D/YY', 'MM/D/YY', 'M/DD/YY', 'MM/DD/YY']).startOf('day');

      if (!startDate && !endDate) return true;
      else if (startDate && !endDate) return dateMoment.isSameOrAfter(startMoment);
      else if (!startDate && endDate) return dateMoment.isSameOrBefore(endMoment);
      else return dateMoment.isSameOrAfter(startMoment) && dateMoment.isSameOrBefore(endMoment);
    }).sort((a, b) => {
      return new Date(a[0]) - new Date(b[0]);
    })
  };

  render() {
    const { theme, colors, customTooltip } = this.props;
    const chartData = this.getData();

    return (
      <div>
        {chartData.length > 1
          ?
            <Chart
              width="100%"
              height="400px"
              chartType="AreaChart"
              loader={<div>Loading Chart</div>}
              data={chartData}
              options={{
                vAxis: { minValue: 0, title: this.props.yLabel || '' },
                // For the legend to fit, we make the chart area smaller
                chartArea: { width: '75%', height: '75%' },
                colors: colors || [theme.palette.primary.main, theme.palette.error.main],
                backgroundColor: theme.palette.common.lightGrey,
                ...(customTooltip ? { tooltip: { isHtml: true }, focusTarget: 'category' } : {}),
              }}
                      // For tests
              rootProps={{ 'data-testid': '1' }}
            />
          :
            <div style={{height: 400, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
              <p>No data</p>
            </div>
        }
      </div>
    );
  }
}
