import { mergeState } from '11online-redux-helpers';

import {
  GET_BRAINTREE_TOKEN,
  GET_BRAINTREE_TOKEN_SUCCESS,
  GET_BRAINTREE_TOKEN_FAILURE,
  POST_CREATE_SUBSCRIPTION,
  POST_CREATE_SUBSCRIPTION_SUCCESS,
  POST_CREATE_SUBSCRIPTION_FAILURE,
  POST_CANCEL_SUBSCRIPTION,
  POST_CANCEL_SUBSCRIPTION_SUCCESS,
  POST_CANCEL_SUBSCRIPTION_FAILURE,
  POST_CHANGE_PLAN,
  POST_CHANGE_PLAN_SUCCESS,
  POST_CHANGE_PLAN_FAILURE,
  POST_UPDATE_CARD,
  POST_UPDATE_CARD_SUCCESS,
  POST_UPDATE_CARD_FAILURE,
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  GET_ALL_PLANS_FAILURE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_FAILURE,
  GET_INVOICE_PDF,
  GET_INVOICE_PDF_SUCCESS,
  GET_INVOICE_PDF_FAILURE,
} from '../actions/types';

export default function reducer(
  //TODO: set the state correctly, this is just copy pasta
  state = {
    isFetching: false,
    clientToken: {},
    plans: {},
    invoices: {},
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_BRAINTREE_TOKEN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BRAINTREE_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        clientToken: action.payload.token,
      };
    case GET_BRAINTREE_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_CREATE_SUBSCRIPTION:
      return {
        ...state,
        isFetching: true,
      };
    case POST_CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case POST_CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        isFetching: true,
      };
    case POST_CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case POST_CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_CHANGE_PLAN:
      return {
        ...state,
        isFetching: true,
      };
    case POST_CHANGE_PLAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case POST_CHANGE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_UPDATE_CARD:
      return {
        ...state,
        isFetching: true,
      };
    case POST_UPDATE_CARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case POST_UPDATE_CARD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_ALL_PLANS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        plans: mergeState(state.plans, action.payload),
      };
    case GET_ALL_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        subscription: action.payload,
        error: [],
      };
    case GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_ALL_INVOICES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        invoices: mergeState(state.invoices, action.payload),
      };
    case GET_ALL_INVOICES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_INVOICE_PDF:
      return {
        ...state,
        isFetching: true,
      };
    case GET_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case GET_INVOICE_PDF_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}
