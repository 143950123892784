import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const getRateRequest = (token, id) => fetch(
  `${process.env.REACT_APP_API_ROOT}/rates/${id}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const postRateRequest = (formData, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/rates`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const patchRateRequest = (rateId, formData, token) => {
  formData.append('_method', 'PATCH');
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/rate/${rateId}`,
    {
      method: 'POST',
      headers: {
        'api-token': token,
      },
      body: formData,
    },
  ).then(handleResponse);
};

export const deleteRateRequest = (id, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/rate/${id}`,
  {
    method: 'DELETE',
    headers: {
      'api-token': token,
    },
  },
).then(handleResponse);

export const getAllRatesRequest = (token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/rates`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const getRateDropsRequest = (token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/rate-drops`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);
