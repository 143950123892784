import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import {
  POST_RATE_SUCCESS,
  PATCH_RATE_SUCCESS, DELETE_RATE_SUCCESS,
} from '../../actions/types';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SortableTable from "../blocks/SortableTable";
import moment from 'moment';
import 'moment-timezone';
import RateForm from "../blocks/RateForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationDialog from '../blocks/ConfirmationDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RequireAdmin from '../../utilities/RequireAdmin';

class RatesManager extends Component {
  state = {
    showForm: false,
    showConfirmationDialog: false,
    confirmDeleteRateId: null,
    rate: null,
  };

  componentDidMount() {
    const { rates, dispatch } = this.props;
    if (rates && Object.keys(rates).length) return;
    dispatch(Actions.requestGetAllRates(Cookies.get('token')))
  }

  onEdit = (rate) => {
    this.setState({ rate, showForm: true });
  };

  onDelete = rate => {
    const { id } = rate;
    this.setState({ showConfirmationDialog: true, confirmDeleteRateId: id });
  };

  deleteRate = rateId => {
    const token = Cookies.get('token');
    const { dispatch } = this.props;
    dispatch(Actions.requestDeleteRate(rateId, token))
      .then(res => {
        const { type } = res;
        if (type === DELETE_RATE_SUCCESS) dispatch(Actions.newMessage('Rate deleted!'));
        else dispatch(Actions.newError('Unable to delete rate.'));
      })
  };

  onAddNew = () => {
    this.setState({ showForm: true });
  };

  onSubmit = (formData, rateId) => {
    const token = Cookies.get('token');
    // Remove the editing user's timezone from timestamps
    const startDate = formData.get('start_date'),
        endDate = formData.get('end_date');
    if (startDate) formData.set('start_date', moment.tz(startDate, 'UTC').format('YYYY-MM-DD'));
    if (endDate) formData.set('end_date', moment.tz(endDate, 'UTC').format('YYYY-MM-DD'));
    const { dispatch } = this.props;
    const { rate } = this.state;
    if (rate) {
      dispatch(Actions.requestPatchRate(rateId, formData, token))
        .then(res => {
          const { type, payload } = res;
          if (type === PATCH_RATE_SUCCESS) {
            dispatch(Actions.newMessage('Rate updated!'));
            this.setState({ rate: null, showForm: false });
          }
          else {
            if (payload && typeof payload === 'object') {
              const str = [].concat(...Object.values(payload)).join(' ');
              dispatch(Actions.newError(str));
            }
          }
        })
    }
    else {
      dispatch(Actions.requestPostRate(formData, token))
        .then(res => {
          const { type, payload } = res;
          if (type === POST_RATE_SUCCESS) {
            dispatch(Actions.newMessage('Rate created!'));
            this.setState({ rate: null, showForm: false });
          }
          else {
            if (payload && typeof payload === 'object') {
              const str = [].concat(...Object.values(payload)).join(' ');
              dispatch(Actions.newError(str));
            }
          }
        })
    }
  };

  onFormBack = () => {
    this.setState({ showForm: false, rate: null });
  };

  rateTableData() {
    const { rates, theme } = this.props;
    if (!rates || !Object.keys(rates).length) return [];
    return Object.values(rates).map(x => ({
      id: x.id,
      rate: x.rate,
      marketplace: x.marketplace,
      start: x.start_date ? moment(x.start_date).format('YYYY-MM-DD') : '',
      end: x.end_date ? moment(x.end_date).format('YYYY-MM-DD') : '',
      actions: (
        <div>
          <IconButton color="primary" onClick={() => this.onEdit(x)}>
            <EditIcon />
          </IconButton>
          <IconButton style={{ color: theme.palette.error.main }} onClick={() => this.onDelete(x)}>
            <DeleteIcon />
          </IconButton>
        </div>
      )
    }));
  }

  render() {
    const tableColumns = [
      { key: 'rate', title: 'Rate' },
      { key: 'marketplace', title: 'Marketplace' },
      { key: 'start', title: 'Start' },
      { key: 'end', title: 'End' },
      { key: 'actions', title: 'Actions' },
    ];

    const { isFetching, error } = this.props;

    return (
      <div style={styles.viewContainer}>
        {
          this.state.showForm ?
            <RateForm rate={this.state.rate} onBack={this.onFormBack} onSubmit={this.onSubmit} showDates={true} error={error} /> :
            <div>
              <Button variant="contained" color="primary" onClick={this.onAddNew}>Create Rate</Button>
              <SortableTable padding="dense" search={true} columns={tableColumns} data={this.rateTableData()} orderBy="start" rowsPerPage={25} />
            </div>
        }
        {
          !!isFetching &&
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(52, 52, 52, 0.6)'
          }}
          >
            <CircularProgress />
          </div>
        }
        <ConfirmationDialog
          open={this.state.showConfirmationDialog}
          value={this.state.confirmDeleteRateId}
          onClose={rateId => {
            if (rateId) this.deleteRate(rateId);
            this.setState({ showConfirmationDialog: false });
          }}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    user: state.auth.user,
    rates: state.rates.rates,
    error: state.rates.error,
    isFetching: state.rates.isFetching,
  }),
)(withStyles(styles, { withTheme: true })(RequireAdmin(RatesManager)));
