import { mergeState } from '11online-redux-helpers';
import {
  POST_FACEBOOK_TOKEN,
  POST_FACEBOOK_TOKEN_SUCCESS,
  POST_FACEBOOK_TOKEN_FAILURE,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE,
  GET_ALL_FACEBOOK_CAMPAIGNS,
  GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS,
  GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE,
  PATCH_FB_CAMPAIGN,
  PATCH_FB_CAMPAIGN_SUCCESS,
  PATCH_FB_CAMPAIGN_FAILURE,
  GET_FB_CAMPAIGN,
  GET_FB_CAMPAIGN_SUCCESS,
  GET_FB_CAMPAIGN_FAILURE,
  POST_FB_CAMPAIGN_EXPORT,
  POST_FB_CAMPAIGN_EXPORT_FAILURE,
  POST_FB_CAMPAIGN_EXPORT_SUCCESS,
  GET_FB_IMPORT_JOBS,
  GET_FB_IMPORT_JOBS_FAILURE,
  GET_FB_IMPORT_JOBS_SUCCESS,
  GET_FB_CAMPAIGN_SPEND,
  GET_FB_CAMPAIGN_SPEND_FAILURE,
  GET_FB_CAMPAIGN_SPEND_SUCCESS,
  DELETE_FACEBOOK_TOKEN,
  DELETE_FACEBOOK_TOKEN_FAILURE,
  DELETE_FACEBOOK_TOKEN_SUCCESS,
} from '../actions/types';
import { currencies } from '../utilities/constants';
import {convertCurrency, findExchangeRateForDate} from "../utilities/exchange-rate-helpers";

function updateFBSpendByBook({ fbCampaigns }) {
  return fbCampaigns.reduce((acc, x) => {
    const { book_id: bookId, fb_campaign_spend: fbSpend, name: fbCampaignName, fb_campaign_id: fbCampaignId } = x;
    const book = x.book || {};
    const { title: bookTitle } = book;
    if (!bookId) return acc;
    if (!(bookId in acc)) acc[bookId] = {};
    fbSpend.forEach((spend) => {
      const s = { ...spend, fbCampaignName, fbCampaignId, bookTitle, bookId };
      const { date } = spend;
      if (!date || !date.length) return;
      const parsedDate = date.split(' ')[0];
      if (parsedDate in acc[bookId]) acc[bookId][parsedDate].push(s);
      else acc[bookId][parsedDate] = [s];
    });
    return acc;
  }, {});
}

function updateFBSpendByDate({ fbCampaigns }) {
  return fbCampaigns.reduce((acc, x) => {
    const { fb_campaign_spend = [], name: fbCampaignName, fb_campaign_id: fbCampaignId } = x;
    const book = x.book || {};
    const { id: bookId, title: bookTitle } = book;
    fb_campaign_spend.forEach((spend) => {
      const s = { ...spend, bookId, bookTitle, fbCampaignName, fbCampaignId };
      const { date } = spend;
      if (!date || !date.length) return;
      const parsedDate = date.split(' ')[0];
      if (parsedDate in acc) acc[parsedDate].push(s);
      else acc[parsedDate] = [s];
    });
    return acc;
  }, {});
}

function updateFbSpend({ fbCampaigns, preferredCurrency = currencies.USD, exchangeRates }) {
  fbCampaigns.forEach((fbCampaign) => {
    const { fb_campaign_spend: fbSpend = [], currency } = fbCampaign;
    if (!currency || currency === preferredCurrency || !exchangeRates) return;
    fbSpend.forEach((spend) => {
      const { date, spend: spendVal } = spend;
      const exRateData = findExchangeRateForDate(date, exchangeRates);
      if (!exRateData) return;
      try {
        const parsedRates = JSON.parse(exRateData.rates);
        const converted = convertCurrency(spendVal, currency, parsedRates, preferredCurrency);
        if (typeof converted === 'number') spend.convertedSpend = converted;
      } catch (e) {
        console.error(e);
      }
    });
  });
  return {
    fbCampaigns: fbCampaigns.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
    fbSpendByDate: updateFBSpendByDate({ fbCampaigns }),
    fbSpendByBook: updateFBSpendByBook({ fbCampaigns }),
  };
}

export default function reducer(
  state = {
    isFetching: false,
    user: {},
    fbCampaigns: {},
    fbImportJobs: {},
    fbSpendByBook: {},
    fbSpendByDate: {},
  },
  action = {
    type: ''
  },
) {
  switch (action.type) {
    case POST_FACEBOOK_TOKEN:
    case DELETE_FACEBOOK_TOKEN:
      return {
        ...state,
        isFetching: true,
      };
    case POST_FACEBOOK_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        user: action.payload,
      };
    case POST_FACEBOOK_TOKEN_FAILURE:
    case DELETE_FACEBOOK_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case DELETE_FACEBOOK_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        user: action.payload,
      };
    case GET_IMPORT_FACEBOOK_AD_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_FB_CAMPAIGN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FB_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        fbCampaigns: mergeState(state.fbCampaigns, action.payload)
      };
    case GET_FB_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case GET_ALL_FACEBOOK_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_FACEBOOK_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        ...updateFbSpend(action.payload),
      };
    case GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case PATCH_FB_CAMPAIGN:
      return {
        ...state,
        isFetching: true,
      };
    case PATCH_FB_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        fbCampaigns: mergeState(state.fbCampaigns, action.payload),
      };
    case PATCH_FB_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_FB_CAMPAIGN_EXPORT:
      return {
        ...state,
        isFetching: true,
      };
    case POST_FB_CAMPAIGN_EXPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case POST_FB_CAMPAIGN_EXPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_FB_IMPORT_JOBS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FB_IMPORT_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fbImportJobs: mergeState(state.fbImportJobs, action.payload),
      };
    case GET_FB_IMPORT_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_FB_CAMPAIGN_SPEND:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FB_CAMPAIGN_SPEND_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fbSpend: action.payload,
      };
    case GET_FB_CAMPAIGN_SPEND_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
