import { getImportFacebookAdCampaignsRequest } from '../api/facebook';

import {
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE,
} from './types';

function getImportFacebookAdCampaigns() {
  return {
    type: GET_IMPORT_FACEBOOK_AD_CAMPAIGNS,
  };
}

function getImportFacebookAdCampaignsSuccess(payload) {
  return {
    type: GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS,
    payload,
  };
}

function getImportFacebookAdCampaignsFailure(error) {
  return {
    type: GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_FAILURE,
    payload: error,
  };
}

export default function requestGetImportFacebookAdCampaigns(token) {
  return (dispatch) => {
    dispatch(getImportFacebookAdCampaigns());
    return getImportFacebookAdCampaignsRequest(token)
      .then(json => dispatch(getImportFacebookAdCampaignsSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getImportFacebookAdCampaignsFailure(errors))));
  };
}
