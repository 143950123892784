export function bookTitle(book) {
  const { title, asin, isbn } = book;
  if (isbn && isbn.length && (!asin || !asin.length)) return `${title} (Paperback)`;
  return title;
}

export function isPaperback(book) {
  const { isbn } = book;
  return isbn && isbn.length;
}
