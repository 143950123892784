import { getExpenseRequest } from '../api/expenses';

import {
  GET_EXPENSE,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSE_FAILURE,
} from './types';


function getExpense() {
  return {
    type: GET_EXPENSE,
  };
}

function getExpenseSuccess(json) {
  return {
    type: GET_EXPENSE_SUCCESS,
    payload: json,
  };
}

function getExpenseFailure(error) {
  return {
    type: GET_EXPENSE_FAILURE,
    payload: error,
  };
}


export default function requestGetExpense(expenseId, token) {
  return (dispatch) => {
    dispatch(getExpense());
    return getExpenseRequest(expenseId, token)
      .then(json => dispatch(getExpenseSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getExpenseFailure(errors))));
  };
}
