import { mergeState } from '11online-redux-helpers';
import {
  POST_TAG,
  POST_TAG_FAILURE,
  POST_TAG_SUCCESS,
  SET_TAGS,
  UPDATE_TAGS,
} from '../actions/types';

export default function reducer(state = {
  tags: {},
  isFetching: false,
  error: null,
}, action = {
  type: '',
}) {
  switch (action.type) {
    case POST_TAG:
      return {
        ...state,
        isFetching: true,
      };
    case POST_TAG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_TAG_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload.reduce((acc, x) => {
          return { ...acc, [x.id]: x };
        }, {}),
      };
    case UPDATE_TAGS:
      return {
        ...state,
        tags: {
          ...(state.tags || {}),
          ...(Array.isArray(action.payload)
            ? action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
            : { [action.payload.id]: action.payload }
          ),
        },
      };
    default:
      return state;
  }
}
