import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import AreaChart from '../blocks/AreaChart';
import PieChart from '../blocks/PieChart';
import Filters from '../blocks/Filters';
import {
  GET_ALL_SALES_FAILURE
} from '../../actions/types';
import { filterRawSalesData } from "../../utilities/sales-helpers";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import styles from '../../styles';
import {
  currencies,
  dateRangePresets,
  PIE_CHART_METRICS,
  PIE_CHART_RESOURCES, USER_JOBS,
} from "../../utilities/constants";
import { datesForDateRangePreset, defaultSalesDates } from "../../utilities/date-helpers";
import { currencySymbol } from "../../utilities/locale-helpers";
import { withStyles } from '@material-ui/core/styles';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import AutocompleteFilter from '../blocks/AutocompleteFilter';
import { cacheVal, getCachedVal } from '../../utilities/cache-helpers';
import { bookFilterOptions, tagFilterOptions, authorFilterOptions, marketplaceFilterOptions } from '../../utilities/filter-helpers';
import Button from '@material-ui/core/Button';
import AccountSnapshot from '../blocks/AccountSnapshot';
import BookThumbnails from '../blocks/BookThumbnails';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Typography} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import withJobPolling from "../blocks/WithJobPolling";
import MUIDataTable from "mui-datatables";
import TotalsTable from "../blocks/TotalsTable";
import InputBase from '@material-ui/core/InputBase';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const FILTERS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  DATE_RANGE_PRESET: 'dateRangePreset',
  SELECTED_AUTHORS: 'selectedAuthors',
  SELECTED_BOOKS: 'selectedBooks',
  SELECTED_TAGS: 'selectedTags',
  SELECTED_MARKETPLACES: 'selectedMarketplaces',
  PIE_CHART_RESOURCE: 'pieChartResource',
  PIE_CHART_METRIC: 'pieChartMetric',
  BOOK_TYPE: 'bookType',
};

const BOOK_TYPES = {
  ALL: 'all',
  PAPERBACK_ONLY: 'paperbackOnly',
  EXCLUDE_PAPERBACK: 'excludePaperback',
}

class ViewSalesByBook extends PureComponent {
  state = {
    [FILTERS.SELECTED_AUTHORS]: [],
    [FILTERS.SELECTED_BOOKS]: [],
    [FILTERS.SELECTED_TAGS]: [],
    [FILTERS.DATE_RANGE_PRESET]: dateRangePresets.THIS_MONTH,
    [FILTERS.PIE_CHART_RESOURCE]: PIE_CHART_RESOURCES.BOOK,
    [FILTERS.PIE_CHART_METRIC]: PIE_CHART_METRICS.EARNINGS,
    [FILTERS.BOOK_TYPE]: BOOK_TYPES.ALL,
    customDateRange: {},
    ...defaultSalesDates,
  };

  componentDidMount() {
    this.maybeResetDateRange(() => {
      const { startDate, endDate } = this.state;
      this.requestSales({ startDate, endDate });
      this.maybeResetFilters();
    })
  }

  maybeResetDateRange = (callback) => {
    const startDate = getCachedVal(FILTERS.START_DATE),
      endDate = getCachedVal(FILTERS.END_DATE),
      dateRangePreset = getCachedVal(FILTERS.DATE_RANGE_PRESET);

    if (startDate && endDate && dateRangePreset) {
      this.setState({
        ...this.state,
        startDate,
        endDate,
        dateRangePreset,
      }, () => callback());
    }
    else callback();
  };

  maybeResetFilters = () => {
    const filters = {};
    const selectedAuthors = getCachedVal(FILTERS.SELECTED_AUTHORS);
    if (Array.isArray(selectedAuthors)) filters[FILTERS.SELECTED_AUTHORS] = selectedAuthors;
    const selectedBooks = getCachedVal(FILTERS.SELECTED_BOOKS);
    if (Array.isArray(selectedBooks)) filters[FILTERS.SELECTED_BOOKS] = selectedBooks;
    const selectedTags = getCachedVal(FILTERS.SELECTED_TAGS);
    if (Array.isArray(selectedTags)) filters[FILTERS.SELECTED_TAGS] = selectedTags;
    const selectedMarketplaces = getCachedVal(FILTERS.SELECTED_MARKETPLACES);
    if (Array.isArray(selectedMarketplaces)) filters[FILTERS.SELECTED_MARKETPLACES] = selectedMarketplaces;
    if (Object.keys(filters).length) this.setState({
      ...this.state,
      ...filters,
    });
  };

  setBook = data => {
    let newBooks = data;
    if (!Array.isArray(data)) newBooks = [];
    cacheVal(FILTERS.SELECTED_BOOKS, newBooks);
    this.setState({
      ...this.state,
      [FILTERS.SELECTED_BOOKS]: newBooks,
    });
  };

  setAuthor = data => {
    let newAuthors = data;
    if (!Array.isArray(data)) newAuthors = [];
    cacheVal(FILTERS.SELECTED_AUTHORS, newAuthors);
    this.setState({
      ...this.state,
      [FILTERS.SELECTED_AUTHORS]: newAuthors,
      [FILTERS.SELECTED_BOOKS]: [],
    });
  };

  setTags = data => {
    let newTags = data;
    if (!Array.isArray(data)) newTags = [];
    cacheVal(FILTERS.SELECTED_TAGS, newTags);
    this.setState({
      ...this.state,
      [FILTERS.SELECTED_TAGS]: newTags,
    });
  };

  setMarketplaces = data => {
    let newMarketplaces = data;
    if (!Array.isArray(data)) newMarketplaces = [];
    cacheVal(FILTERS.SELECTED_MARKETPLACES, newMarketplaces);
    this.setState({
      ...this.state,
      [FILTERS.SELECTED_MARKETPLACES]: newMarketplaces,
    });
  };

  setBookType = event => {
    const { target } = event,
      { value } = target || {};
    cacheVal(FILTERS.BOOK_TYPE, value);
    this.setState({
      ...this.state,
      [FILTERS.BOOK_TYPE]: value,
    });
  }

  setDateRangePreset = data => {
    const { value } = data.target;
    const { startDate, endDate } = value === dateRangePresets.CUSTOM ?
      {} :
      datesForDateRangePreset(value);
    cacheVal(FILTERS.DATE_RANGE_PRESET, value);
    if (startDate) cacheVal(FILTERS.START_DATE, startDate);
    if (endDate) cacheVal(FILTERS.END_DATE, endDate);
    this.setState({
      ...this.state,
      [FILTERS.DATE_RANGE_PRESET]: value,
      ...(value !== dateRangePresets.CUSTOM ? { ...datesForDateRangePreset(value), customDateRange: {} } : {}),
    }, () => {
      if (startDate || endDate) this.requestSales({ startDate, endDate });
    });
  };

  setDate = ({ startDate, endDate }) => {
    this.setState({
      ...this.state,
      customDateRange: {
        [FILTERS.START_DATE]: startDate,
        [FILTERS.END_DATE]: endDate,
      },
      [FILTERS.DATE_RANGE_PRESET]: dateRangePresets.CUSTOM,
    })
  };

  setPieChartFilter = key => val => this.setState({ [key]: val.target.value });

  requestSales = ({ startDate, endDate } = {}) => {
    const { dispatch } = this.props;
    const { requestGetAllSales, newError } = Actions;
    if (startDate) startDate = moment(startDate).startOf('day').format('YYYY-MM-DD');
    if (endDate) endDate = moment(endDate).startOf('day').format('YYYY-MM-DD');
    dispatch(requestGetAllSales(Cookies.get('token'), startDate, endDate))
      .then(res => {
        const { type, payload = {} } = res;
        if (type === GET_ALL_SALES_FAILURE)
          dispatch(newError(payload.message || 'An error occurred'));
      });
  };

  getSalesData = () => {
    const {
      selectedBooks,
      selectedAuthors,
      selectedTags,
      selectedMarketplaces,
      [FILTERS.PIE_CHART_METRIC]: pieChartMetric,
      [FILTERS.PIE_CHART_RESOURCE]: pieChartResource,
      [FILTERS.BOOK_TYPE]: bookType,
    } = this.state,
      { salesByBook, expensesByBook, fbSpendByBook, authorBooks, user, bookTags, amsSpendByDate, tags, recurringExpenses, paperbackBookIds } = this.props;
    const { preferred_currency: preferredCurrency } = user;

    if (!salesByBook) return;

    Object.values(recurringExpenses || {}).forEach(exp => {
      const { book_id } = exp;
      if (!book_id) return;
      if (book_id in expensesByBook) expensesByBook[book_id].push(exp);
      else expensesByBook[book_id] = [ exp ];
    });

    const selectedTagIds = (selectedTags || []).map(x => x.value);
    let selectedBookIds = (selectedBooks || []).map(x => x.value);
    const selectedAuthorIds = (selectedAuthors || []).map(x => x.value);
    const selectedMarkets = (selectedMarketplaces || []).map(x => x.value);

    if (bookType === BOOK_TYPES.PAPERBACK_ONLY) {
      if (!selectedBookIds || !selectedBookIds.length) selectedBookIds = paperbackBookIds || [];
      else selectedBookIds = selectedBookIds.filter(bookId => (paperbackBookIds || []).includes(bookId));
    }
    else if (bookType === BOOK_TYPES.EXCLUDE_PAPERBACK) {
      if (!selectedBookIds || !selectedBookIds.length) {
        const allBookIds = Object.keys(salesByBook || {}).map(x => parseInt(x));
        selectedBookIds = allBookIds.filter(bookId => !(paperbackBookIds || []).includes(bookId));
      }
      else selectedBookIds = selectedBookIds.filter(bookId => !(paperbackBookIds || []).includes(bookId));
    }

    return filterRawSalesData(
      salesByBook,
      expensesByBook,
      amsSpendByDate,
      fbSpendByBook,
      authorBooks,
      selectedBookIds,
      selectedAuthorIds,
      selectedTagIds,
      bookTags,
      preferredCurrency,
      selectedMarkets,
      pieChartMetric,
      pieChartResource,
      tags
    );
  };

  applyCustomDateRange = () => {
    const { [FILTERS.START_DATE]: startDate, [FILTERS.END_DATE]: endDate } = this.state.customDateRange || {};
    cacheVal(FILTERS.START_DATE, startDate);
    cacheVal(FILTERS.END_DATE, endDate);
    cacheVal(FILTERS.DATE_RANGE_PRESET, dateRangePresets.CUSTOM);
    this.setState({ customDateRange: {}, [FILTERS.START_DATE]: startDate, [FILTERS.END_DATE]: endDate }, () => {
      this.requestSales({ startDate, endDate });
    });
  };

  renderCharts = (salesData) => {
    const { chartData, pieChartData } = salesData;
    const { theme, user, classes } = this.props;
    const { [FILTERS.PIE_CHART_METRIC]: pieChartMetric } = this.state;
    const preferredCurrency = user ? user.preferred_currency : currencies.USD;
    const yLabel = `Sales and Expenses (${currencySymbol(preferredCurrency)})`;
    const pieChartFormatters = pieChartMetric === 'earnings' ? [
      {
        type: 'NumberFormat',
        column: 1,
        options: {
          prefix: currencySymbol(preferredCurrency),
        },
      },
    ] : [];

    return (
      <div>
        <SectionWrapper className={classes.greyBackground}>
          <Toolbar style={{
            display: 'flex',
          }}>
            <Select
              value={this.state[FILTERS.PIE_CHART_METRIC]}
              onChange={this.setPieChartFilter(FILTERS.PIE_CHART_METRIC)}
              inputProps={{
                className: classes.headerSelect,
              }}
            >
              <MenuItem value={PIE_CHART_METRICS.EARNINGS}>Earnings</MenuItem>
              <MenuItem value={PIE_CHART_METRICS.UNITS}>Units Sold</MenuItem>
            </Select>
            <Typography variant="h6" style={{ margin: '0 15px' }}>by</Typography>
            <Select
              value={this.state[FILTERS.PIE_CHART_RESOURCE]}
              onChange={this.setPieChartFilter(FILTERS.PIE_CHART_RESOURCE)}
              inputProps={{
                className: classes.headerSelect,
              }}
            >
              <MenuItem value={PIE_CHART_RESOURCES.BOOK}>Book</MenuItem>
              <MenuItem value={PIE_CHART_RESOURCES.AUTHOR}>Author</MenuItem>
              <MenuItem value={PIE_CHART_RESOURCES.MARKET}>Marketplace</MenuItem>
              <MenuItem value={PIE_CHART_RESOURCES.TAG}>Tag</MenuItem>
            </Select>
          </Toolbar>
          <PieChart
            data={pieChartData}
            theme={theme}
            sliceVisibilityThreshold={0.01}
            formatters={pieChartFormatters}
          />
        </SectionWrapper>
        <SectionWrapper className={classes.greyBackground} header="Earnings by Day">
          <AreaChart
            data={chartData}
            yLabel={yLabel}
            theme={theme}
            colors={[theme.palette.common.revenue, theme.palette.common.expenses, theme.palette.common.profit]}
            customTooltip={true}
          />
        </SectionWrapper>
      </div>
    );
  };

  renderThumbnails = (tableData) => {
    const { authorBooks, user } = this.props;
    const { show_top_earners: showTopEarners } = user;
    if (!showTopEarners) return;
    const books = [].concat(...Object.values(authorBooks).map(x => x.books));
    const topFive = tableData.sort((a, b) => b.totalRevRaw - a.totalRevRaw)
      .slice(0, 5).map(x => {
        const { id: bookId } = x;
        const book = books.find(b => b.id === bookId);
        return {
          id: bookId,
          title: x.book,
          cover_url: book && book.cover_url,
          amazon_url: book && book.amazon_url,
        };
      });
    return <BookThumbnails
      books={topFive}
      header="Top Earners"
    />
  };

  render() {
    const {
      customDateRange,
      selectedBooks,
      selectedAuthors,
      selectedTags,
      selectedMarketplaces,
      startDate,
      endDate,
      dateRangePreset,
      [FILTERS.BOOK_TYPE]: bookType
    } = this.state;
    const { [FILTERS.START_DATE]: customStartDate, [FILTERS.END_DATE]: customEndDate } = customDateRange;
    const showApplyCustom = !!customStartDate || !!customEndDate;
    const salesData = this.getSalesData();

    const { isFetching, classes, theme, authorBooks, tags } = this.props;
    if (isFetching || !salesData) {
      return (
        <div>
          <SectionWrapper className={classes.whiteBackground}>
            <PageHeader text={'sales'} />
          </SectionWrapper>
          <SectionWrapper style={{
            display: 'flex',
            minHeight: '60vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CircularProgress />
          </SectionWrapper>
        </div>
      )
    }
    const { tableData, tableColumns, tableTotals } = salesData;
    const { profit, totalRev, totalExp } = tableTotals;
    const totalCount = tableData.length,
      rowsPerPageOptions = [5, 10, 25, ...(totalCount < 25 ? [] : (100 > totalCount ? [totalCount] : [100, totalCount]))];
    const additionalInputs = (
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: 5,
          }}
        >
          <FormControl style={{ flex: 1 }}>
            <InputLabel htmlFor="book_type" style={{ color: 'white' }}>Book Type</InputLabel>
            <Select
              value={bookType}
              onChange={this.setBookType}
              input={<BootstrapInput name="book_type" id="book_type" />}
            >
              <MenuItem value={BOOK_TYPES.ALL}>All</MenuItem>
              <MenuItem value={BOOK_TYPES.PAPERBACK_ONLY}>Paperback Only</MenuItem>
              <MenuItem value={BOOK_TYPES.EXCLUDE_PAPERBACK}>Exclude Paperback</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <AutocompleteFilter
            value={selectedAuthors}
            onChange={this.setAuthor}
            options={authorFilterOptions(authorBooks, bookType)}
            placeholder="Select authors"
            isMulti={true}
            closeMenuOnSelect={false}
            theme={theme}
          />
          <AutocompleteFilter
            value={selectedBooks}
            onChange={this.setBook}
            options={bookFilterOptions(selectedAuthors, authorBooks, bookType)}
            placeholder="Select books"
            isMulti={true}
            theme={theme}
            closeMenuOnSelect={false}
            styleOverride={{
              container: base => ({
                ...base,
                flex: 2,
                margin: 5,
              })
            }}
          />
        </div>
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <AutocompleteFilter
            value={selectedTags}
            options={tagFilterOptions(tags)}
            placeholder="Select tags"
            onChange={this.setTags}
            isMulti={true}
            closeMenuOnSelect={false}
            theme={theme}
          />
          <AutocompleteFilter
            value={selectedMarketplaces}
            options={marketplaceFilterOptions()}
            placeholder="Select marketplaces"
            onChange={this.setMarketplaces}
            isMulti={true}
            closeMenuOnSelect={false}
            theme={theme}
          />
        </div>
      </div>
    );
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Sales" />
        </SectionWrapper>
        <SectionWrapper>
          <AccountSnapshot profit={profit} expenses={totalExp} revenue={totalRev} />
        </SectionWrapper>
        <Filters
          startDate={customStartDate || startDate}
          endDate={customEndDate || endDate}
          setDate={this.setDate}
          dateRangePreset={dateRangePreset}
          setDateRangePreset={this.setDateRangePreset}
          additionalInputs={showApplyCustom ?
            <Button
              onClick={this.applyCustomDateRange}
              variant="outlined"
              style={{
                color: 'white',
                backgroundColor: '#1b1b1b',
                borderColor: 'white',
              }}>
              Filter Custom
            </Button> :
            undefined
          }
          additionalRow={additionalInputs}
        >
          <SectionWrapper className={classes.greyBackground}>
            { this.renderThumbnails(salesData.tableData) }
          </SectionWrapper>
          {
            this.renderCharts(salesData)
          }
          {
            !this.props.hideTable &&
            <SectionWrapper header="Details by Book">
              <TotalsTable
                columns={tableColumns.slice(1)}
                data={[ tableTotals ]}
              />
              <MUIDataTable
                data={tableData}
                columns={tableColumns.map(col => {
                  const { name } = col;
                  if (name === 'profit') {
                    return {
                      ...col,
                      options: {
                        ...(col.options || {}),
                        setCellProps: (val, rowIdx) => {
                          const { profitRaw } = tableData[rowIdx];
                          return {
                            style: {
                              color: profitRaw < 0 ? 'red' : 'inherit',
                            }
                          };
                        }
                      }
                    };
                  }
                  return col;
                })}
                options={{
                  rowsPerPageOptions,
                  selectableRows: 'none',
                  customSort: (data, idx, dir) => {
                    const key = tableColumns[idx].name;
                    return data.sort((a, b) => {
                      if (key === 'book') {
                        return a.data[idx].localeCompare(b.data[idx]) * (dir === 'desc' ? 1 : -1);
                      }
                      const rawKeyIdx = tableColumns.findIndex(col => col.name === `${key}Raw`);
                      return (a.data[rawKeyIdx] < b.data[rawKeyIdx] ? 1 : -1) * (dir === 'desc' ? 1 : -1);
                    });
                  },
                }}
              />
              <TotalsTable
                columns={tableColumns.slice(1)}
                data={[ tableTotals ]}
              />
            </SectionWrapper>
          }
        </Filters>
        <Fab
          color="primary"
          style={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => window.location.reload()}
        >
          <RefreshIcon />
        </Fab>
      </div>
    );
  }
}

export default connect(
  state => ({
    salesByBook: state.sales.salesByBook,
    paperbackBookIds: state.sales.paperbackBookIds,
    isFetching: state.sales.isFetching,
    expensesByBook: state.expenses.expensesByBook,
    recurringExpenses: state.expenses.recurringExpenses,
    amsSpendByDate: state.ams.amsSpendByDate,
    fbSpendByDate: state.facebook.fbSpendByDate,
    fbSpendByBook: state.facebook.fbSpendByBook,
    authorBooks: state.auth.authorBooks,
    user: state.auth.user,
    bookTags: state.books.bookTags || {},
    tags: state.tags.tags || {},
  }),
)(withStyles(styles, { withTheme: true })(withJobPolling(ViewSalesByBook, USER_JOBS.KDP_IMPORT)));
