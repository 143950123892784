import { postExpenseRequest } from '../api/expenses';

import {
  POST_EXPENSE,
  POST_EXPENSE_SUCCESS,
  POST_EXPENSE_FAILURE,
} from './types';

function postExpense() {
  return {
    type: POST_EXPENSE,
  };
}

function postExpenseSuccess(json) {
  return {
    type: POST_EXPENSE_SUCCESS,
    payload: json,
  };
}

function postExpenseFailure(error) {
  return {
    type: POST_EXPENSE_FAILURE,
    payload: error,
  };
}


export default function requestPostExpense(formData, token) {
  return (dispatch) => {
    dispatch(postExpense());
    return postExpenseRequest(formData, token)
      .then(json => dispatch(postExpenseSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(postExpenseFailure(errors))));
  };
}
