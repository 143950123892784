import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import { connect } from 'react-redux';
import RequireAdmin from "../../utilities/RequireAdmin";
import RatesManager from "./RatesManager";

class Admin extends Component {
  state = {
    tab: 0,
  };

  onTabChange = (e, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Admin" />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{ minHeight: 500 }}>
            <AppBar position="static" color="secondary">
              <Tabs value={this.state.tab} onChange={this.onTabChange} variant="fullWidth" indicatorColor="primary" classes={{
                root: classes.darkGreyBackground,
              }}>
                <Tab label="KENP Rates" />
              </Tabs>
            </AppBar>
            { this.state.tab === 0 && <RatesManager /> }
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.auth.user,
}))(withStyles(styles, { withTheme: true })(RequireAdmin(Admin)));
