import {
  GET_PROFIT_AND_LOSS,
  GET_PROFIT_AND_LOSS_FAILURE,
  GET_PROFIT_AND_LOSS_SUCCESS,
  SET_PROFIT_AND_LOSS,
} from '../actions/types';
import {groupObjAryByKey} from "../utilities/array-helpers";
import {salesByDate} from "../utilities/sales-helpers";

function chartData(salesByBook, expenses) {
  const bookSalesByDate = salesByDate(salesByBook || {});
  const expByDate = groupObjAryByKey(Object.values(expenses || {}), 'date');

  return Object.keys(bookSalesByDate).reduce((acc, date) => {
    const salesItems = bookSalesByDate[date];
    const totalSales = salesItems.reduce((sum, sale) => {
      const { sales = 0, pageReadRevenue = 0 } = sale;
      return sum + sales + pageReadRevenue;
    }, 0);
    let totalExp = 0;
    if (expByDate && date in expByDate) {
      totalExp = expByDate[date].reduce((sum, x) => (sum + x.amount), 0);
    }
    return [...acc, [
      date,
      totalSales,
      totalExp,
    ]];
  }, []);
}

export default function reducer(
  state = {
    isFetching: false,
    chartData: null,
    error: null,
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_PROFIT_AND_LOSS:
      return {
        ...state,
        isFetching: true,
      };
    case SET_PROFIT_AND_LOSS:
      return { ...state, chartData: chartData(action.salesByBook, action.expenses) };
    case GET_PROFIT_AND_LOSS_SUCCESS:
      return { ...state, isFetching: false, error: null };
    case GET_PROFIT_AND_LOSS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}
