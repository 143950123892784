import { getAllPlansRequest } from '../api/braintree';

import {
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  GET_ALL_PLANS_FAILURE,
} from './types';

function getAllPlans() {
  return {
    type: GET_ALL_PLANS,
  };
}

function getAllPlansSuccess(json) {
  return {
    type: GET_ALL_PLANS_SUCCESS,
    payload: json,
  };
}

function getAllPlansFailure(error) {
  return {
    type: GET_ALL_PLANS_FAILURE,
    payload: error,
  };
}

export default function requestGetAllPlans() {
  return (dispatch) => {
    dispatch(getAllPlans());
    return getAllPlansRequest()
      .then(json => dispatch(getAllPlansSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllPlansFailure(errors))));
  };
}

