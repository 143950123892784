import { getAllExpenseCategoriesRequest } from '../api/expenses';

import {
  GET_ALL_EXPENSE_CATEGORIES,
  GET_ALL_EXPENSE_CATEGORIES_SUCCESS,
  GET_ALL_EXPENSE_CATEGORIES_FAILURE,
} from './types';

function getAllExpenseCategories() {
  return {
    type: GET_ALL_EXPENSE_CATEGORIES,
  };
}

function getAllExpenseCategoriesSuccess(json) {
  return {
    type: GET_ALL_EXPENSE_CATEGORIES_SUCCESS,
    payload: json,
  };
}

function getAllExpenseCategoriesFailure(error) {
  return {
    type: GET_ALL_EXPENSE_CATEGORIES_FAILURE,
    payload: error,
  };
}

export default function requestGetAllExpenseCategories(token) {
  return (dispatch) => {
    dispatch(getAllExpenseCategories());
    return getAllExpenseCategoriesRequest(token)
      .then(json => dispatch(getAllExpenseCategoriesSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllExpenseCategoriesFailure(errors))));
  };
}

