import { getAllInvoicesRequest } from '../api/braintree';

import {
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_FAILURE,
} from './types';

function getAllInvoices() {
  return {
    type: GET_ALL_INVOICES,
  };
}

function getAllInvoicesSuccess(json) {
  return {
    type: GET_ALL_INVOICES_SUCCESS,
    payload: json,
  };
}

function getAllInvoicesFailure(error) {
  return {
    type: GET_ALL_INVOICES_FAILURE,
    payload: error,
  };
}

export default function requestGetAllInvoices(apiToken){
  return (dispatch) => {
    dispatch(getAllInvoices());
    return getAllInvoicesRequest(apiToken)
      .then(json => dispatch(getAllInvoicesSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllInvoicesFailure(errors))));
  };
}

