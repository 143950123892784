import { mergeState } from '11online-redux-helpers';
import {
  POST_CATEGORY,
  POST_CATEGORY_SUCCESS,
  POST_CATEGORY_FAILURE,
  SET_CATEGORIES,
  UPDATE_CATEGORIES,
} from '../actions/types';

export default function reducer(state = {
  categories: {},
  isFetching: false,
  error: null,
}, action = {
  type: '',
}) {
  switch (action.type) {
    case POST_CATEGORY:
      return {
        ...state,
        isFetching: true,
      };
    case POST_CATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: mergeState({}, action.payload),
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        categories: mergeState(state.categories, action.payload),
      };
    default:
      return state;
  }
}
