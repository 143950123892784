import { postCategoryRequest } from '../api/categories';

import {
  POST_CATEGORY,
  POST_CATEGORY_FAILURE,
  POST_CATEGORY_SUCCESS,
  SET_CATEGORIES,
  UPDATE_CATEGORIES,
} from './types';

function postCategory() {
  return dispatch => dispatch({ type: POST_CATEGORY });
}

function postCategorySuccess(json) {
  return dispatch => dispatch({
    type: POST_CATEGORY_SUCCESS,
    payload: json,
  });
}

function postCategoryFailure(error) {
  return dispatch => dispatch({
    type: POST_CATEGORY_FAILURE,
    payload: error,
  });
}

function updateCategories(json) {
  return dispatch => dispatch({
    type: UPDATE_CATEGORIES,
    payload: json,
  });
}

export function setCategories(categories) {
  return dispatch => dispatch({
    type: SET_CATEGORIES,
    payload: categories,
  });
}


export function requestPostCategory(formData, token) {
  return (dispatch) => {
    dispatch(postCategory());
    return postCategoryRequest(formData, token)
      .then((json) => {
        dispatch(updateCategories(json));
        return dispatch(postCategorySuccess(json));
      })
      .catch(error => error.json().then(errors => dispatch(postCategoryFailure(errors))));
  };
}
