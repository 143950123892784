import { NEW_ERROR } from './types';

function showError(message) {
  return {
    type: NEW_ERROR,
    message,
  };
}

export default function newError(message) {
  return (dispatch) => {
    dispatch(showError(message));
  };
}
