import fetch from "isomorphic-fetch";
import { handleResponse } from '11online-fetch-helpers';
import {getUrlParamString} from "../utilities/request-helpers";

export const postAmsCampaignExportRequest = (formData, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/ams-campaign-export`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const getAmsCampaignsRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/ams-campaigns`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const getAmsHistoricalImportRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/ams-campaign-historical-import`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const patchAmsCampaignRequest = (campaignId, formData, token) => {
  formData.append('_method', 'PATCH');
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/ams-campaign/${campaignId}`,
    {
      method: 'POST',
      headers: {
        'api-token': token,
      },
      body: formData,
    },
  ).then(handleResponse);
};

export const getAmsSpendByDateRequest = (token, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/ams-campaign-spend-by-date`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};

export const postAmazonTokenRequest = (apiToken, amazonToken) => fetch(
    `${process.env.REACT_APP_API_ROOT}/amazon/token`,
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api-token': apiToken,
        },
        body: JSON.stringify({
            amazonToken
        }),
    },
).then(handleResponse);

