import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const componentStyles = {
  primary: {
    fontFamily: 'AvenirLTStd-Medium',
  },
  icon: {
    marginRight: 0,
  },
};

class DynamicMenuItem extends Component {
  render() {
    const { classes, className, name, icon, id, to, onClick } = this.props;

    return (
      <Link to={to} style={{ margin: '0 3%', textDecoration: 'none' }}>
        <MenuItem id={id} onClick={onClick}>
          <ListItemIcon classes={{
            root: classes.icon,
          }}
          >
            { icon }
          </ListItemIcon>
          <ListItemText
            inset
            primary={name}
            classes={{
              primary: classNames(classes.primary, className),
            }}
          />
        </MenuItem>
      </Link>
    );
  }
}

export default withStyles(componentStyles)(DynamicMenuItem);
