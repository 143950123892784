import { GET_SALES_BY_DATE, GET_SALES_BY_DATE_FAILURE, GET_SALES_BY_DATE_SUCCESS } from './types';
import { getSalesByDateRequest } from '../api/sales';

function getSalesByDate() {
  return dispatch => dispatch({ type: GET_SALES_BY_DATE });
}

function getSalesByDateSuccess(payload) {
  return dispatch => dispatch({ type: GET_SALES_BY_DATE_SUCCESS, payload });
}

function getSalesByDateFailure(payload) {
  return dispatch => dispatch({ type: GET_SALES_BY_DATE_FAILURE, payload });
}

export function requestGetSalesByDate(token, startDate, endDate) {
  return (dispatch) => {
    dispatch(getSalesByDate());
    return getSalesByDateRequest(token, startDate, endDate)
      .then(json => dispatch(getSalesByDateSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(getSalesByDateFailure(eJson))));
  };
}
