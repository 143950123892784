import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import SortableTable from '../blocks/SortableTable';
import Button from '@material-ui/core/Button';
import styles from '../../styles';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {doesItemExistInState} from '../../utilities/exist-in-state';
import {
  DELETE_CUSTOM_RATE_SUCCESS,
  GET_ALL_CUSTOM_RATES_SUCCESS, PATCH_CUSTOM_RATE_SUCCESS, POST_CUSTOM_RATE_SUCCESS,
} from '../../actions/types';
import { capitalizeFirstChar } from "../../utilities/string-helpers";
import RateForm from '../blocks/RateForm';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../blocks/ConfirmationDialog';

class ViewCustomRates extends Component {
  state = {
    rate: null,
    showConfirmationDialog: false,
    confirmDeleteRateId: null,
  };

  componentDidMount() {
    if (doesItemExistInState(this.props.rates.customRates, this.props.auth.user.id)) return;
    this.getCustomRates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth === this.props.auth) return;
    const doesRateExistInState = doesItemExistInState(this.props.rates.customRates, this.props.auth.user.id);
    if (doesRateExistInState) return;
    this.getCustomRates();
  }

  getCustomRates() {
    this.props.dispatch(Actions.requestGetAllCustomRates(Cookies.get('token')))
      .then(res => {
        if (res.type !== GET_ALL_CUSTOM_RATES_SUCCESS) {
          this.props.dispatch(Actions.newError(res.payload.message));
        }
      });
  }

  onDelete = rate => {
    const { id } = rate;
    this.setState({ showConfirmationDialog: true, confirmDeleteRateId: id });
  };

  deleteRate = rateId => {
    const { dispatch } = this.props;
    const token = Cookies.get('token');
    dispatch(Actions.requestDeleteCustomRate(token, rateId))
      .then(res => {
        const { type } = res;
        if (type === DELETE_CUSTOM_RATE_SUCCESS) dispatch(Actions.newMessage('Rate deleted!'));
        dispatch(Actions.requestGetAllSales(token));
        dispatch(Actions.requestGetProfitAndLoss(token));
      });
  };

  submitRate = (formData, rateId) => {
    if (rateId) return this.patchRate(formData, rateId);
    return this.postRate(formData);
  };

  patchRate = (formData, rateId) => {
    const { dispatch } = this.props,
      token = Cookies.get('token');
    dispatch(Actions.requestPatchCustomRate(formData, rateId, token))
      .then(res => {
        if (res.type === PATCH_CUSTOM_RATE_SUCCESS) dispatch(Actions.newMessage('Rate updated!'));
        else this.renderError(res);
      });
  };

  postRate = (formData) => {
    const { dispatch } = this.props,
      token = Cookies.get('token');
    dispatch(Actions.requestPostCustomRate(formData, token))
      .then(res => {
        const { type } = res;
        if (type === POST_CUSTOM_RATE_SUCCESS) {
          dispatch(Actions.newMessage('Rate created!'));
          this.setState({ rate: null });
        }
        else this.renderError(res);
      })
  };

  renderError = res => {
    const { payload } = res;
    const { dispatch } = this.props;
    if (!payload || typeof payload !== 'object') return;
    const errorMsg = [].concat(...Object.values(payload)).join(' ');
    dispatch(Actions.newError(errorMsg));
  };

  renderRateForm = () => {
    const rate = this.state.rate || {};
    const error = this.props.rates.error || {};
    return (
      <RateForm
        rate={rate}
        onSubmit={this.submitRate}
        onBack={() => this.setState({ rate: null })}
        error={error}
      />
    );
  };

  addRate = () => {
    this.setState({ rate: {} });
  };

  render() {
    const { auth = {}, rates, theme } = this.props;
    const { user = {} } = auth;
    const { id: userId } = user;
    const usersRates = rates.customRates[userId];

    let tableData = [];
    const tableColumns = [
      { key: 'rate', title: 'Rate' },
      { key: 'marketplace', title: 'Marketplace' },
      { key: 'actions', title: 'Actions' },
    ];
    if (usersRates) {
      tableData = Object.values(usersRates).map((x) => {
        const { rate, marketplace, id } = x;
        return {
          id,
          rate,
          marketplace: capitalizeFirstChar(marketplace),
          actions: (
            <div>
              <IconButton color="primary" onClick={() => this.setState({ rate: x })}>
                <Edit />
              </IconButton>
              <IconButton style={{ color: theme.palette.error.main }} onClick={() => this.onDelete(x)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        };
      });
    }

    if (this.state.rate) return this.renderRateForm();

    return (
      <div style={styles.viewContainer}>
        <Button variant="contained" color="primary" onClick={this.addRate}>Add Custom Rate</Button>
        <p>You may enter a custom rate for as many marketplaces as you wish. However, please note that the rate for <strong>amazon.com</strong> will be used for all marketplaces without a custom rate</p>
        <SortableTable data={tableData} columns={tableColumns} />
        <ConfirmationDialog
          open={this.state.showConfirmationDialog}
          value={this.state.confirmDeleteRateId}
          onClose={rateId => {
            if (rateId) this.deleteRate(rateId);
            this.setState({ showConfirmationDialog: false });
          }}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    rates: state.rates,
  }),
)(withStyles(styles, { withTheme: true })(ViewCustomRates));
