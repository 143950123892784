import { getAllCustomRatesRequest } from "../api/custom-rates";
import {
  GET_ALL_CUSTOM_RATES,
  GET_ALL_CUSTOM_RATES_ERROR,
  GET_ALL_CUSTOM_RATES_SUCCESS
} from "./types";

function getAllCustomRates() {
  return { type: GET_ALL_CUSTOM_RATES };
}

function getAllCustomRatesSuccess(payload) {
  return { type: GET_ALL_CUSTOM_RATES_SUCCESS, payload };
}

function getAllCustomRatesError(payload) {
  return { type: GET_ALL_CUSTOM_RATES_ERROR, payload };
}

export default function requestGetAllCustomRates(token) {
  return (dispatch) => {
    dispatch(getAllCustomRates());
    return getAllCustomRatesRequest(token)
      .then(json => dispatch(getAllCustomRatesSuccess(json)))
      .catch(e => e.json().then(eJson => dispatch(getAllCustomRatesError(eJson))));
  };
}
