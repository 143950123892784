import {
  GET_ACCOUNT_SNAPSHOT,
  GET_ACCOUNT_SNAPSHOT_FAILURE,
  GET_ACCOUNT_SNAPSHOT_SUCCESS,
} from './types';
import { requestGetSalesByDate } from './sales';
import requestGetAllExpenses from './get-all-expenses';
import { requestGetFbCampaignSpend } from './facebook';
import requestGetAllExchangeRates from './get-all-exchange-rates';
import moment from 'moment';

function getAccountSnapshot() {
  return dispatch => dispatch({ type: GET_ACCOUNT_SNAPSHOT });
}

function getAccountSnapshotSuccess(payload) {
  return dispatch => dispatch({ type: GET_ACCOUNT_SNAPSHOT_SUCCESS, payload });
}

function getAccountSnapshotFailure(payload) {
  return dispatch => dispatch({ type: GET_ACCOUNT_SNAPSHOT_FAILURE, payload });
}

export function requestGetAccountSnapshot(token) {
  return (dispatch) => {
    const yesterday = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
    dispatch(getAccountSnapshot());
    return Promise.all([
      dispatch(requestGetSalesByDate(token, yesterday, yesterday)),
      dispatch(requestGetAllExpenses(token, yesterday, yesterday)),
      dispatch(requestGetFbCampaignSpend(token, yesterday, yesterday)),
      dispatch(requestGetAllExchangeRates(token)),
    ]).then(res => dispatch(getAccountSnapshotSuccess(res)))
      .catch(e => dispatch(getAccountSnapshotFailure(e)));
  };
}
