import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';

class PageHeader extends PureComponent {
  render() {
    return (
      <Typography component="h2" variant="h5">
        {this.props.text}
      </Typography>
    );
  }
}

export default PageHeader;
