import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import SalesIcon from '@material-ui/icons/AttachMoney';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ExpensesIcon from '@material-ui/icons/BusinessCenter';
import SyncIcon from '@material-ui/icons/Sync';
import BillingIcon from '@material-ui/icons/CreditCard';
import MyAccountIcon from '@material-ui/icons/Person';
import ImportExport from '@material-ui/icons/ImportExport';
import LockIcon from '@material-ui/icons/Lock';
import Timeline from '@material-ui/icons/Timeline';
import DynamicMenuItem from './DynamicMenuItem';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import { Link } from 'react-router-dom';
import { GET_SUBSCRIPTION_SUCCESS } from '../../actions/types';
import Actions from '../../actions';
const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: "url('/background.jpg')",
    display: 'flex',
    justifyContent: 'flex-start',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    color: 'white',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logo: {
    width: 30,
    marginRight: 10,
  },
  topNavLinkText: {
    fontSize: 20,
  },
});

class NavigationDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, freeUser: false, basicUser: false, professionalUser: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  componentDidMount() {
    const {
      auth,
      dispatch,
    } = this.props;
    if (auth.user.api_token) {
      dispatch(Actions.requestGetSubscription(auth.user.api_token)).then((result) => {
        if (result.type === GET_SUBSCRIPTION_SUCCESS) {
          if (result.payload.braintree_plan === 'professional-monthly' || result.payload.braintree_plan === 'professional-annual') {
            this.setState({ professionalUser: true });
          } else if (result.payload.braintree_plan === 'basic-monthly' || result.payload.braintree_plan === 'basic-annual') {
            this.setState({ basicUser: true });
          } else if (result.payload.braintree_plan === 'free') {
            if (auth.user.monthly_earnings >= 1000) {
              this.setState({ freeUser: false });
            } else {
              this.setState({ freeUser: true });
            }
          } else {
            this.setState({ freeUser: false, basicUser: false, professionalUser: false });
          }
        } else {
          this.setState({ freeUser: false, basicUser: false, professionalUser: false });
        }
      });
    }
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      classes,
      theme,
      auth,
    } = this.props;
    const { user } = auth;
    const {
      open, freeUser, basicUser, professionalUser,
    } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <img src="bookkeeper-logo.png" alt="" className={classes.logo} />
            <Typography variant="h6" color="inherit">
              BookKeeper
            </Typography>
            {user.id && (professionalUser || basicUser || freeUser)
              ? [
                <DynamicMenuItem
                  key="view-sales"
                  id="view-sales"
                  to="/"
                  icon={<SalesIcon style={{ color: 'white' }} />}
                  name={<span style={{ color: 'white' }}>Sales</span>}
                  onClick={this.handleClose}
                  className={classes.topNavLinkText}
                />,
                <DynamicMenuItem
                  key="view-expenses"
                  id="view-expenses"
                  to="/expenses"
                  icon={<ExpensesIcon style={{ color: 'white' }} />}
                  name={<span style={{ color: 'white' }}>Expenses</span>}
                  onClick={this.handleClose}
                  className={classes.topNavLinkText}
                />,
              ] : null
            }
            {
              user.id
              && (
                <>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  >
                    <Link to="/settings" style={{ margin: 0 }}>
                      <IconButton>
                        <MyAccountIcon style={{ color: 'white' }} />
                      </IconButton>
                    </Link>
                  </div>
                </>
              )
            }
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <MenuList>
            {user.id && (professionalUser || basicUser || freeUser || user.role === 'admin')
              && (
                <>
                  <DynamicMenuItem
                    id="home"
                    to="/"
                    icon={<HomeIcon />}
                    name="Home"
                    onClick={this.handleClose}
                  />
                  <DynamicMenuItem
                    key="view-sales"
                    id="view-sales"
                    to="/"
                    icon={<SalesIcon />}
                    name={<span>Sales</span>}
                    onClick={this.handleClose}

                  />
                  <DynamicMenuItem
                    key="view-expenses"
                    id="view-expenses"
                    to="/expenses"
                    icon={<ExpensesIcon />}
                    name={<span>Expenses</span>}
                    onClick={this.handleClose}

                  />
                </>

              )
            }
            {user.id && (professionalUser || user.role === 'admin')
              && (
                <DynamicMenuItem
                  id="ad-campaigns"
                  to="/ad-campaigns"
                  icon={<Timeline />}
                  name="Ad Campaigns"
                  onClick={this.handleClose}
                />
              )
            }
            {
              (user && user.role === 'admin')
              && <DynamicMenuItem id="admin" to="/admin" icon={<LockIcon />} name="Admin" onClick={this.handleClose} />
            }
            {user.id
              && <DynamicMenuItem id="logout" to="/login" icon={<LogoutIcon />} name="Logout" onClick={this.handleClose} />
            }
            {!user.id
              && <DynamicMenuItem id="login" to="/login" icon={<LogoutIcon />} name="Login" onClick={this.handleClose} />
            }
          </MenuList>
        </Drawer>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
)(withStyles(styles, { withTheme: true })(NavigationDrawer));
