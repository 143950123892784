import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from '../../styles';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

class TotalsTable extends React.Component {
  getMuiTheme = () => createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: this.props.theme.palette.primary.dark,
          color: 'white',
          fontSize: 14
        }
      },
      MuiTableCell: {
        head: {
          backgroundColor: `${this.props.theme.palette.common.darkGrey} !important`,
          color: 'white',
          fontSize: 16,
        },
      },
    }
  });

  render = () => (
    <MuiThemeProvider theme={this.getMuiTheme()}>
      <MUIDataTable
        columns={this.props.columns}
        data={this.props.data}
        options={{
          selectableRows: 'none',
          filter: false,
          pagination: false,
          print: false,
          rowHover: false,
          search: false,
          sort: false,
          download: false,
          viewColumns: false,
        }}
      />
    </MuiThemeProvider>
  )
}

export default withStyles(styles, { withTheme: true })(TotalsTable);
