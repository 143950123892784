export function cacheVal(key, val) {
  localStorage.setItem(
    key,
    Array.isArray(val) || typeof val === 'object' ? JSON.stringify(val) : val,
  );
}

export function getCachedVal(key) {
  const val = localStorage.getItem(key);
  if (!val) return val;
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
}

export function clearCache() {
  localStorage.clear();
}
