import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Actions from '../../actions';
import SortableTable from '../blocks/SortableTable';
import {
  GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE,
  GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS,
  PATCH_FB_CAMPAIGN_SUCCESS,
} from '../../actions/types';
import styles from '../../styles';
import { validateFileExtension } from "../../utilities/file-helpers";
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import {sortObjAryByKey} from "../../utilities/array-helpers";
import Select from 'react-select';
import {bookTitle} from "../../utilities/book-helpers";
import { currencyFormatter } from "../../utilities/string-helpers";
import {currencies, USER_JOBS} from "../../utilities/constants";
import withJobPolling from "../blocks/WithJobPolling";

class ViewFbCampaigns extends Component {
  state = {
    start_date : '',
    end_date: '',
  };

  componentDidMount() {
    const token = Cookies.get('token');
    this.props.dispatch(Actions.requestGetAllFacebookCampaigns(token)).then(result => {
      if (result.type === GET_ALL_FACEBOOK_CAMPAIGNS_FAILURE) {
        this.props.dispatch(Actions.newError(result.payload.message));
      }
    });
    const queryParams = new URLSearchParams(window.location.search);
    const amsToken = queryParams.get('code');
    if(amsToken){
      this.props.dispatch(Actions.requestPostAmazonToken(Cookies.get('token'), amsToken));
    }
  }

  bookOptions = () => {
    const user = this.props.user || {},
      authors = user.author || [];
    const books = authors.reduce((acc, x) => {
      const books = x.books || [];
      return [ ...acc, ...books ];
    }, []).map(x => ({ label: bookTitle(x), value: x.id }));
    return sortObjAryByKey(books, 'label');
  };

  onBookSelectChange = (data, campaign) => {
    const { id: campaignId, book_id: campaignBookId } = campaign;
    const { value: bookId } = data || {};
    if (!campaignBookId && !bookId) return;
    this.props.dispatch(Actions.requestPatchFbCampaign(campaignId, bookId, Cookies.get('token')))
      .then(res => {
        const { type } = res;
        if (type === PATCH_FB_CAMPAIGN_SUCCESS) {
          this.props.dispatch(Actions.newMessage('Campaign updated successfully!'))
        }
        else {
          this.props.dispatch(Actions.newError('An error occurred. Unable to update campaign.'));
        }
      })
  };

  renderBookSelect = (campaign, bookId) => {
    const bookOpts = this.bookOptions(),
      selected = bookId ? bookOpts.find(x => x.value === bookId) : null;
    return (
      <Select
        isSearchable
        isClearable
        options={bookOpts}
        value={selected}
        placeholder="Select a book..."
        onChange={data => this.onBookSelectChange(data, campaign)}
        styles={{
          container: base => ({
            ...base,
            minWidth: 300
          })
        }}
      />
    )
  };

  importCampaigns = () => {
    const token = Cookies.get('token');
    this.props.dispatch(Actions.requestGetImportFacebookAdCampaigns(token)).then(res => {
      if (res.type !== GET_IMPORT_FACEBOOK_AD_CAMPAIGNS_SUCCESS) return;
      this.props.dispatch(Actions.newMessage(res.payload.message));
    });
  };

  onFileSelect = (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    const [ file ] = files;
    const validExts = ['.csv', '.xls', '.xlsx'];
    if (!validateFileExtension(file, validExts)) {
      this.props.dispatch(Actions.newError(`Please select a file with one of the following extensions: ${validExts.join(', ')}`));
      return false;
    }
    const token = Cookies.get('token');
    const formData = new FormData();
    formData.append('file', file);
    this.props.dispatch(Actions.requestPostFbCampaignExport(formData, token))
      .then(() => this.props.dispatch(Actions.requestGetAllFacebookCampaigns(token)))
      .then(() => this.props.dispatch(Actions.newMessage('Export successfully uploaded.')))
      .catch(e => {
        console.log(e);
      });
  };

  isSyncing = () => {
    const { user } = this.props,
      { jobs } = user || {};
    return !!(jobs || []).find(x => x.job_type === USER_JOBS.FB_IMPORT);
  };

  renderFbStatus = () => {
    let icon = null,
      txt = null;
    const iconProps = { fontSize: 'large', style: { marginLeft: 5 } };
    switch (this.props.fbStatus) {
      case 'connected':
        icon = <SyncIcon { ...iconProps } />;
        txt = 'Connected';
        break;
      case 'not_authorized':
        icon = <SyncProblemIcon { ...iconProps } />;
        txt = 'Partially Connected';
        break;
      default:
        icon = <SyncDisabledIcon { ...iconProps } />;
        txt = 'Not Connected';
        break;
    }
    return (
      <div>
        <Typography gutterBottom variant="h6" style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          Facebook Status:
          { icon }
          { txt }
        </Typography>
      </div>
    )
  };

  //TODO filter through spends and then add up the spend in a date range
  //TODO make dropdowns for start and stop, and put those dates in the state in onchage
  //TODO onchange call filter function

  render() {
    const tableColumns = [
      {key: 'name', title: 'Name'},
      {key: 'spend', title: 'Spend'},
      {key: 'daily_budget', title: 'Daily Budget'},
      {key: 'currency', title: 'Currency'},
      {key: 'fb_campaign_id', title: 'Fb Campaign ID'},
      {key: 'book', title: 'Book'},
      {key: 'active', title: 'Active'},
      {key: 'start_time', title: 'Start Time'},
      {key: 'updated_time', title: 'Updated Time'},
    ];

    const { user, fbStatus } = this.props;
    const { preferred_currency = currencies.USD } = user || {};
    const currFormatter = currencyFormatter(preferred_currency);
    const campaigns = Object.values(this.props.fbCampaigns);
    const tableData = campaigns.map(campaign => {
      const { book_id: bookId, id, fb_campaign_spend: fbSpend, currency } = campaign;
      const campaignCurrFormatter = currencyFormatter(currency);
      const spend = (fbSpend || []).reduce((total, fbSpend)=> {
        const { spend, convertedSpend } = fbSpend;
        let spendAmount = convertedSpend || spend;
        if (typeof spendAmount === 'string') spendAmount = parseFloat(spendAmount);
        return total + (spendAmount || 0);
      },0);

      const daily_budget = fbSpend && fbSpend.length ? fbSpend[0].daily_budget : 0;

      return {
        name: campaign.name,
        spend: currFormatter.format(spend),
        spendRaw: spend,
        currency,
        daily_budget: campaignCurrFormatter.format(daily_budget),
        daily_budgetRaw: daily_budget,
        fb_campaign_id: campaign.fb_campaign_id,
        book: this.renderBookSelect(campaign, bookId),
        active: campaign.configured_status === '1' ? <CheckIcon/> : '',
        start_time: moment(campaign.start_time).format('YYYY-MM-DD'),
        updated_time: moment(campaign.updated_time).format('YYYY-MM-DD'),
      }
    });

    const { isFetching } = this.props;

    return (
      <div style={{
        padding: 15,
        minHeight: 300,
        width: '100%',
      }}>
        {
          isFetching &&
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(1,1,1,.3)',
            zIndex: 999
          }}>
            <CircularProgress />
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            type="file"
            style={{ display: 'none' }}
            id="file-input"
            onChange={this.onFileSelect}
            accept=".xlsx, .xls, .csv"
          />
          <label htmlFor="file-input">
            <Button variant="contained" color="primary">
              Upload Campaign Export
            </Button>
          </label>
          <div>
            {
              !this.isSyncing() &&
              <Button variant="contained" color="primary" onClick={()=>this.importCampaigns()}>
                Import Historical Facebook Campaigns
              </Button>
            }
          </div>
            <div>
              { this.renderFbStatus() }
              {
                fbStatus !== 'connected' &&
                <Button color="primary" variant="contained" onClick={this.props.onLogin}>
                  Login
                </Button>
              }
              {
                fbStatus === 'connected' &&
                <Button variant="contained" onClick={this.props.onLogout}>
                  Log out
                </Button>
              }
            </div>
        </div>
        <SortableTable
          key={'campaignTable'}
          data={tableData}
          columns={tableColumns}
          rowsPerPage={10}
          search={true}
          padding="dense"
          orderBy="active"
        />
      </div>
    );
  }
}
export default connect(
  state => ({
    user: state.auth.user,
    fbCampaigns: state.facebook.fbCampaigns,
    isFetching: state.facebook.isFetching,
  }),
)(withStyles(styles, { withTheme: true })(withJobPolling(ViewFbCampaigns, USER_JOBS.FB_IMPORT)));
