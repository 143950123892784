import { postFacebookTokenRequest } from '../api/facebook';

import {
  POST_FACEBOOK_TOKEN,
  POST_FACEBOOK_TOKEN_SUCCESS,
  POST_FACEBOOK_TOKEN_FAILURE,
}
 from '../actions/types'

function postFacebookToken() {
  return {
    type: POST_FACEBOOK_TOKEN,
  };
}

function postFacebookTokenSuccess(json) {
  return {
    type: POST_FACEBOOK_TOKEN_SUCCESS,
    payload: json,
  };
}

function postFacebookTokenFailure(error) {
  return {
    type: POST_FACEBOOK_TOKEN_FAILURE,
    payload: error,
  };
}

export default function requestPostFacebookToken(apiToken, fbAccessToken){
  return (dispatch) => {
    dispatch(postFacebookToken());
    return postFacebookTokenRequest(apiToken, fbAccessToken)
    .then((json) => dispatch(postFacebookTokenSuccess(json)))
    .catch(error => dispatch(postFacebookTokenFailure(error)));
  };
}
