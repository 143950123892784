import React, { PureComponent } from 'react';
import { Chart } from 'react-google-charts';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

export default class PieChart extends PureComponent {
  render() {
    const {title, customTooltip, formatters, theme, sliceVisibilityThreshold } = this.props;
    let {data} = this.props;
    // Filter out negative values
    data = data.filter(a=>a[1]>=0||a[1]==='Sales');
    return (
      <div>
        {
          !!title &&
          <Toolbar>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        }
        <Chart
          width="100%"
          height="400px"
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          formatters={formatters || []}
          data={data}
          options={{
            sliceVisibilityThreshold: sliceVisibilityThreshold || 0.05,
            backgroundColor: theme.palette.common.lightGrey,
            tooltip: {
              showColorCode: true,
              ...(customTooltip ? { isHtml: true, trigger: 'visible' } : {}),
            },
          }}
          // For tests
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    );
  }
}
