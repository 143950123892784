import React, {Component} from 'react';
import { sortObjAryByKey } from "../../utilities/array-helpers";
import CreatableSelect from 'react-select/creatable';

export default class BookTagForm extends Component {
  state = {
    bookTags: undefined,
    isLoading: false,
  };

  componentDidMount() {
    if (typeof this.state.bookTags === 'undefined') this.extractBookTags();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevBook = prevProps.book || {},
      currBook = this.props.book;

    if (currBook && currBook.id !== prevBook.id) this.extractBookTags();
  }

  extractBookTags = () => {
    const { book } = this.props;
    if (!book) return;
    const tags = book.tags || [];
    this.setState({ bookTags: tags.map(x => ({ value: x.id, label: x.text })) });
  };

  onTagsChange = data => {
    if (!data) this.setBookTagsAndSubmit(null);
    else if (Array.isArray(data)) this.setBookTagsAndSubmit(data);
  };

  handleCreate = data => {
    this.setState({ isLoading: true });
    this.props.postTag(data)
      .then(res => {
        if (!res) return;
        const { payload } = res;
        if (payload) {
          this.setBookTagsAndSubmit([
            ...(this.state.bookTags || []),
            { value: payload.id, label: payload.text },
          ])
        }
      });
  };

  setBookTagsAndSubmit = data => this.setState({ bookTags: data, isLoading: true }, () => this.submit());

  submit = () => {
    const formData = new FormData();
    (this.state.bookTags || []).forEach((x) => formData.append('tag_ids[]', x.value));
    if (this.props.book && this.props.book.id) {
      this.props.onSubmit(formData, this.props.book.id).then(() => this.setState({ isLoading: false }));
    }
  };

  tagOptions = () => {
    const tags = this.props.tags || {},
      opts = Object.values(tags).reduce((acc, x) => {
        return [ ...acc, { value: x.id, label: x.text } ];
      }, []);
    return sortObjAryByKey(opts, 'label');
  };

  render() {
    return (
      <CreatableSelect
        options={this.tagOptions()}
        onChange={this.onTagsChange}
        onCreateOption={this.handleCreate}
        value={this.state.bookTags}
        isLoading={this.state.isLoading}
        placeholder="Tags"
        id="tag_ids"
        name="tag_ids"
        isMulti={true}
      />
    );
  }
}
