import { getAllRatesRequest } from '../api/rates';

import {
  GET_ALL_RATES,
  GET_ALL_RATES_SUCCESS,
  GET_ALL_RATES_FAILURE,
} from './types';

function getAllRates() {
  return {
    type: GET_ALL_RATES,
  };
}

function getAllRatesSuccess(json) {
  return {
    type: GET_ALL_RATES_SUCCESS,
    payload: json,
  };
}

function getAllRatesFailure(error) {
  return {
    type: GET_ALL_RATES_FAILURE,
    payload: error,
  };
}

export default function requestGetAllRates(token) {
  return (dispatch) => {
    dispatch(getAllRates());
    return getAllRatesRequest(token)
      .then(json => dispatch(getAllRatesSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllRatesFailure(errors))));
  };
}
