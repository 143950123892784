import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PageHeader from '../blocks/PageHeader';
import SectionWrapper from '../blocks/SectionWrapper';
import ViewAmsCampaigns from './ViewAmsCampaigns';
import ViewFBCampaigns from './ViewFBCampaigns';
import Actions from "../../actions";
import Cookies from "cookies-js";
import { connect } from 'react-redux';

class Campaigns extends Component {
  state = {
    tab: 0,
    fbStatus: null,
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab');
    if(tab === '1'){
        this.setState({ tab: 1 });
    }else{
        this.setState({ tab: 0 });
    }

    this.initFB();
  }

  initFB = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId      : '409881879453192',
        cookie     : true,  // enable cookies to allow the server to access
        // the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v3.1' // use version 3.2
      });

      // Now that we've initialized the JavaScript SDK, we call
      // FB.getLoginStatus().  This function gets the state of the
      // person visiting this page and can return one of three states to
      // the callback you provide.  They can be:
      //
      // 1. Logged into your app ('connected')
      // 2. Logged into Facebook, but not your app ('not_authorized')
      // 3. Not logged into Facebook and can't tell if they are logged into
      //    your app or not.
      //
      // These three cases are handled in the callback function.
      this.checkFBLoginState();
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  // This is called with the results from from FB.getLoginStatus().
  onFBStatusChange = response => {
    let status = response.status;
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
      this.props.dispatch(Actions.newMessage('Successfully connected to Facebook!'));
      // Logged into your app and Facebook.
      this.testFBApi();
      // TODO: this is firing on every page load of this page do we need that??
      this.props.dispatch(Actions.requestPostFacebookToken(Cookies.get('token'), response.authResponse.accessToken));
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      window.FB.login(response => {
        status = response.status;
        if (response.status === 'connected') {
          this.props.dispatch(Actions.newMessage('Successfully connected to Facebook!'));
          // Logged into your app and Facebook.
          this.testFBApi();
          // TODO: this is firing on every page load of this page do we need that??
          this.props.dispatch(Actions.requestPostFacebookToken(Cookies.get('token'), response.authResponse.accessToken));
        } else {
          console.log('User canceled login or did not fully authorize');
        }
      }, { scope: 'business_management, ads_management, ads_read'});
    }
    this.setState({ fbStatus: status });
  }

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  testFBApi = () => window.FB.api('/me', console.log);

  onTabChange = (e, tab) => this.setState({ tab });

  handleFBLogin = () => window.FB.login(this.checkFBLoginState);

  checkFBLoginState = () => window.FB.getLoginStatus(this.onFBStatusChange);

  logUserOutOfFB = () => window.FB.logout(response => {
    this.setState({ fbStatus: response.status });
    this.props.dispatch(Actions.newMessage('Successfully disconnected to Facebook!'));
    this.props.dispatch(Actions.requestDeleteFbToken(Cookies.get('token')));
  });

  render() {
    const { classes } = this.props;
    const { fbStatus } = this.state;
    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text={'Ad Campaigns'} />
        </SectionWrapper>
        <SectionWrapper>
          <Paper style={{ minHeight: 500 }}>
            <AppBar position="static" color="secondary">
              <Tabs value={this.state.tab} onChange={this.onTabChange} variant="fullWidth" indicatorColor="primary" classes={{
                root: classes.darkGreyBackground,
              }}>
                <Tab label="Facebook" />
                <Tab label="Amazon Marketing Services" />
              </Tabs>
            </AppBar>
            { this.state.tab === 0 && <ViewFBCampaigns fbStatus={fbStatus}
                                                       onLogin={this.handleFBLogin}
                                                       onLogout={this.logUserOutOfFB} /> }
            { this.state.tab === 1 && <ViewAmsCampaigns /> }
          </Paper>
        </SectionWrapper>
      </div>
    );
  }
}

export default connect(
  state => ({}),
)(withStyles(styles, { withTheme: true })(Campaigns));
