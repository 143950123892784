import { postRateRequest } from '../api/rates';

import {
  POST_RATE,
  POST_RATE_SUCCESS,
  POST_RATE_FAILURE,
} from './types';

function postRate() {
  return {
    type: POST_RATE,
  };
}

function postRateSuccess(json) {
  return {
    type: POST_RATE_SUCCESS,
    payload: json,
  };
}

function postRateFailure(error) {
  return {
    type: POST_RATE_FAILURE,
    payload: error,
  };
}


export default function requestPostRate(formData, token) {
  return (dispatch) => {
    dispatch(postRate());
    return postRateRequest(formData, token)
      .then(json => dispatch(postRateSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(postRateFailure(errors))));
  };
}
