import { getAllExpensesRequest } from '../api/expenses';

import {
  GET_ALL_EXPENSES,
  GET_ALL_EXPENSES_SUCCESS,
  GET_ALL_EXPENSES_FAILURE,
} from './types';

function getAllExpenses() {
  return {
    type: GET_ALL_EXPENSES,
  };
}

function getAllExpensesSuccess(json) {
  return {
    type: GET_ALL_EXPENSES_SUCCESS,
    payload: json,
  };
}

function getAllExpensesFailure(error) {
  return {
    type: GET_ALL_EXPENSES_FAILURE,
    payload: error,
  };
}

export default function requestGetAllExpenses(token, startDate, endDate) {
  return (dispatch) => {
    dispatch(getAllExpenses());
    return getAllExpensesRequest(token, startDate, endDate)
      .then(json => dispatch(getAllExpensesSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getAllExpensesFailure(errors))));
  };
}
