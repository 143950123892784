import moment from 'moment';
import { groupObjAryByKey } from "./array-helpers";
import { sortDateStrArray } from "./date-helpers";
import { currencyFormatter } from "./string-helpers";
import {
  currencies,
  PIE_CHART_METRICS, PIE_CHART_RESOURCES,
  SALES_AREA_CHART_HEADERS,
  SALES_CHART_TYPES,
  SALES_PIE_CHART_HEADERS
} from "./constants";
import {convertCurrency, findExchangeRateForDate} from "./exchange-rate-helpers";
import {bookTitle} from "./book-helpers";

export function salesByDate(salesByBook) {
  return groupObjAryByKey(
    [].concat(...Object.values(salesByBook)),
    'date',
  );
}

/**
 * Get all chart dates for the given book sale and fb ad spend data
 * @param bookSalesByDate
 * @param fbSpend
 * @returns {Array}
 */
function chartDates(bookSalesByDate, fbSpend) {
  let fbSpendDates = [];
  if (fbSpend && Object.keys(fbSpend).length) {
    fbSpendDates = sortDateStrArray([].concat(...Object.values(fbSpend).map(x => Object.keys(x))));
  }
  if (Array.isArray(bookSalesByDate) && !bookSalesByDate.length && !fbSpendDates.length) return [];
  const bookDates = sortDateStrArray(Object.keys(bookSalesByDate));
  let start = moment(bookDates[0]).startOf('day');
  let end = moment(bookDates[bookDates.length - 1]).startOf('day');
  if (fbSpendDates.length) {
    if (moment(fbSpendDates[0]).isBefore(start)) start = moment(fbSpendDates[0]).startOf('day');
    if (moment(fbSpendDates[fbSpendDates.length - 1]).isAfter(end)) end = moment(fbSpendDates[fbSpendDates.length - 1]).startOf('day');
  }
  const dateAry = [];

  do {
    dateAry.push(start.format('YYYY-MM-DD'));
    start.add(1, 'day');
  } while (start.isSameOrBefore(end));
  return dateAry;
}

function chartData(bookSalesByDate, dateAry, expenses, fbSpend, amsSpend, formatter) {
  return dateAry.reduce((acc, date) => {
    const sales = bookSalesByDate[date] || [];
    const dateMoment = moment(date);

    let salesRev = 0;
    let readRev = 0;
    let paperbackRev = 0;
    let unitsSold = 0;
    let pagesRead = 0;
    let paperbackUnits = 0;
    const totalRev = sales.reduce((sum, s) => {
      let { sales: salesVal = 0, pageReadRevenue = 0, units_sold, pages, paperback_revenue, paperback_units } = s;
      if (typeof salesVal === 'string') salesVal = parseFloat(salesVal);
      if (typeof pageReadRevenue === 'string') pageReadRevenue = parseFloat(pageReadRevenue);
      if (typeof paperback_units === 'string') paperback_units = parseInt(paperback_units, 10);
      salesRev += salesVal;
      readRev += pageReadRevenue;
      unitsSold += (units_sold || 0);
      pagesRead += (pages || 0);
      paperbackRev += (paperback_revenue || 0);
      paperbackUnits += (paperback_units || 0);
      const newSum = parseFloat(sum) + salesVal + pageReadRevenue;
      return newSum;
    }, 0);

    let exp = 0;
    if (expenses) {
      expenses.forEach((e) => {
        const { date: expDate, amount } = e;
        if (date === expDate) exp += parseFloat(amount);
      });
    }

    if (fbSpend) {
      Object.values(fbSpend).forEach((byDate) => {
        if (!(date in byDate)) return;
        byDate[date].forEach((s) => {
          let spend = s.spend || 0;
          if (typeof spend === 'string') spend = parseFloat(spend);
          exp += spend;
        });
      });
    }

    if (amsSpend) {
      Object.values(amsSpend).forEach((x) => {
        const { cost, date: amsSpendDate } = x;
        if (moment(amsSpendDate).isSame(dateMoment)) exp += cost;
      });
    }

    const profit = totalRev - exp;
    const shortDateStr = dateMoment.toDate().toLocaleDateString(undefined, { dateStyle: 'short' });
    const medDateStr = dateMoment.toDate().toLocaleDateString(undefined, { dateStyle: 'medium' });
    const tooltip = tooltipHtml(
      medDateStr,
      formatter.format(totalRev),
      formatter.format(exp),
      formatter.format(profit),
      formatter.format(salesRev),
      formatter.format(readRev),
      unitsSold,
      pagesRead,
      formatter.format(paperbackRev),
      paperbackUnits,
    );
    return [
      ...acc,
      [
        shortDateStr,
        tooltip,
        totalRev,
        exp,
        profit,
      ],
    ];
  }, []);
}

function salesObjForResource(bookSales, pieChartResource, bookTags) {
  if (pieChartResource === PIE_CHART_RESOURCES.BOOK) return bookSales;
  if (pieChartResource === PIE_CHART_RESOURCES.AUTHOR) {
    // reduced by author
    return Object.values(bookSales).reduce((acc, bookSaleAry) => {
      if (!bookSaleAry || !bookSaleAry.length) return acc;
      const [ first ] = bookSaleAry;
      const { book } = first || {};
      const { authors = [] } = book || {};
      const [ author ] = authors;
      const { id } = author || {};
      if (!id) return acc;
      if (id in acc) return {
        ...acc,
        [id]: [ ...acc[id], ...bookSaleAry ],
      };
      return { ...acc, [id]: bookSaleAry };
    }, {});
  }
  // reduce by tag id
  if (pieChartResource === PIE_CHART_RESOURCES.TAG) {
    return Object.keys(bookSales).reduce((acc, bookId) => {
      const bookSaleAry = bookSales[bookId];
      if (!bookSaleAry || !bookSaleAry.length) return acc;
      const bookTagIds = Object.keys(bookTags || {})
        .filter(tagId => Array.isArray(bookTags[tagId]) && bookTags[tagId].includes(parseInt(bookId, 10)));
      if (!bookTagIds.length) {
        return {
          ...acc,
          untagged: [
            ...(acc.untagged || []),
            ...bookSaleAry,
          ],
        };
      }
      bookTagIds.forEach(tagId => {
        if (!(tagId in acc)) acc[tagId] = bookSaleAry;
        else acc[tagId] = [ ...acc[tagId], ...bookSaleAry ];
      });
      return acc;
    }, {});
  }
  // reduced by marketplace
  return Object.values(bookSales).reduce((acc, bookSaleAry) => {
    if (!bookSaleAry || !bookSaleAry.length) return acc;
    const [ first ] = bookSaleAry;
    const { marketplace } = first || {};
    if (!marketplace) return acc;
    if (!(marketplace in acc)) return { ...acc, [marketplace]: bookSaleAry };
    return { ...acc, [marketplace]: [ ...acc[marketplace], ...bookSaleAry ] };
  }, {});
}

function pieChartData(bookSales, pieChartMetric, pieChartResource, bookTags, tags) {
  const bookSalesObj = salesObjForResource(bookSales, pieChartResource, bookTags);
  return Object.values(bookSalesObj).reduce((acc, bookSaleAry, idx) => {
    if (!Array.isArray(bookSaleAry) || !bookSaleAry.length) return acc;
    const [ first ] = bookSaleAry;
    const { book, marketplace, book_id: bookId } = first || {};
    const { title, authors = [] } = book || {};
    const [ author ] = authors;
    const { name } = author || {};
    const val = bookSaleAry.reduce((sum, sale) => {
      let { sales: salesVal = 0, pageReadRevenue = 0, units_sold: unitsSold } = sale;
      if (pieChartMetric === PIE_CHART_METRICS.UNITS) return (sum + unitsSold || 0);
      if (typeof salesVal === 'string') salesVal = parseFloat(salesVal);
      if (typeof pageReadRevenue === 'string') pageReadRevenue = parseFloat(pageReadRevenue);
      return parseFloat(sum) + salesVal + pageReadRevenue;
    }, 0);
    let label = title;
    if (pieChartResource === PIE_CHART_RESOURCES.AUTHOR) label = name;
    else if (pieChartResource === PIE_CHART_RESOURCES.MARKET) label = marketplace;
    else if (pieChartResource === PIE_CHART_RESOURCES.TAG) {
      const tagId = Object.keys(bookSalesObj)[idx];
      if (tagId === 'untagged') label = 'Untagged';
      else {
        const tag = tags[tagId] || {},
          { text = 'Unknown' } = tag || {};
        label = text;
      }
    }
    return [ ...acc, [ label, val ] ];
  }, []).sort((a, b) => {
    const [aTitle] = a;
    const [bTitle] = b;
    return aTitle.localeCompare(bTitle);
  });
}

function tooltipHtml(date, revenue, expenses, profit, salesRev, readRev, unitsSold, pagesRead, paperbackRev, paperbackUnits) {
  return `
  <div class="area-chart-tooltip">
    <div class="date">${date}</div>
    <div class="category">
      <div class="category-header">
        <span class="label revenue"><span class="icon"></span>Revenue:</span>
        <span>${revenue}</span>
      </div>
      <div class="details">
        <div>
          <span class="label sales">Sales</span>
          <span>${salesRev}</span>
        </div>
        <div class="details">
          <span class="label sales">Units Sold</span>
          <span>${unitsSold}</span>
        </div>
        <div class="details">
          <span class="label sales">Paperback Rev</span>
          <span>${paperbackRev}</span>
        </div>
        <div class="details">
          <span class="label sales">Paperback Units</span>
          <span>${paperbackUnits}</span>
        </div>
        <div>
          <span class="label">Page Read</span>
          <span>${readRev}</span>
        </div>
        <div class="details">
          <span class="label">Pages Read</span>
          <span>${pagesRead}</span>
        </div>
      </div>
    </div>
    <div class="category">
      <div class="category-header">
        <span class="label expenses"><span class="icon"></span>Expenses:</span>
        <span>${expenses}</span>
      </div>
    </div>
    <div class="category">
      <div class="category-header">
        <span class="label profit"><span class="icon"></span>Profit:</span>
        <span>${profit}</span>
      </div>
    </div>
  </div>
  `;
}

function filterObjByBookIds(keys, object) {
  return Object.keys(object).reduce((acc, k) => {
    if (!keys.includes(parseInt(k, 10))) return acc;
    return { ...acc, [k]: object[k] };
  }, {});
}

export function filterRawSalesData(
  sales,
  expenses,
  amsSpend,
  fbSpend,
  authorBooks,
  bookIds,
  authorIds,
  tagIds,
  bookTags,
  preferredCurrency,
  selectedMarketplaces,
  pieChartMetric,
  pieChartResource,
  tags,
) {
  let filteredSales = { ...sales },
    filteredExp = { ...expenses },
    filteredFbSpend = { ...fbSpend },
    filteredAmsSpend = { ...amsSpend },
    filteredBookTags = { ...bookTags };
  if (Array.isArray(authorIds) && authorIds.length) {
    const authorBookIds = authorIds.reduce((acc, aId) => {
      if (!(aId in authorBooks)) return acc;
      return [...acc, ...(authorBooks[aId].books || []).map(x => x.id)];
    }, []);
    filteredSales = filterObjByBookIds(authorBookIds, filteredSales);
    filteredExp = filterObjByBookIds(authorBookIds, filteredExp);
    filteredFbSpend = filterObjByBookIds(authorBookIds, filteredFbSpend);
    filteredAmsSpend = filterObjByBookIds(authorBookIds, filteredAmsSpend);
  }
  if (Array.isArray(bookIds) && bookIds.length) {
    filteredSales = filterObjByBookIds(bookIds, filteredSales);
    filteredExp = filterObjByBookIds(bookIds, filteredExp);
    filteredFbSpend = filterObjByBookIds(bookIds, filteredFbSpend);
    filteredAmsSpend = filterObjByBookIds(bookIds, filteredAmsSpend);
  }
  if (Array.isArray(tagIds) && tagIds.length) {
    const tagBookIds = tagIds.reduce((acc, tagId) => {
      if (!(tagId in bookTags)) return acc;
      return [...new Set([...acc, ...bookTags[tagId]])];
    }, []);
    filteredSales = filterObjByBookIds(tagBookIds, filteredSales);
    filteredExp = filterObjByBookIds(tagBookIds, filteredExp);
    filteredFbSpend = filterObjByBookIds(tagBookIds, filteredFbSpend);
    filteredAmsSpend = filterObjByBookIds(tagBookIds, filteredAmsSpend);
    filteredBookTags = Object.keys(filteredBookTags || {}).reduce((acc, tagId) => {
      if (!tagIds.includes(parseInt(tagId, 10))) return acc;
      return { ...acc, [tagId]: filteredBookTags[tagId] };
    }, {});
  }
  if (Array.isArray(selectedMarketplaces) && selectedMarketplaces.length) {
    filteredSales = Object.keys(filteredSales).reduce((acc, bookId) => {
      const salesInMarketplaces = filteredSales[bookId].filter(x => selectedMarketplaces.includes(x.marketplace));
      if (!salesInMarketplaces.length) return acc;
      return { ...acc, [bookId]: salesInMarketplaces };
    }, {});
  }

  return salesChartAndTableData(filteredSales, filteredExp, filteredAmsSpend, filteredFbSpend, preferredCurrency, pieChartMetric, pieChartResource, filteredBookTags, tags);
}

export function salesChartAndTableData(sales, expenses, amsSpend, fbSpend, preferredCurrency, pieChartMetric, pieChartResource, bookTags, tags) {
  const currFormatter = currencyFormatter(preferredCurrency),
    numFormatter = new Intl.NumberFormat();
  const tableColumns = [
    {
      name: 'book',
      label: 'Book',
    },
    {
      name: 'sales',
      label: 'Sales',
      options: {
        filter: false,
      },
    },
    {
      name: 'salesRaw',
      label: 'Sales Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'paperbackSales',
      label: 'Paperback Sales',
      options: {
        filter: false,
      },
    },
    {
      name: 'paperbackSalesRaw',
      label: 'Paperback Sales Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'salesRev',
      label: 'Sales Revenue',
      options: {
        filter: false,
      },
    },
    {
      name: 'salesRevRaw',
      label: 'Sales Revenue Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'giveaways',
      label: 'Giveaways',
      options: {
        filter: false,
      },
    },
    {
      name: 'giveawaysRaw',
      label: 'Giveaways Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'pages',
      label: 'Pages',
      options: {
        filter: false,
      },
    },
    {
      name: 'pagesRaw',
      label: 'Pages Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'readRev',
      label: 'Page Read Revenue',
      options: {
        filter: false,
      },
    },
    {
      name: 'readRevRaw',
      label: 'Page Read Revenue Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'paperbackRev',
      label: 'Paperback Revenue',
      options: {
        filter: false,
      },
    },
    {
      name: 'paperbackRevRaw',
      label: 'Paperback Revenue Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'totalRev',
      label: 'Total Revenue',
      options: {
        filter: false,
        sortDirection: 'desc',
      },
    },
    {
      name: 'totalRevRaw',
      label: 'Total Revenue Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'totalExp',
      label: 'Expenses',
      options: {
        filter: false,
      },
    },
    {
      name: 'totalExpRaw',
      label: 'Expenses Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'profit',
      label: 'Profit',
      options: {
        filter: false,
      }
    },
    {
      name: 'profitRaw',
      label: 'Profit Raw',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
  ];

  // group sales by date
  const bookSalesByDate = groupObjAryByKey([].concat(...Object.values(sales)), 'date');
  const cDates = chartDates(bookSalesByDate, fbSpend);

  const tableTotals = {
    book: 'Totals',
    pages: 0,
    sales: 0,
    paperbackSales: 0,
    giveaways: 0,
    salesRev: 0,
    readRev: 0,
    paperbackRev: 0,
    totalRev: 0,
    totalExp: 0,
    profit: 0,
  };

  const bookIds = [...new Set([
    ...Object.keys(sales).filter(bookId => Array.isArray(sales[bookId]) && sales[bookId].length),
    ...Object.keys(expenses),
    ...Object.keys(fbSpend || {}).filter(bookId => Object.keys(fbSpend[bookId]).length),
    ...Object.keys(amsSpend || {}),
  ])];
  
  const tableData = bookIds.reduce((acc, bookId) => {
    const bookSales = (sales || {})[bookId] || [],
      bookExps = (expenses || {})[bookId] || [],
      bookAmsSpend = (amsSpend || {})[bookId] || [],
      bookFbSpend = [].concat(...Object.values((fbSpend || {})[bookId] || {}));
    let salesRev = 0;
    let readRev = 0;
    let paperbackRev = 0;
    let totUnitsSold = 0;
    let totUnitsPromo = 0;
    let totPages = 0;
    let totalExp = 0;
    let totPaperbackSales = 0;
    bookSales.forEach((s) => {
      let { pageReadRevenue, sales: salesVal, paperback_revenue, paperback_units } = s;
      let unitsSold = s.units_sold || 0;
      let unitsPromotional = s.units_promotional || 0;
      let pages = s.pages || 0;
      if (typeof salesVal === 'string') salesVal = parseFloat(salesVal);
      if (typeof paperback_revenue === 'string') paperback_revenue = parseFloat(paperback_revenue);
      if (typeof pageReadRevenue === 'string') pageReadRevenue = parseFloat(pageReadRevenue);
      if (typeof pages === 'string') pages = parseInt(pages, 10);
      if (typeof unitsSold === 'string') unitsSold = parseInt(unitsSold, 10);
      if (typeof unitsPromotional === 'string') unitsPromotional = parseInt(unitsPromotional, 10);
      if (typeof paperback_units === 'string') paperback_units = parseInt(paperback_units, 10);
      salesRev += salesVal || 0;
      paperbackRev += paperback_revenue || 0;
      readRev += pageReadRevenue || 0;
      totPages += pages;
      totUnitsSold += unitsSold;
      totUnitsPromo += unitsPromotional;
      totPaperbackSales += (paperback_units || 0);
    });
    bookExps.forEach((e) => {
      let expAmount = e.amount || 0;
      if (typeof expAmount === 'string') expAmount = parseFloat(expAmount);
      totalExp += expAmount;
    });
    bookFbSpend.forEach((s) => {
      let spend = s.convertedSpend || s.spend || 0;
      if (typeof spend === 'string') spend = parseFloat(spend);
      totalExp += spend;
    });
    bookAmsSpend.forEach((x) => {
      let { cost: spend } = x;
      if (typeof spend === 'string') spend = parseFloat(spend);
      totalExp += spend;
    });
    const totalRev = salesRev + readRev;
    tableTotals.salesRev += salesRev;
    tableTotals.readRev += readRev;
    tableTotals.paperbackRev += paperbackRev;
    tableTotals.totalRev += totalRev;
    tableTotals.totalExp += totalExp;
    tableTotals.profit += (totalRev - totalExp);
    tableTotals.pages += totPages;
    tableTotals.sales += totUnitsSold;
    tableTotals.paperbackSales += totPaperbackSales;
    tableTotals.giveaways += totUnitsPromo;
    let bookTitle = null;
    if (bookSales.length) {
      const { book } = bookSales[0],
        { title } = book || {};
      bookTitle = title;
    }
    if (!bookTitle && bookExps.length) {
      if (bookId === 'bookless') {
        bookTitle = 'General Expense';
      }
      else {
        const { book } = bookExps[0],
          { title } = book || {};
        bookTitle = title;
      }

    }
    if (!bookTitle && bookAmsSpend.length) {
      const { book_title: title } = bookAmsSpend[0];
      bookTitle = title;
    }
    if (!bookTitle && bookFbSpend.length) {
      const { bookTitle: title } = bookFbSpend[0];
      bookTitle = title;
    }

    acc.push({
      id: parseInt(bookId, 10),
      book: bookTitle || '',
      salesRev: currFormatter.format(salesRev),
      salesRevRaw: salesRev,
      readRev: currFormatter.format(readRev),
      readRevRaw: readRev,
      paperbackRev: currFormatter.format(paperbackRev),
      paperbackRevRaw: paperbackRev,
      totalRev: currFormatter.format(totalRev),
      totalRevRaw: totalRev,
      totalExp: currFormatter.format(totalExp),
      totalExpRaw: totalExp,
      profit: currFormatter.format(totalRev - totalExp),
      profitRaw: (totalRev - totalExp),
      pages: numFormatter.format(totPages),
      pagesRaw: totPages,
      sales: numFormatter.format(totUnitsSold),
      salesRaw: totUnitsSold,
      giveaways: numFormatter.format(totUnitsPromo),
      giveawaysRaw: totUnitsPromo,
      paperbackSales: numFormatter.format(totPaperbackSales),
      paperbackSalesRaw: totPaperbackSales,
    });
    return acc;
  }, []);

  Object.keys(tableTotals).forEach((k) => {
    if (['pages', 'sales', 'paperbackSales', 'giveaways'].includes(k)) {
      tableTotals[k] = numFormatter.format(tableTotals[k]);
      return;
    }
    const v = tableTotals[k];
    if (typeof v === 'number') tableTotals[k] = currFormatter.format(v);
  });

  return {
    tableData,
    chartData: [
      SALES_AREA_CHART_HEADERS,
      ...chartData(
        bookSalesByDate,
        cDates,
        [].concat(...Object.values(expenses)),
        fbSpend,
        [].concat(...Object.values(amsSpend)),
        currFormatter,
      ),
    ],
    tableColumns,
    tableTotals,
    pieChartData: [SALES_PIE_CHART_HEADERS, ...pieChartData(sales, pieChartMetric, pieChartResource, bookTags, tags)],
  };
}
