import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import Actions from '../../actions';
import Plan from '../blocks/Plan';

/**  Plan Tiers
 BK-1 Basic Monthly            [Monthly]
 BK-3 Professional Monthly     [Monthly]
 BK-2 Basic Annual             [Annual]
 BK-4 Professional Annual      [Annual]
 BK-5 Basic Free               [Free]
 */

class ViewPlans extends Component {
  componentDidMount() {
    this.props.dispatch(Actions.requestGetAllPlans());
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  render() {

    if (this.isEmpty(this.props.plans)) return null;

    return (
      <div style={styles.viewContainer}>
        <h1 style={{...styles.heading, textAlign: 'center'}}>Plans And Pricing</h1>
        <h5 style={{...styles.heading, textAlign: 'center'}}>First 14 days are free to use. After that:</h5>
        <Grid container spacing={5} style={{...styles.container, flexGrow: 1}} justify="center">
          <Grid sm={12} item md={4} style={{display: 'flex', alignItems: 'stretch'}}>
            <Paper
              xs={12}
              style={{
                margin: 10,
                display: 'flex',
                alignItems: 'stretch',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: '#efefef',
              }}
            >
              <Grid>
                <h1 style={{
                  backgroundColor: '#13bf1f',
                  margin: 0,
                  padding: 20,
                  textAlign: 'center',
                  color: '#fff',
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                >
                  Monthly Plans
                </h1>
              </Grid>
              <Grid
                item
                container
                style={{display: 'flex', alignItems: 'stretch', height: '100%'}}
              >
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'stretch'}}>
                  <Plan plans={(this.props.plans || {})['BK-1']} bgColor="#efefef" btnColor="#13bf1f" affiliate={this.props.match.params.affiliate}/>
                </Grid>
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'stretch'}}>
                  <Plan plans={(this.props.plans || {})['BK-3']} bgColor="#dfdfdf" btnColor="#13bf1f" affiliate={this.props.match.params.affiliate}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid sm={12} item md={4} style={{display: 'flex', alignItems: 'stretch'}}>
            <Paper
              xs={12}
              style={{
                margin: 10, display: 'flex', alignItems: 'stretch', flexDirection: 'column', width: '100%',
              }}
            >
              <Grid>
                <h1 style={{
                  backgroundColor: '#d81921',
                  margin: 0,
                  padding: 20,
                  textAlign: 'center',
                  color: '#fff',
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                  lineHeight: '20px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                >
                  Annual Plans
                  <br/>
                  <span style={{fontSize: 20}}>(Best Value)</span>
                </h1>
              </Grid>
              <Grid
                item
                container
                style={{
                  display: 'flex', alignItems: 'stretch', height: '100%', backgroundColor: '#efefef',
                }}
              >
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'stretch'}}>
                  <Plan plans={(this.props.plans || {})['BK-2']} bgColor="#efefef" btnColor="#d81921" affiliate={this.props.match.params.affiliate}/>
                </Grid>
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'stretch'}}>
                  <Plan plans={(this.props.plans || {})['BK-4']} bgColor="#dfdfdf" btnColor="#d81921" affiliate={this.props.match.params.affiliate}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid xs={12} item md={4} style={{display: 'flex', alignItems: 'stretch'}}>
            <Paper
              xs={12}
              style={{
                margin: 10, display: 'flex', alignItems: 'stretch', flexDirection: 'column', width: '100%',
              }}
            >
              <Grid item>
                <h1 style={{
                  backgroundColor: '#009fd9',
                  margin: 0,
                  padding: 20,
                  textAlign: 'center',
                  color: '#fff',
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                >
                  Free Plans
                </h1>
              </Grid>
              <Grid container style={{height: '100%'}}>
                <Plan plans={(this.props.plans || {})['BK-5']} bgColor="#efefef" btnColor="#009fd9" affiliate={this.props.match.params.affiliate}/>
              </Grid>
            </Paper>
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default connect(
  state => ({
    plans: state.braintree.plans,
    isFetching: state.braintree.isFetching,
  }),
)(ViewPlans);
