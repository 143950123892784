import { mergeState } from '11online-redux-helpers';

import {
  GET_ALL_BOOKS,
  GET_ALL_BOOKS_SUCCESS,
  GET_ALL_BOOKS_FAILURE,
  POST_BOOK_TAGS,
  POST_BOOK_TAGS_FAILURE,
  POST_BOOK_TAGS_SUCCESS,
  UPDATE_BOOK_TAGS, SET_BOOK_TAGS,
} from '../actions/types';

function updateBookTags(bookTags, book) {
  const { id: bookId } = book,
    booksTagIds = (book.tags || []).map(x => x.id),
    statesTagIds = Object.keys(bookTags || {}).map(x => parseInt(x, 10)),
    combinedTagIds = [...new Set([...booksTagIds, ...statesTagIds])];

  return combinedTagIds.reduce((acc, tagId) => {
    if (booksTagIds.includes(tagId)) {
      return {
        ...acc,
        [tagId]: [
          ...new Set([...(bookTags[tagId] || []), bookId]),
        ],
      };
    }
    else {
      return {
        ...acc,
        [tagId]: (bookTags[tagId] || []).filter(id => id !== bookId),
      };
    }
  }, {});
}

function parseBookTags(bookTags) {
  return Object.keys(bookTags).reduce((acc, tagId) => {
    const bookIds = bookTags[tagId].map(x => x.book_id);
    return { ...acc, [tagId]: bookIds };
  }, {});
}

export default function reducer(
  state = {
    isFetching: false,
    books: {},
    bookTags: {},
    error: null,
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_ALL_BOOKS:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_BOOKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        books: {
          ...(state.books || {}),
          ...action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
        },
      };
    case GET_ALL_BOOKS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_BOOK_TAGS:
      return {
        ...state,
        isFetching: true,
      };
    case POST_BOOK_TAGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        books: {
          ...(state.books || {}),
          [action.payload.id]: action.payload,
        },
      };
    case POST_BOOK_TAGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_BOOK_TAGS:
      return {
        ...state,
        bookTags: updateBookTags(state.bookTags, action.payload),
      };
    case SET_BOOK_TAGS:
      return {
        ...state,
        bookTags: parseBookTags(action.payload),
      };
    default:
      return state;
  }
}
