import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const userJobsRequest = (userId, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/user/${userId}/jobs`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const registerRequest = (formData) => fetch(
  `${process.env.REACT_APP_API_ROOT}/subscription`,
  {
    method: 'POST',
    body: formData,
  },
).then(handleResponse);

export const patchUserRequest = (user_id, formData, token) => {
  formData.append('_method', 'PATCH');
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/user/${user_id}`,
    {
      method: 'POST',
      headers: {
        'api-token': token,
      },
      body: formData,
    },
  ).then(handleResponse);
};


export const getUserRequest = (user_id, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/user/${user_id}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);
