import React, { Component } from 'react';
import Select from 'react-select';

class AutocompleteFilter extends Component {
  render() {
    const { theme, value, options, onChange, placeholder, isMulti, styleOverride, closeMenuOnSelect } = this.props,
      selectProps = { value, options, onChange, placeholder, isMulti };
    const styles = {
      container: base => ({
        ...base,
        flex: 1,
        margin: 5,
      }),
      input: base => ({
        ...base,
        fontFamily: 'AvenirLTStd-Book',
      }),
      control: base => ({
        ...base,
        // borderColor: 'red',
        '&:hover': {
          borderColor: 'white',
          boxShadow: 'none',
        },
        '&:focus': {
          borderColor: 'white',
          boxShadow: 'none',
        },
        '&:active': {
          borderColor: 'white',
          boxShadow: 'none',
        },
      }),
      valueContainer: base => ({
        ...base,
      }),
      placeholder: base => ({
        ...base,
        fontFamily: 'AvenirLTStd-Book',
      }),
      clearIndicator: base => ({
        ...base,
      }),
      dropdownIndicator: base => ({
        ...base,
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: 'white',
        border: '1px solid hsl(0,0%,20%)',
        paddingTop: 2,
      }),
      multiValueLabel: base => ({
        ...base,
        fontFamily: 'AvenirLTStd-Book',
      }),
      multiValueRemove: base => ({
        ...base,
        color: 'hsl(0,0%,20%)',
      }),
      menuList: base => ({
        ...base,
        fontFamily: 'AvenirLTStd-Book',
      }),
    };

    return (
      <Select
        styles={{
          ...styles,
          ...(styleOverride || {}),
        }}
        closeMenuOnSelect={typeof closeMenuOnSelect === 'boolean' ? closeMenuOnSelect : true}
        {...selectProps}
      />
    );
  }
}

export default AutocompleteFilter;
