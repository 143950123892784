import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';
import {getUrlParamString} from "../utilities/request-helpers";

export const postFacebookTokenRequest = (apiToken, fbAccessToken) => fetch(
  `${process.env.REACT_APP_API_ROOT}/facebook/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-token': apiToken,
      },
      body: JSON.stringify({
        fbAccessToken,
      }),
    },
).then(handleResponse);


export const getImportFacebookAdCampaignsRequest = (apiToken) => fetch (
  `${process.env.REACT_APP_API_ROOT}/fb-campaigns/ad-accounts`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': apiToken,
      },
    },
).then(handleResponse);

export const getFBImportJobsRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/fb-campaign-import-jobs`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const getAllFacebookCampaignsRequest = (apiToken, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/fb-campaigns`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': apiToken,
      },
    },
  ).then(handleResponse);
};

export const getFbCampaignRequest = (token, fbCampaignId) => fetch(
  `${process.env.REACT_APP_API_ROOT}/fb-campaign/${fbCampaignId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const getFbCampaignSpendRequest = (token, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/fb-campaigns/spend`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};


export const patchFbCampaignRequest = (fbCampaignId, book_id, token) => {
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/fb-campaign/${fbCampaignId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
      body: JSON.stringify({
        '_method': 'PATCH',
        book_id,
      }),
    },
  ).then(handleResponse);
};

export const deleteFbCampaignRequest = (fbCampaignId, token) => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/fb-campaign/${fbCampaignId}`,
      {
        method: 'DELETE',
        headers: {
          'api-token': token
        }
      }
    ).then(handleResponse);
};

export const postFbCampaignExportRequest = (formData, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/fb-campaign-export`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
);

export const deleteFbTokenRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/facebook/token`,
  {
    method: 'DELETE',
    headers: {
      'api-token': token,
    },
  },
).then(handleResponse);
