import moment from 'moment';
import {round} from "./number-helpers";
import {currencies} from "./constants";

/**
 * Find exchange rate data for the specified date
 * @param {string} date:            target date
 * @param {Object} exchangeRates:   cached exchange rate data (from exchangeRates reducer)
 * @returns {Object|null} Returns exchange rate data for the date if exists
 */
export function findExchangeRateForDate(date, exchangeRates) {
  // earliest date for which we have exchange rate data
  const firstERDate = moment(Object.keys(exchangeRates)[0]).startOf('day');
  const dateMoment = moment(date).startOf('day');
  const dateSubSevenMoment = moment(dateMoment).subtract(7, 'days').startOf('day');
  if (dateMoment.isBefore(firstERDate)) return null;
  if (dateMoment.isSame(firstERDate)) return exchangeRates[firstERDate.format('YYYY-MM-DD')];

  const prevDateMoment = moment(dateMoment).subtract(1, 'day').startOf('day');
  // move backwards, one day at a time, from the specified date. return the most
  // recent exchange rate data if exists
  do {
    const prevDateStr = prevDateMoment.format('YYYY-MM-DD');

    // return the most recent exchange rate
    if (prevDateStr in exchangeRates) return exchangeRates[prevDateStr];

    // if we're same or before the earliest cached exchange rate date, return
    if (prevDateMoment.isSameOrBefore(firstERDate)) return null;
    // if we're more than 7 days from the specified date, return
    if (prevDateMoment.isSameOrBefore(dateSubSevenMoment)) return null;
    prevDateMoment.subtract(1, 'day').startOf('day');
  } while (prevDateMoment);
  return null;
}

export function convertCurrency(value, valCurrency, rates, targetCurrency) {
  let convertedVal = value;
  // if value's currency isn't USD, convert to USD first
  if (valCurrency !== currencies.USD) {
    const { [valCurrency]: usdRate } = rates;
    convertedVal = round(convertedVal / usdRate, 2);
  }

  // if desired currency isn't USD, convert the USD amount to target currency
  if (targetCurrency && targetCurrency !== currencies.USD) {
    const { [targetCurrency]: targetRate } = rates;
    if (targetRate) {
      convertedVal = round(convertedVal * targetRate, 2);
    }
  }
  return convertedVal;
}
