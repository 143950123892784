import {
  GET_ALL_RECURRING_EXPENSES,
  GET_ALL_RECURRING_EXPENSES_FAILURE,
  GET_ALL_RECURRING_EXPENSES_SUCCESS,
} from "./types";
import {getAllRecurringExpensesRequest} from "../api/expenses";

export function requestGetAllRecurringExpenses(token, startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: GET_ALL_RECURRING_EXPENSES });
    return getAllRecurringExpensesRequest(token, startDate, endDate)
      .then(json => dispatch({ type: GET_ALL_RECURRING_EXPENSES_SUCCESS, payload: json }))
      .catch(e => dispatch({ type: GET_ALL_RECURRING_EXPENSES_FAILURE, payload: e }));
  };
}
