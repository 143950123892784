import { getRateRequest } from '../api/rates';

import {
  GET_RATE,
  GET_RATE_SUCCESS,
  GET_RATE_FAILURE,
} from './types';

function getRate() {
  return {
    type: GET_RATE,
  };
}

function getRateSuccess(json) {
  return {
    type: GET_RATE_SUCCESS,
    payload: json,
  };
}

function getRateFailure(error) {
  return {
    type: GET_RATE_FAILURE,
    payload: error,
  };
}

export default function requestGetRate(token, id) {
  return (dispatch) => {
    dispatch(getRate());
    return getRateRequest(token, id)
      .then(json => dispatch(getRateSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(getRateFailure(errors))));
  };
}
