import { loginRequestWithToken } from '../api/auth';
import { setCategories } from './categories';
import {
  LOGIN_USER,
  LOGIN_USER_WITH_TOKEN_SUCCESS,
  LOGIN_USER_WITH_TOKEN_FAILURE,
} from './types';

function loginUser() {
  return {
    type: LOGIN_USER,
  };
}

function loginUserWithTokenSuccess(json) {
  return {
    type: LOGIN_USER_WITH_TOKEN_SUCCESS,
    payload: {
      user: json,
    },
  };
}

function loginUserWithTokenFailure(error) {
  return {
    type: LOGIN_USER_WITH_TOKEN_FAILURE,
    payload: error,
  };
}

export default function loginWithToken(token) {
  return (dispatch) => {
    dispatch(loginUser());
    return loginRequestWithToken(token)
      .then((json) => {
        dispatch(setCategories(json.categories || []));
        return dispatch(loginUserWithTokenSuccess(json));
      })
      .catch(error => error.json().then(errors => dispatch(loginUserWithTokenFailure(errors))));
  };
}
