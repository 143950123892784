import moment from 'moment';

export function reduceObjAryByKey(ary, key) {
  return ary.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
}

export function sortObjAryByKey(ary, key) {
  return ary.sort((a, b) => {
    const { [key]: aVal } = a,
      { [key]: bVal } = b;
    if (typeof aVal === 'string' && typeof bVal === 'string') return aVal.localeCompare(bVal);
    else return aVal - bVal;
  });
}

export function sortObjAryByDate(ary, dateKey, desc = true) {
  return ary.sort((a, b) => {
    const aDate = a[dateKey],
      bDate = b[dateKey];
    return desc
      ? moment(bDate).valueOf() - moment(aDate).valueOf()
      : moment(aDate).valueOf() - moment(bDate).valueOf();
  });
}

export function groupObjAryByKey(ary, key, backupKey = 'unknown') {
  return ary.reduce((acc, i) => ({
    ...acc,
    [(key in i && !!i[key] ? i[key] : backupKey)]: [
      ...(acc[(key in i && !!i[key] ? i[key] : backupKey)] || []),
      i,
    ],
  }), []);
}
