import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_WITH_TOKEN_SUCCESS,
  LOGIN_USER_WITH_TOKEN_FAILURE,
  LOGOUT_USER,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_JOBS,
} from '../actions/types';

function updateAuthorBooks(user) {
  const { author } = user;
  return author.reduce((acc, x) => {
    const { id } = x;
    return {
      ...acc,
      [id]: x,
    };
  }, {});
}

export default function reducer(state = {
  isFetching: false,
  user: {},
  password: [],
  authorBooks: {},
}, action = { type: '' }) {
  switch (action.type) {
    case UPDATE_USER_JOBS:
      return {
        ...state,
        user: {
          ...(state.user || {}),
          jobs: action.payload,
        },
      };
    case LOGIN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        user: action.payload.user,
        authorBooks: updateAuthorBooks(action.payload.user),
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case LOGIN_USER_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        user: action.payload.user,
        authorBooks: updateAuthorBooks(action.payload.user),
      };
    case LOGIN_USER_WITH_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isFetching: false,
        user: {},
      };
    case REGISTER:
      return {
        ...state,
        isFetching: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        user: action.payload.user,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        isFetching: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case RESET_PASSWORD_EMAIL:
      return {
        ...state,
        isFetching: true,
      };
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
