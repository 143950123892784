import _ from 'lodash';
import moment from 'moment';

function tooltipHtml(date, expenses, facebook, ams) {
  const expStr = expenses
    ? `
    <div class="category">
      <div class="category-header">
        <span class="label">Expenses:</span>
        <span>${expenses}</span>
      </div>
    </div>
    `
    : '';
  const fbStr = facebook
    ? `
    <div class="category">
      <div class="category-header">
        <span class="label">Facebook Ad Spend:</span>
        <span>${facebook}</span>
      </div>
    </div>
    `
    : '';
  const amsStr = ams
    ? `
    <div class="category">
      <div class="category-header">
        <span class="label">AMS Spend:</span>
        <span>${ams}</span>
      </div>
    </div>
    `
    : '';
  return `
  <div class="area-chart-tooltip">
    <div class="date">${date}</div>
    ${expStr}
    ${fbStr}
    ${amsStr}
  </div>
  `;
}

export function areaChartData(expenses, fbSpend, amsSpend, formatter) {
  const byDate = {};
  (expenses || []).forEach((exp) => {
    const { date, amount } = exp;
    if (date in byDate) byDate[date] = {
      ...byDate[date],
      expenses: (byDate[date].expenses || 0) + amount,
    };
    else byDate[date] = { expenses: amount };
  });
  Object.values(fbSpend || {}).forEach((fbByDate) => {
    Object.keys(fbByDate).forEach((date) => {
      const fbSpendAry = fbByDate[date] || [];
      const total = fbSpendAry.reduce((sum, x) => (sum + (x.convertedSpend || x.spend || 0)), 0);
      if (date in byDate) byDate[date] = {
        ...byDate[date],
        facebook: total + (byDate[date].facebook || 0),
      };
      else byDate[date] = { facebook: total };
    });
  });
  (amsSpend || []).forEach((spendAry) => {
    (spendAry || []).forEach(x => {
      const { date, cost } = x;
      const formattedDate = moment(date).format('YYYY-MM-DD');
      if (formattedDate in byDate) byDate[formattedDate] = {
        ...byDate[formattedDate],
        ams: (byDate[formattedDate].ams || 0) + cost,
      };
      else byDate[formattedDate] = { ams: cost };
    });
  });
  return Object.keys(byDate).map((date) => {
    const data = byDate[date];
    let { ams, facebook, expenses } = data;
    const total = (ams || 0) + (facebook || 0) + (expenses || 0);
    if (ams) ams = formatter.format(ams);
    if (facebook) facebook = formatter.format(facebook);
    if (expenses) expenses = formatter.format(expenses);
    return [date, tooltipHtml(date, expenses, facebook, ams), total];
  });
}

export function pieChartData(expenses, fbSpend, amsSpend) {
  const amsSpendByBook = amsSpend.reduce((acc, ary) => {
    (ary || []).forEach(x => {
      const { book_id: bookId } = x;
      if (!bookId) return;
      if (bookId in acc) acc[bookId].push(x);
      else acc[bookId] = [ x ];
    });
    return acc;
  }, {});
  const bookIds = _.uniq([
    ...Object.keys(expenses || {}),
    ...Object.keys(fbSpend || {}),
    ...Object.keys(amsSpendByBook || {}),
  ]);
  return bookIds.map((bookId) => {
    let total = 0;
    let bookTitle = null;
    if (expenses && bookId in expenses) {
      (expenses[bookId] || []).forEach(exp => {
        const { amount, book } = exp,
          { title } = book || {};
        if (title) bookTitle = title;
        total += (amount || 0);
      });
    }
    if (fbSpend && bookId in fbSpend) {
      Object.values(fbSpend[bookId]).forEach(ary => {
        ary.forEach(x => {
          const { spend, convertedSpend, bookTitle: title } = x;
          if (title && !bookTitle) bookTitle = title;
          total += (convertedSpend || spend || 0);
        });
      });
    }
    if (amsSpendByBook && bookId in amsSpendByBook) {
      amsSpendByBook[bookId].forEach(x => {
        const { cost, book_title } = x;
        if (book_title && !bookTitle) bookTitle = book_title;
        total += (cost || 0);
      });
    }
    return [ bookTitle, total ];
  });
}
