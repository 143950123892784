import { currencies, marketplaces } from "./constants";
import getSymbolFromCurrency from 'currency-symbol-map';

export function localeForCurrency(preferredCurrency = currencies.USD) {
  switch (preferredCurrency) {
    case currencies.USD:
      return 'en';
    case currencies.AUD:
      return 'en_AT';
    case currencies.BRL:
      return 'pt_BR';
    case currencies.CAD:
      return 'en_CA';
    case currencies.EUR:
      return 'de';
    case currencies.GBP:
      return 'en_GB';
    default:
      return 'en';
  }
}

export function currencyForMarketplace(marketplace) {
  switch (marketplace) {
    case marketplaces.AMAZON:
      return currencies.USD;
    case marketplaces.AMAZON_AU:
      return currencies.AUD;
    case marketplaces.AMAZON_BR:
      return currencies.BRL;
    case marketplaces.AMAZON_CA:
      return currencies.CAD;
    case marketplaces.AMAZON_UK:
      return currencies.GBP;
    case marketplaces.AMAZON_DE:
    case marketplaces.AMAZON_ES:
    case marketplaces.AMAZON_FR:
    case marketplaces.AMAZON_IT:
    case marketplaces.AMAZON_NL:
      return currencies.EUR;
    case marketplaces.AMAZON_IN:
      return currencies.INR;
    case marketplaces.AMAZON_JP:
      return currencies.JPY;
    case marketplaces.AMAZON_MX:
      return currencies.MXN;
    default:
      return '';
  }
}

export function currencySymbol(currency) {
  return getSymbolFromCurrency(currency);
}
