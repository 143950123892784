import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import SortableTable from '../blocks/SortableTable';
import styles from '../../styles';
import {GET_ALL_BOOKS_SUCCESS, POST_BOOK_TAGS_SUCCESS, POST_TAG_SUCCESS} from "../../actions/types";
import {sortObjAryByKey} from "../../utilities/array-helpers";
import BookTagForm from '../blocks/BookTagForm';
import { withStyles } from '@material-ui/core/styles';
import {bookTitle} from "../../utilities/book-helpers";

const myStyles = {
  tagCol: {
    width: '50%',
  }
};

class BookManager extends Component {
  componentDidMount() {
    const books = this.props.books | {},
      tags = this.props.tags || {};
    if (!books || !Object.keys(books).length) this.fetchBooks();
    if (!tags || !Object.keys(tags).length) this.fetchTags();
  }

  fetchTags = () => {
    const { dispatch } = this.props,
      token = Cookies.get('token');
    return dispatch(Actions.requestGetTags(token));
  };

  fetchBooks = () => {
    const { dispatch } = this.props,
      token = Cookies.get('token');
    return dispatch(Actions.requestGetAllBooks(token))
      .then(res => {
        if (res.type !== GET_ALL_BOOKS_SUCCESS) {
          dispatch(Actions.newError('An error occurred'));
        }
      })
  };

  onSubmit = (formData, bookId) => {
    if (!bookId) return;
    const { dispatch } = this.props,
      token = Cookies.get('token');
    return dispatch(Actions.requestPostBookTags(token, bookId, formData))
      .then(res => {
        if (res.type === POST_BOOK_TAGS_SUCCESS) {
          dispatch(Actions.newMessage('Book updated!'));
        }
      });
  };

  getTableData = () => {
    const { books } = this.props;
    if (!books || !Object.keys(books).length) return [];
    const rows = Object.values(books).reduce((acc, x) => {
      const { id, isbn, asin } = x,
        tags = x.tags || [],
        tagsStr = tags.map(x => x.text).join(' ');
      return [ ...acc, {
        id,
        title: bookTitle(x),
        asin,
        isbn,
        edit: `/books/${id}`,
        tags: (<BookTagForm
          tags={this.props.tags || {}}
          postTag={this.postTag}
          onSubmit={this.onSubmit}
          book={x}
        />),
        tagsRaw: tagsStr
      } ];
    }, []);
    return sortObjAryByKey(rows, 'title');
  };

  postTag = tag => {
    const token = Cookies.get('token'),
      { dispatch } = this.props;
    return dispatch(Actions.requestPostTag(tag, token))
      .then(res => {
        if (res.type === POST_TAG_SUCCESS) return res;

        const payload = res.payload,
          errorStr = payload && typeof payload === 'object'
            ? Object.values(payload).join('. ')
            : 'An error occurred!';
        dispatch(Actions.newError(errorStr));
        return undefined;
      })
  };

  render() {
    const tableCols = [
      { key: 'title', title: 'Title' },
      { key: 'asin', title: 'ASIN'},
      { key: 'isbn', title: 'ISBN' },
      { key: 'tags', title: 'Tags', className: this.props.classes.tagCol, sortable: false },
    ];
    const tableData = this.getTableData();
    return (
      <div style={styles.viewContainer}>
        <SortableTable
          data={tableData}
          columns={tableCols}
          search={true}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    books: state.books.books,
    tags: state.tags.tags,
  }),
)(withStyles(myStyles)(BookManager));
