import { mergeState } from '11online-redux-helpers';
import moment from 'moment';
import {
  GET_ALL_SALES,
  GET_ALL_SALES_SUCCESS,
  GET_ALL_SALES_FAILURE,
  GET_SALES_BY_DATE,
  GET_SALES_BY_DATE_FAILURE,
  GET_SALES_BY_DATE_SUCCESS,
} from '../actions/types';
import { findExchangeRateForDate, convertCurrency } from "../utilities/exchange-rate-helpers";


/**
 * Estimates page read royalty rate for the sale. Users are allowed to customize
 * rate estimation method. See https://support.getbookreport.com/hc/en-us/articles/115010111967-How-are-page-read-earnings-calculated-
 * for more info
 * @param sale
 * @param rateData
 * @returns {*}
 */
function estimateRate(sale, rateData) {
  const { marketplace, date } = sale;
  const {
    rateEstimationMethod,
    rateDrops,
    customRates,
    ratesByMarketplace,
  } = rateData;
  if (rateEstimationMethod === 'drop') {
    if (marketplace in rateDrops) return rateDrops[marketplace];
  } else if (rateEstimationMethod === 'custom') {
    let cusRate = Object.values(customRates).find(x => x.marketplace === marketplace);

    // if using a custom rate and marketplace is missing, try to use amazon.com
    if (!cusRate) cusRate = Object.values(customRates).find(x => x.marketplace === 'amazon.com');
    if (cusRate) return cusRate.rate;
  } else {
    const dateMoment = moment(date);
    // sale is in current month and before the 15th, subtract additional month
    if (dateMoment.date() < 15 && dateMoment.month() === moment().month()) dateMoment.subtract(1, 'month');
    dateMoment.startOf('month').startOf('day').subtract(1, 'month');
    const marketplaceRates = ratesByMarketplace[marketplace] || [];
    const rate = marketplaceRates.find(x => {
      const { marketplace: rM, start_date: startDate } = x;
      return moment(startDate).startOf('day').isSameOrBefore(dateMoment) && rM === marketplace;
    });
    if (rate) return rate.rate;
  }
  return undefined;
}

/**
 * Adds page read royalties to a sale
 * @param sale
 * @param customRates
 * @returns {*}
 */
function pageReadRevenueForSale(sale, rateData) {
  const { rate, pages, page_read_royalty: pageReadRoyalty } = sale;
  // if we've already cached the page_read_royalty for this entry (from a historical import)
  // return it
  if (typeof pageReadRoyalty === 'number' && pageReadRoyalty > 0) return pageReadRoyalty;
  if (typeof pages !== 'number' || !pages) return 0;
  let rev = 0;
  if (rate) rev += rate.rate * pages;
  else {
    const estimatedRate = estimateRate(sale, rateData);
    if (estimatedRate) rev += estimatedRate * pages;
  }
  rev = Number((rev).toFixed(2));
  return rev;
}

/**
 * Convert sales currencies for a user's preferred currency
 * @param sales - array of sales objects
 * @param preferredCurrency - user's preferred currency
 * @returns {*}
 */
function convertSalesCurrencies(sales, preferredCurrency) {
  return sales.map((s) => {
    const {
      currency,
      exchange_rate: exRate,
      sales: salesValue,
      pageReadRevenue,
    } = s;
    if (!currency || !exRate) return s;
    if (currency === preferredCurrency) return s;
    try {
      const parsedRates = JSON.parse(exRate.rates);
      const pageReadRev = convertCurrency(pageReadRevenue, currency, parsedRates, preferredCurrency);
      const salesRev = convertCurrency(salesValue, currency, parsedRates, preferredCurrency);
      return { ...s, pageReadRevenue: pageReadRev, sales: salesRev };
    } catch (e) {
      console.error(e);
    }
    return s;
  });
}

/**
 * Updates salesByBook object with sales and exchange rate data. salesByBook
 * has shape like:
 *    {
 *      [bookId:string]: {
 *        exchange_rate:Object|null,
 *        book_id:number,
 *        book:Object,
 *        id:number,
 *        sales:number,
 *        pages:number,
 *        ...
 *      }[]
 *    }
 * @param {Array<Object>} sales:      array of sales data (api response)
 * @param {Object} exchangeRates:     cached exchange rate data
 * @returns {Object} Returns updated salesByBook object
 */
function updateSalesByBook(sales = [], exchangeRates = null, rateData) {
  const preferredCurrency = rateData.preferredCurrency || 'USD';

  const groupedByBook = sales.reduce((acc, s) => {
    const { book_id: bookId } = s;
    return {
      ...acc,
      [bookId]: [
        ...(acc[bookId] || []),
        { ...s, pageReadRevenue: pageReadRevenueForSale(s, rateData) },
      ],
    };
  }, {});

  return Object.keys(groupedByBook).reduce((acc, bookId) => {
    const sortedSales = groupedByBook[bookId].sort((a, b) => (new Date(a.date) - new Date(b.date)));
    if (!exchangeRates) return { ...acc, [bookId]: sortedSales };
    const sortedSalesWithExRates = sortedSales.map((sale) => {
      if (sale.exchange_rate || !exchangeRates) return sale;
      return { ...sale, exchange_rate: findExchangeRateForDate(sale.date, exchangeRates) };
    });
    const convertedSales = convertSalesCurrencies(sortedSalesWithExRates, preferredCurrency);
    return { ...acc, [bookId]: convertedSales };
  }, {});
}

export default function reducer(
  state = {
    isFetching: false,
    user: {},
    salesByBook: null,
    salesByDate: {},
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_ALL_SALES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_SALES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        salesByBook: updateSalesByBook(action.payload, action.exchangeRates, action.rateData),
        paperbackBookIds: action.payload.reduce((acc, bookSale) => {
          let { book_id: bookId, book } = bookSale,
            { isbn } = book || {};
          if (isbn && isbn.length && !acc.includes(bookId)) return [ ...acc, bookId ];
          return acc;
        }, []),
      };
    case GET_ALL_SALES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_SALES_BY_DATE:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SALES_BY_DATE_SUCCESS:
      return {
        ...state,
        salesByDate: action.payload,
        isFetching: false,
      };
    case GET_SALES_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
