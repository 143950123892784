import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';

export const getAllBooksRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/books`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const postBookTagsRequest = (token, bookId, formData) => fetch(
  `${process.env.REACT_APP_API_ROOT}/book/${bookId}/tags`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const getBookTagsRequest = (token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/books/tags`,
  {
    method: 'GET',
    headers: {
      'api-token': token,
    },
  },
).then(handleResponse);
