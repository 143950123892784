import fetch from 'isomorphic-fetch';
import { handleResponse } from '11online-fetch-helpers';
import {getUrlParamString} from "../utilities/request-helpers";

export const getAllExpensesRequest = (token, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/expenses`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};

export const getAllRecurringExpensesRequest = (token, startDate, endDate) => {
  let url = `${process.env.REACT_APP_API_ROOT}/recurring_expenses`;
  const p = {};
  if (startDate) p.startDate = startDate;
  if (endDate) p.endDate = endDate;
  if (startDate || endDate) {
    const paramStr = getUrlParamString(p);
    url += `?${paramStr}`;
  }
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token,
      },
    },
  ).then(handleResponse);
};

export const getExpenseRequest = (expenseId, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/expense/${expenseId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);

export const postExpenseRequest = (formData, token) => fetch(
  `${process.env.REACT_APP_API_ROOT}/expense`,
  {
    method: 'POST',
    headers: {
      'api-token': token,
    },
    body: formData,
  },
).then(handleResponse);

export const patchExpenseRequest = (expenseId, formData, token) => {
  formData.append('_method', 'PATCH');
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/expense/${expenseId}`,
    {
      method: 'POST',
      headers: {
        'api-token': token,
      },
      body: formData,
    },
  ).then(handleResponse);
};

export const deleteExpenseRequest = (expenseId, token) => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/expense/${expenseId}`,
      {
        method: 'DELETE',
        headers: {
          'api-token': token
        }
      }
    ).then(handleResponse);
};

export const getAllExpenseCategoriesRequest = token => fetch(
  `${process.env.REACT_APP_API_ROOT}/categories`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'api-token': token,
    },
  },
).then(handleResponse);
