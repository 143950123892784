import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'cookies-js';
import Button from '@material-ui/core/Button';
import Actions from '../../actions';
import AreaChart from '../blocks/AreaChart';
import Filters from '../blocks/Filters';
import styles from '../../styles';
import {
  DELETE_EXPENSE_FAILURE,
  GET_ALL_SALES_FAILURE,
} from '../../actions/types';
import {
  currencies,
  dateRangePresets,
  EXP_AREA_CHART_HEADERS,
  EXP_PIE_CHART_HEADERS, REPEAT_TYPES, USER_JOBS,
} from "../../utilities/constants";
import moment from 'moment';
import { datesForDateRangePreset, defaultSalesDates } from "../../utilities/date-helpers";
import { withStyles } from '@material-ui/core/styles';
import SectionWrapper from '../blocks/SectionWrapper';
import PageHeader from '../blocks/PageHeader';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { cacheVal, getCachedVal } from '../../utilities/cache-helpers';
import AutocompleteFilter from '../blocks/AutocompleteFilter';
import { bookFilterOptions, categoryFilterOptions } from '../../utilities/filter-helpers';
import {groupObjAryByKey, sortObjAryByKey} from "../../utilities/array-helpers";
import {currencyFormatter} from "../../utilities/string-helpers";
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountSnapshot from '../blocks/AccountSnapshot';
import {areaChartData, pieChartData} from "../../utilities/expenses-helper";
import PieChart from '../blocks/PieChart';
import {currencySymbol} from "../../utilities/locale-helpers";
import withJobPolling from "../blocks/WithJobPolling";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';

const FILTERS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  DATE_RANGE_PRESET: 'dateRangePreset',
  SELECTED_CATEGORIES: 'selectedCategories',
  SELECTED_BOOKS: 'selectedBooks',
};

const FACEBOOK_AD_SPEND = 'Facebook Ad Spend';
const AMS_AD_SPEND = 'AMS Ad Spend';

class ViewExpenses extends Component {
  state = {
    [FILTERS.DATE_RANGE_PRESET]: dateRangePresets.THIS_MONTH,
    [FILTERS.SELECTED_CATEGORIES]: [],
    [FILTERS.SELECTED_BOOKS]: [],
    ...defaultSalesDates,
  };

  componentDidMount() {
    this.maybeResetDateRange(() => {
      const { startDate, endDate } = this.state;
      this.requestExpenses({ startDate, endDate });
      this.maybeResetFilters();
    });
  }

  maybeResetFilters = () => {
    const filters = {};
    const selectedCategories = getCachedVal(FILTERS.SELECTED_CATEGORIES);
    if (Array.isArray(selectedCategories)) filters[FILTERS.SELECTED_CATEGORIES] = selectedCategories;
    const selectedBooks = getCachedVal(FILTERS.SELECTED_BOOKS);
    if (Array.isArray(selectedBooks)) filters[FILTERS.SELECTED_BOOKS] = selectedBooks;
    if (Object.keys(filters).length) this.setState({ ...this.state, ...filters });
  };

  maybeResetDateRange = (callback) => {
    const startDate = getCachedVal(FILTERS.START_DATE),
      endDate = getCachedVal(FILTERS.END_DATE),
      dateRangePreset = getCachedVal(FILTERS.DATE_RANGE_PRESET);

    if (startDate && endDate && dateRangePreset) {
      this.setState({ startDate, endDate, dateRangePreset }, () => callback());
    }
    else callback();
  };

  deleteExpense = (id, event) => {
		this.props.dispatch(Actions.requestDeleteExpense(id, Cookies.get('token')))
			.then(result => {
				if (result.type !== DELETE_EXPENSE_FAILURE){
					this.props.dispatch(Actions.newMessage('You have successfully deleted this expense.'));
				} else {
					this.props.dispatch(Actions.newError('Something went wrong.'));
				}
			});
	};

  requestExpenses = ({ startDate, endDate }) => {
    const { dispatch } = this.props;
    if (startDate) startDate = moment(startDate).startOf('day').format('YYYY-MM-DD');
    if (endDate) endDate = moment(endDate).endOf('day').format('YYYY-MM-DD');
    dispatch(Actions.requestGetAllSales(Cookies.get('token'), startDate, endDate))
      .then(res => {
        const { type, payload = {} } = res;
        if (type === GET_ALL_SALES_FAILURE)
          dispatch(Actions.newError(payload.message || 'An error occurred'));
      });
  };

  setDate = ({ startDate, endDate }) => {
    cacheVal(FILTERS.START_DATE, startDate);
    cacheVal(FILTERS.END_DATE, endDate);
    cacheVal(FILTERS.DATE_RANGE_PRESET, dateRangePresets.CUSTOM);
    this.setState({ startDate, endDate, dateRangePreset: dateRangePresets.CUSTOM }, () => {
      this.requestExpenses({ startDate, endDate });
    });
  };

  setDateRangePreset = data => {
    const { value } = data.target;
    cacheVal(FILTERS.DATE_RANGE_PRESET, value);
    this.setState({ dateRangePreset: value }, () => {
      if (value === dateRangePresets.CUSTOM) return;
      this.requestDateRangePreset(value);
    });
  };

  requestDateRangePreset = dateRange => {
    const { startDate, endDate } = datesForDateRangePreset(dateRange);
    this.setState({ startDate: startDate ? startDate.toDate() : null, endDate: endDate ? endDate.toDate() : null }, () => {
      const { startDate, endDate } = this.state;
      cacheVal(FILTERS.START_DATE, startDate);
      cacheVal(FILTERS.END_DATE, endDate);
      this.requestExpenses({ startDate, endDate });
    });
  };

  setCategories = data => {
    let newCat = data;
    if (!Array.isArray(data)) newCat = [];
    cacheVal(FILTERS.SELECTED_CATEGORIES, newCat);
    this.setState({ [FILTERS.SELECTED_CATEGORIES]: newCat });
  };

  setBooks = data => {
    let newBooks = data;
    if (!Array.isArray(data)) newBooks = [];
    cacheVal(FILTERS.SELECTED_BOOKS, newBooks);
    this.setState({ [FILTERS.SELECTED_BOOKS]: newBooks });
  };

  filteredExpenseData = () => {
    const { expensesByBook, recurringExpenses } = this.props;
    let filtered = [].concat(...Object.values({ ...expensesByBook })).concat(...Object.values(recurringExpenses || {}));
    const {
      [FILTERS.SELECTED_BOOKS]: selectedBooks,
      [FILTERS.SELECTED_CATEGORIES]: selectedCategories,
    } = this.state;
    if (Array.isArray(selectedBooks) && selectedBooks.length) {
      const bookIds = selectedBooks.map(x => x.value);
      filtered = filtered.filter(x => (x.book && bookIds.includes(x.book.id)));
    }
    if (Array.isArray(selectedCategories) && selectedCategories.length) {
      const catIds = selectedCategories.map(x => x.value);
      filtered = filtered.filter(x =>
        (x.categories || []).map(y => y.id).some(z => catIds.includes(z))
      );
    }
    return filtered;
  };

  filteredAmsSpendData = () => {
    const { amsSpendByDate } = this.props;
    const { [FILTERS.SELECTED_BOOKS]: selectedBooks, [FILTERS.SELECTED_CATEGORIES]: selectedCategories } = this.state;
    if (Array.isArray(selectedCategories) && selectedCategories.length) {
      if (!selectedCategories.find(x => x.value === AMS_AD_SPEND)) return [];
    }
    if (!amsSpendByDate || !Object.keys(amsSpendByDate).length) return [];
    let filtered = { ...amsSpendByDate };
    if (Array.isArray(selectedBooks) && selectedBooks.length) {
      const bookIds = selectedBooks.map(x => x.value);
      filtered = Object.keys(amsSpendByDate).reduce((acc, bookId) => {
        if (!bookIds.includes(parseInt(bookId, 10))) return acc;
        return { ...acc, [bookId]: amsSpendByDate[bookId] };
      }, {})
    }
    return Object.values(filtered);
  };

  filteredFbSpendData = () => {
    const { fbSpendByBook } = this.props;
    const { [FILTERS.SELECTED_BOOKS]: selectedBooks, [FILTERS.SELECTED_CATEGORIES]: selectedCategories } = this.state;
    if (!fbSpendByBook || !Object.keys(fbSpendByBook).length) return [];
    if (Array.isArray(selectedCategories) && selectedCategories.length) {
      if (!selectedCategories.find(x => x.value === FACEBOOK_AD_SPEND)) return [];
    }
    let filtered = { ...fbSpendByBook };
    if (Array.isArray(selectedBooks) && selectedBooks.length) {
      const bookIds = selectedBooks.map(x => x.value);
      filtered = Object.keys(fbSpendByBook).reduce((acc, bookId) => {
        if (!bookIds.includes(bookId)) return acc;
        return { acc, [bookId]: fbSpendByBook[bookId] };
      }, {})
    }
    return filtered;
  };

  /**
   * Calculates total sales revenue for current date range's data
   *
   * @returns {*}
   */
  getSalesRevenue = () => {
    const { salesByBook } = this.props;
    return [].concat(...Object.values(salesByBook || {})).reduce((sum, x) => {
      let { pageReadRevenue, sales: salesRev } = x;
      if (typeof salesRev === 'string') salesRev = parseFloat(salesRev);
      if (typeof pageReadRevenue === 'string') pageReadRevenue = parseFloat(pageReadRevenue);
      return sum + (salesRev || 0) + (pageReadRevenue || 0);
    }, 0);
  };

  renderChart = (currFormatter) => {
    const { theme, user, classes } = this.props;
    const preferredCurrency = user ? user.preferred_currency : currencies.USD;
    const expenses = this.filteredExpenseData();
    const amsSpend = this.filteredAmsSpendData();
    const fbSpendByBook = this.filteredFbSpendData();

    const areaData = [ EXP_AREA_CHART_HEADERS, ...areaChartData(expenses, fbSpendByBook, amsSpend, currFormatter) ];
    const pieData = [ EXP_PIE_CHART_HEADERS, ...pieChartData(groupObjAryByKey(expenses, 'book_id'), fbSpendByBook, amsSpend) ];

    return (
      <div>
        <SectionWrapper className={classes.greyBackground} header="Expenses by Book">
          <PieChart
            data={pieData}
            theme={theme}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  prefix: currencySymbol(preferredCurrency),
                },
              },
            ]} />
        </SectionWrapper>
        <SectionWrapper className={classes.greyBackground} header="Expenses by Day">
          <AreaChart
            data={areaData}
            theme={theme}
            colors={[theme.palette.common.expenses]}
            customTooltip={true}
          />
        </SectionWrapper>
      </div>
    );
  };

  render() {
    const { user, classes, theme, authorBooks, categories, isFetching } = this.props;
    const preferredCurrency = (user && user.preferred_currency) || currencies.USD;
    const currFormatter = currencyFormatter(preferredCurrency);
    const { [FILTERS.SELECTED_CATEGORIES]: selectedCategories } = this.state;

    if (isFetching) {
      return (
        <div>
          <SectionWrapper className={classes.whiteBackground}>
            <PageHeader text={'expenses'} />
          </SectionWrapper>
          <SectionWrapper style={{
            display: 'flex',
            minHeight: '60vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CircularProgress />
          </SectionWrapper>
        </div>
      )
    }

    const tableColumns = [
      { name: 'description', label: 'Description', options: { filterType: 'textField' } },
      { name: 'amount', label: 'Amount', options: { filter: false } },
      { name: 'amountRaw', label: 'Amount Raw', options: { display: 'excluded', filter: false, download: false } },
      { name: 'book', label: 'Book' },
      { name: 'date', label: 'Date', options: { filter: false, sortDirection: 'asc' } },
      { name: 'category', label: 'Category' },
      { name: 'actions', label: 'Actions', options: { filter: false, download: false, sort: false } },
    ];
    let tableData = [];
    const expenses = this.filteredExpenseData();
    const amsSpend = this.filteredAmsSpendData();
    const fbSpendByBook = this.filteredFbSpendData();
    let totalExp = 0;

    for (const key in expenses) {
      const exp = expenses[key],
        { repeat_of } = exp,
        expCategories = exp.categories || [],
        [ category ] = expCategories;
      let expenseData = {
        id: exp.id,
        description: exp.title,
        amount: currFormatter.format(exp.amount),
        amountRaw: exp.amount,
        date: exp.date,
        actions: repeat_of ? null : (
          <div>
            <IconButton color="primary" component={Link}  to={`/expense/${exp.id}`}>
              <Edit />
            </IconButton>
            <IconButton style={{ color: theme.palette.error.main }} component={Button} onClick={(event)=>this.deleteExpense(exp.id, event)}>
              <Delete />
            </IconButton>
          </div>
        ),
        category: category ? category.title : '',
      };
      if(expenses[key].book) {
        expenseData.book = expenses[key].book.title;
      }
      tableData.push(expenseData);
      totalExp += exp.amount;
    }

    Object.keys(fbSpendByBook).forEach(bookId => {
      Object.keys(fbSpendByBook[bookId]).forEach(date => {
        (fbSpendByBook[bookId][date] || []).forEach(fbSpend => {
          const { bookTitle, id, spend, fbCampaignName, convertedSpend } = fbSpend;
          const spendAmount = convertedSpend || spend;
          const d = {
            id,
            description: fbCampaignName || '',
            amount: currFormatter.format(spendAmount),
            amountRaw: spendAmount,
            date,
            book: bookTitle && bookTitle.length ? bookTitle : '',
            category: FACEBOOK_AD_SPEND
          };
          tableData.push(d);
          totalExp += spendAmount;
        });
      });
    });

    for (const bookId in (amsSpend || {})) {
      amsSpend[bookId].forEach(x => {
        const { book_title: bookTitle, cost: spend, date, name: campaignName, id } = x,
          dateStr = moment(date).format('YYYY-MM-DD');
        const d = {
          id,
          description: campaignName || '',
          amount: currFormatter.format(spend),
          amountRaw: spend,
          date: dateStr,
          book: bookTitle || '',
          category: AMS_AD_SPEND,
        };
        tableData.push(d);
        totalExp += spend;
      });
    }

    const revenue = this.getSalesRevenue() || 0;
    const profit = currFormatter.format(revenue - totalExp);

    tableData = tableData.sort((a, b) => {
      const { date: aDate } = a;
      const { date: bDate } = b;
      return new Date(bDate) - new Date(aDate);
    });

    const { dateRangePreset, startDate, endDate, selectedBooks } = this.state;
    const catOpts = [
        ...categoryFilterOptions(categories),
        { value: FACEBOOK_AD_SPEND, label: FACEBOOK_AD_SPEND },
        { value: AMS_AD_SPEND, label: AMS_AD_SPEND },
      ];

    const additionalInputs = (
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
        <AutocompleteFilter
          value={selectedCategories}
          onChange={this.setCategories}
          options={sortObjAryByKey(catOpts, 'label')}
          placeholder="Select categories"
          isMulti={true}
          theme={theme}
          closeMenuOnSelect={false}
        />
        <AutocompleteFilter
          value={selectedBooks}
          onChange={this.setBooks}
          options={bookFilterOptions(null, authorBooks)}
          placeholder="Select books"
          isMulti={true}
          theme={theme}
          closeMenuOnSelect={false}
        />
      </div>
    );

    const recurringExpLink = <Button variant="outlined" component={Link} color="secondary" to="/recurring_expenses">
      Manage Recurring Expenses
    </Button>;

    const expLinks = (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 10 }} variant="contained" component={Link} color="primary" to="/expense">
          Add Expense
        </Button>
        { recurringExpLink }
      </div>
    );

    return (
      <div>
        <SectionWrapper className={classes.whiteBackground}>
          <PageHeader text="Expenses" />
        </SectionWrapper>
        <SectionWrapper>
          <AccountSnapshot profit={profit} expenses={currFormatter.format(totalExp)} revenue={currFormatter.format(revenue)} />
        </SectionWrapper>
        <Filters
          startDate={startDate}
          endDate={endDate}
          setDate={this.setDate}
          dateRangePreset={dateRangePreset}
          setDateRangePreset={this.setDateRangePreset}
          additionalRow={additionalInputs}
        >
          <SectionWrapper className={classes.greyBackground}>
            {
              expLinks
            }
          </SectionWrapper>
          { this.renderChart(currFormatter) }
          <SectionWrapper header="Expense Details">
            <MUIDataTable
              data={tableData}
              columns={tableColumns}
              options={{
                selectableRows: 'none',
                customSort: (data, idx, dir) => {
                  const key = tableColumns[idx].name;
                  return data.sort((a, b) => {
                    if (key !== 'amount') {
                      return a.data[idx].localeCompare(b.data[idx]) * (dir === 'desc' ? 1 : -1);
                    }
                    const rawKeyIdx = tableColumns.findIndex(col => col.name === `${key}Raw`);
                    return (a.data[rawKeyIdx] < b.data[rawKeyIdx] ? 1 : -1) * (dir === 'desc' ? 1 : -1);
                  });
                },
                customToolbar: () => (
                  <React.Fragment>
                    <Tooltip title="Add Expense">
                      <IconButton component={Link} to="/expense" color="primary">
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    { recurringExpLink }
                  </React.Fragment>
                )
              }}
            />
          </SectionWrapper>
        </Filters>
        <Fab
          color="primary"
          style={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => window.location.reload()}
        >
          <RefreshIcon />
        </Fab>
      </div>
    );
  }
}

export default connect(
  state => ({
    isFetching: state.sales.isFetching,
    salesByBook: state.sales.salesByBook,
    expensesByBook: state.expenses.expensesByBook,
    recurringExpenses: state.expenses.recurringExpenses || {},
    amsSpendByDate: state.ams.amsSpendByDate,
    fbSpendByBook: state.facebook.fbSpendByBook,
    user: state.auth.user,
    categories: state.categories.categories || {},
    authorBooks: state.auth.authorBooks || {},
  }),
)(withStyles(styles, { withTheme: true })(withJobPolling(ViewExpenses, [USER_JOBS.FB_IMPORT, USER_JOBS.AMS_IMPORT])));
