import { deleteFbCampaignRequest } from '../api/facebook';

import {
  DELETE_FB_CAMPAIGN,
  DELETE_FB_CAMPAIGN_SUCCESS,
  DELETE_FB_CAMPAIGN_FAILURE,
} from '../actions/types';

function deleteFbCampaign() {
  return {
    type: DELETE_FB_CAMPAIGN,
  };
}

function deleteFbCampaignSuccess(json) {
  return {
    type: DELETE_FB_CAMPAIGN_SUCCESS,
    payload: json
  };
}

function deleteFbCampaignFailure(error) {
  return {
    type: DELETE_FB_CAMPAIGN_FAILURE,
    payload: error
  };
}

export default function requestfbCampaignExpense(fb_campaign_id, token) {
  return dispatch => {
    dispatch(deleteFbCampaign());
    return deleteFbCampaignRequest(fb_campaign_id, token)
      .then((json) => dispatch(deleteFbCampaignSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(deleteFbCampaignFailure(errors))));
  };
}
