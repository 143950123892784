import { patchExpenseRequest } from '../api/expenses';

import {
  PATCH_EXPENSE,
  PATCH_EXPENSE_SUCCESS,
  PATCH_EXPENSE_FAILURE,
} from './types';


function patchExpense() {
  return {
    type: PATCH_EXPENSE,
  };
}

function patchExpenseSuccess(json) {
  return {
    type: PATCH_EXPENSE_SUCCESS,
    payload: json,
  };
}

function patchExpenseFailure(error) {
  return {
    type: PATCH_EXPENSE_FAILURE,
    payload: error,
  };
}


export default function requestPatchExpense(expenseId, formData, token) {
  return (dispatch) => {
    dispatch(patchExpense());
    return patchExpenseRequest(expenseId, formData, token)
      .then(json => dispatch(patchExpenseSuccess(json)))
      .catch(error => error.json().then(errors => dispatch(patchExpenseFailure(errors))));
  };
}
