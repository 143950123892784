import { sortObjAryByKey } from './array-helpers';
import { marketplaces } from './constants';
import {bookTitle, isPaperback} from "./book-helpers";

export function bookFilterOptions(selectedAuthors, authorBooks, bookType = 'all') {
  if (!authorBooks) return [];
  const books = Array.isArray(selectedAuthors) && selectedAuthors.length ?
    selectedAuthors.map(x => x.value).reduce((acc, aId) => {
      if (!(aId in authorBooks)) return acc;
      return [ ...acc, ...(authorBooks[aId].books || []) ]
    }, []) :
    [].concat(...Object.values(authorBooks).map(x => x.books));
  let booksByType = [ ...books ];
  if (bookType === 'paperbackOnly') booksByType = booksByType.filter(x => isPaperback(x));
  else if (bookType === 'excludePaperback') booksByType = booksByType.filter(x => !isPaperback(x));
  return sortObjAryByKey(booksByType.map(x => ({ value: x.id, label: bookTitle(x) })), 'label');
}

export function categoryFilterOptions(categories) {
  if (!categories || !Object.keys(categories).length) return [];
  return Object.values(categories).map(x => ({ value: x.id, label: x.title }));
}

export function tagFilterOptions(tags) {
  if (!tags || !Object.keys(tags).length) return [];
  const opts = Object.values(tags).map(tag => ({ value: tag.id, label: tag.text }));
  return sortObjAryByKey(opts, 'label');
}

export function authorFilterOptions(authorBooks, bookType = 'all') {
  if (!authorBooks) return [];

  const opts = Object.values(authorBooks)
    .filter(({ books }) => {
      if (bookType === 'all') return true;
      else if (bookType === 'paperbackOnly')
        return !!(books || []).find(x => isPaperback(x));
      else
        return !!(books || []).find(x => !isPaperback(x));
    })
    .map((author) => {
      const { id, name } = author;
      return { value: id, label: name };
    });

  return sortObjAryByKey(opts, 'label');
}

export function marketplaceFilterOptions() {
  return Object.values(marketplaces).map(marketplace => ({ value: marketplace, label: marketplace }));
}
