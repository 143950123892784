import {
    NEW_MESSAGE
} from '../actions/types';

function showMessage(message, autoHideDuration, actions, showLoading = false) {
    return {
        type: NEW_MESSAGE,
        message: message,
        autoHideDuration,
        actions,
        showLoading,
    };
}

export default function newMessage(message, autoHideDuration = 6000, actions = [], showLoading = false) {
    return dispatch => {
        dispatch(showMessage(message, autoHideDuration, actions, showLoading));
    };
}
