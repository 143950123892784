import { getBraintreeTokenRequest } from '../api/braintree';

import {
  GET_BRAINTREE_TOKEN,
  GET_BRAINTREE_TOKEN_SUCCESS,
  GET_BRAINTREE_TOKEN_FAILURE,
}
 from '../actions/types'

function getBraintreeToken() {
  return {
    type: GET_BRAINTREE_TOKEN,
  };
}

function getBraintreeTokenSuccess(json) {
  return {
    type: GET_BRAINTREE_TOKEN_SUCCESS,
    payload: json,
  };
}

function getBraintreeTokenFailure(error) {
  return {
    type: GET_BRAINTREE_TOKEN_FAILURE,
    payload: error,
  };
}

export default function requestGetBraintreeToken(apiToken){
  return (dispatch) => {
    dispatch(getBraintreeToken());
    return getBraintreeTokenRequest(apiToken)
    .then((json) => dispatch(getBraintreeTokenSuccess(json)))
    .catch(error => error.json().then(errors => dispatch(getBraintreeTokenFailure(errors))));
  };
}

