import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { dateRangePresets } from "../../utilities/constants";
import { toTitleCase } from "../../utilities/string-helpers";
import { withStyles } from '@material-ui/core/styles';
import SectionWrapper from '../blocks/SectionWrapper';
import styles from '../../styles';

const componentStyles = {
  root: {
    color: 'white',
    fontFamily: 'AvenirLTStd-Book',
  },
  select: {
    color: 'white',
    fontFamily: 'AvenirLTStd-Book',
    minWidth: '80px',
  },
  option: {
    fontFamily: 'AvenirLTStd-Book',
  },
  underline: {
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  disabled: {

  },
  labelRoot: {
    color: 'white',
    fontFamily: 'AvenirLTStd-Book',
    '&:focus': {
      color: 'white',
    }
  },
  icon: {
    color: 'white',
  },
  formControl: {
    margin: 10,
  },
  notice: {
    color: 'white',
    fontFamily: 'AvenirLTStd-Book',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '40%',
    margin: 'auto',
    marginBottom: 26
  }
};

class Filters extends Component {
  setStartDate = (startDate) => {
    this.props.setDate({ startDate, endDate: this.props.endDate });
  };

  setEndDate = (endDate) => {
    this.props.setDate({ endDate, startDate: this.props.startDate });
  };

  render() {
    const { classes, additionalRow } = this.props;
    return (
      <div>
        <SectionWrapper className="dark-img-background">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="date-range"
                    classes={{ root: classes.labelRoot }}
                    FormLabelClasses={{
                      root: classes.labelRoot,
                    }}
                  >
                    Date range
                  </InputLabel>
                  <Select
                    value={this.props.dateRangePreset}
                    onChange={this.props.setDateRangePreset}
                    input={(
                      <Input classes={{
                        root: classes.root,
                        underline: classes.underline
                      }} />
                    )}
                    inputProps={{
                      name: 'date-range',
                      id: 'date-range',
                    }}
                    className={classes.select}
                    classes={{
                      icon: classes.icon,
                    }}
                  >
                    {
                      Object.values(dateRangePresets).map(x => (
                        <MenuItem key={x} value={x} className={classes.option}>{toTitleCase(x)}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <DatePicker
                  className={classes.formControl}
                  autoOk
                  clearable
                  disableFuture
                  label="Start date"
                  format="MMM dd, yyyy"
                  value={this.props.startDate}
                  onChange={this.setStartDate}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      underline: classes.underline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.root
                    }
                  }}
                />
                <DatePicker
                  className={classes.formControl}
                  autoOk
                  clearable
                  disableFuture
                  label="End date"
                  format="MMM dd, yyyy"
                  value={this.props.endDate}
                  onChange={this.setEndDate}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      underline: classes.underline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.root
                    }
                  }}
                />
                {
                  this.props.additionalInputs && this.props.additionalInputs
                }
              </div>
              {this.props.dateRangePreset == "yesterday" &&
                <h2 className={classes.notice}>
                  Please note that recent expense data from Amazon and Facebook may be missing or incomplete while BookKeeper imports your data.
                </h2>
              }
            </>
          </MuiPickersUtilsProvider>
          {
            additionalRow || null
          }
        </SectionWrapper>
        {
          this.props.children
        }
      </div>
    );
  }
}

export default withStyles({ ...styles, ...componentStyles })(Filters);
