import { mergeState, deleteFromState } from '11online-redux-helpers';

import {
  GET_ALL_EXPENSES,
  GET_ALL_EXPENSES_SUCCESS,
  GET_ALL_EXPENSES_FAILURE,
  GET_EXPENSE,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSE_FAILURE,
  POST_EXPENSE,
  POST_EXPENSE_SUCCESS,
  POST_EXPENSE_FAILURE,
  PATCH_EXPENSE,
  PATCH_EXPENSE_SUCCESS,
  PATCH_EXPENSE_FAILURE,
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAILURE,
  GET_ALL_EXPENSE_CATEGORIES,
  GET_ALL_EXPENSE_CATEGORIES_SUCCESS,
  GET_ALL_EXPENSE_CATEGORIES_FAILURE,
  GET_ALL_RECURRING_EXPENSES,
  GET_ALL_RECURRING_EXPENSES_SUCCESS,
  GET_ALL_RECURRING_EXPENSES_FAILURE,
} from '../actions/types';
import {groupObjAryByKey} from "../utilities/array-helpers";
import {delItemFromObj} from "../utilities/object-helpers";

function deleteExpense(expenseId, state) {
  const expenses = state.expenses || {},
    newExpenses = delItemFromObj(expenseId, expenses),
    newExpensesByBook = groupObjAryByKey(Object.values(newExpenses || {}), 'book_id'),
    recurring = Object.values(state.recurringExpenses || {}).reduce((acc, exp) => {
      const { id, repeat_of } = exp;
      if (id === parseInt(expenseId, 10) || repeat_of === parseInt(expenseId, 10)) return acc;
      return { ...acc, [id]: exp };
    }, {});
  return { expenses: newExpenses, expensesByBook: newExpensesByBook, recurringExpenses: recurring };
}

function cacheExpense(exp, state) {
  const { repeat_type, id } = exp;
  if (repeat_type && repeat_type.length) return { recurringExpenses: { ...(state.recurringExpenses || {}), [id]: exp } };
  return { expenses: { ...(state.expenses || {}), [id]: exp } };
}

export default function reducer(
  state = {
    isFetching: false,
    user: {},
    expenses: {},
    expensesByBook: {},
    expenseCategories: {},
  },
  action = {
    type: '',
  },
) {
  switch (action.type) {
    case GET_ALL_EXPENSES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_EXPENSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        expenses: {
          ...(state.expenses || {}),
          ...action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
        },
        expensesByBook: groupObjAryByKey(action.payload, 'book_id', 'bookless'),
      };
    case GET_ALL_EXPENSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ALL_RECURRING_EXPENSES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_RECURRING_EXPENSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        recurringExpenses: action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
      };
    case GET_ALL_RECURRING_EXPENSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_EXPENSE:
      return {
        ...state,
        isFetching: true,
      };
    case GET_EXPENSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        expenses: mergeState(state.expenses, action.payload),
      };
    case GET_EXPENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case POST_EXPENSE:
      return {
        ...state,
        isFetching: true,
      };
    case POST_EXPENSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        ...cacheExpense(action.payload, state),
      };
    case POST_EXPENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case PATCH_EXPENSE:
      return {
        ...state,
        isFetching: true,
      };
    case PATCH_EXPENSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        expenses: mergeState(state.expenses, action.payload),
      };
    case PATCH_EXPENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case DELETE_EXPENSE:
      return {
        ...state,
        isFetching: true
      };
    case DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        ...deleteExpense(action.payload.id, state),
      };
    case DELETE_EXPENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case GET_ALL_EXPENSE_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_EXPENSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: [],
        expenseCategories: mergeState(state.expenseCategories, action.payload),
      };
    case GET_ALL_EXPENSE_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
