import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';

export default class BookThumbnails extends PureComponent {
  render() {
    const { books, header } = this.props;

    return (
      <div>
        {
          !!header
          && <Toolbar><Typography variant="h6">{header}</Typography></Toolbar>
        }
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {
            (books || []).map(x => (
              <Card key={x.id} style={{ margin: '0 3%' }}>
                <Link href={x.amazon_url} title={x.title} target="_blank">
                  <CardMedia
                    title={x.title}
                    image={x.cover_url || 'bookkeeper-logo.png'}
                    component="img"
                    style={{
                      width: 130,
                      height: 200,
                    }}
                    onError={(e) => {
                      e.target.src = 'bookkeeper-logo.png';
                    }}
                  />
                </Link>
              </Card>
            ))
          }
        </div>
      </div>
    );
  }
}
