const paperSpacing = 25;

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  heading: {
    textTransform: 'uppercase',
    marginTop: 0,
  },
  textField: {
    marginRight: 20,
    marginBottom: 30,
    width: 320,
    display: 'block',
  },
  headerSelect: {
    fontSize: 20,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  viewContainer: {
    padding: 10,
  },
  lightBlueBackground: {
    backgroundColor: '#42d6e2',
  },
  greyBackground: {
    backgroundColor: '#f0f0f0',
  },
  whiteBackground: {
    backgroundColor: '#ffffff',
  },
  blackBackground: {
    backgroundColor: '#060606',
  },
  darkBlueBackground: {
    backgroundColor: '#28435c',
  },
  darkGreyBackground: {
    backgroundColor: '#2e2e2e',
  },
  contentContainer: {
    maxWidth: 1600,
    display: 'block',
    margin: '0 auto',
  },
  paper: {
    marginTop: paperSpacing,
    marginBottom: paperSpacing,
    paddingTop: paperSpacing * 2,
    paddingBottom: paperSpacing * 2,
    paddingRight: paperSpacing,
    paddingLeft: paperSpacing
  },
  paperHeading: {
    marginTop: 0
  },
  formGridItem: {
    display: 'flex',
    padding: 10,
  },
  formGridField: {
    flex: 1,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  whiteUnderline: {
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:after': {
      borderBottomColor: 'white',
    },
  },
};

export default styles;
